import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { notification } from "antd";

import CheckAuthencationRoute from "./utils/CheckAuthencationRoute";
import AdminRoute from "./utils/AdminRoute";
import LoginRegister from "./components/login-register/signin-sigup";
import "./App.scss";
import { authInfoSelector } from "./redux/slice/auth/authSelector";
import { KEY_USER_LOGIN } from "./constants/apiConstants";
import { setAuthInfo } from "./redux/slice/auth/authSlice";
import isEmpty from "lodash/isEmpty";
import resetPassword from "./components/reset-password";

function App() {
  const dispatch = useDispatch();
  const authInfo = useSelector(authInfoSelector);
  const [api, contextHolder] = notification.useNotification();
  const [authData, setAuthData] = useState(null);
  const publicPage = [
    {
      url: "/login",
      component: LoginRegister,
      props: {
        notification: api,
      },
    },
    {
      url: "/",
      component: LoginRegister,
      props: {
        notification: api,
      },
    },
    {
      url: "/reset-password",
      component: resetPassword,
      props: {
        notification: api,
      },
    }
  ];

  useEffect(() => {
    const userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN) || "{}");
    if (!isEmpty(userLocal)) {
      dispatch(setAuthInfo(userLocal));
      setAuthData(userLocal);
    }
  }, []);

  useEffect(() => {
    if (authInfo) {
      setAuthData(authInfo);
    } else {
      setAuthData(null);
    }
  }, [authInfo]);

  const renderRouter = () => {
    if (authData) {
      return <AdminRoute />;
    }

    return (
      <>
        {publicPage.map((page, index) => {
          return (
            <CheckAuthencationRoute path={page.url} key={index} exact={true}>
              {React.createElement(page.component, page.props)}
            </CheckAuthencationRoute>
          );
        })}
      </>
    );
  };

  return (
    <Router>
      <div className="App">
        <Helmet>
          <script
            type="text/javascript"
            src={process.env.REACT_APP_TinyMCE_LIB}
          ></script>
        </Helmet>
        {contextHolder}

        <div className="container d-flex align-items-center flex-column">
          <div className="container-login-register ">
            <Switch>{renderRouter()}</Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
