import React, {useEffect, useState, useRef} from "react";
import {useSelector} from "react-redux";

import ButtonCustomize from "../../../components/button";
import {BtnTypeEnum} from "../../../components/button/const";
import {packageListSelector} from "../../../redux/slice/user/userSelector";
import {useUser} from "../../client/useUser";
import Modal from "../../../components/modal/modal-card";
import "./buyMore.scss";
import FormService from "./formService";
import {useBuyMore} from "./useBuyMore";
import ServiceItem from "./ServiceItem";
import cloneDeep from "lodash/cloneDeep";
import {EBuyMoreSystem, SERVICE_ADD_MORE} from "../../../constants/enum";
import CardItem from "./ServiceItem";

const BuyMoreService = () => {
    const {getListPackage} = useUser();
    const {
        getAllListBuyMore,
        handleCreateBuyMore,
        uploadFileImg,
        handleUpdateBuyMore,
        handleDeleteBuyMore
    } = useBuyMore();
    const packageList = useSelector(packageListSelector) || [];

    const [contentService, setContentService] = useState([]);
    const [packageSelected, setPackageSelected] = useState(null);
    const [modalForm, setModalForm] = useState({
        isOpen: false,
        title: "",
    });
    const formServiceRef = useRef(null);

    useEffect(() => {
        reloadData();
    }, []);

    const reloadData = () => {
        getListPackage();
    };

    const onAddNew = () => {
        setModalForm({
            isOpen: true,
            title: "Thêm dịch vụ mới",
        });
        formServiceRef.current?.clearForm();
    };
    const onDuplicate = (buyMoreItem) => () => {
        setModalForm({
            isOpen: true,
            title: "Nhân bản dịch vụ",
        });
        setFormDelay(buyMoreItem, true);
    };
    const onUpdate = (buyMoreItem) => () => {
        setModalForm({
            isOpen: true,
            title: "Cập nhật dịch vụ",
        });
        setFormDelay(buyMoreItem);
    };
    const onDelete = (buyMoreId) => async () => {
        const resData = await handleDeleteBuyMore(buyMoreId);
        if (resData.isSuccess) {
            if (packageSelected) {
                getListBuyMore(packageSelected)();
            }
        }
    }
    const setFormDelay = (item, isDuplicate = false) => {
        if (formServiceRef.current) {
            formServiceRef.current?.setDataForm(item, isDuplicate);
        } else {
            setTimeout(() => {
                formServiceRef.current?.setDataForm(item, isDuplicate);
            }, 300)
        }
    }

    const handleCancel = () => {
        setModalForm({
            isOpen: false,
        });
        formServiceRef.current?.clearForm();
    };

    const getListBuyMore = (packageItem) => () => {
        setPackageSelected(packageItem);
        getAllListBuyMore({
            packageId: packageItem.id,
        }).then((res) => {
            setContentService(res.data);
        });
    };

    const onConfirm = () => {

        formServiceRef.current?.submitForm();
    };

    const onSaveData = async (values, fileListState, fileDeletes) => {
        const payload = cloneDeep(values);
        let resData;

        if (!payload.id) {
            payload.typeSystem = EBuyMoreSystem.NORMAL;
            resData = await handleCreateBuyMore(payload);

            if (resData.isSuccess && fileListState?.length > 0) {
                let ids = resData.data?.map(({id}) => {
                    return id;
                })

                let formData = new FormData();

                formData.append("file", fileListState?.[0])
                formData.append("parentId",ids)
                formData.append("objectKey", SERVICE_ADD_MORE)
                await uploadFileImg(formData)
            }
        } else {
            resData = await handleUpdateBuyMore(payload);

            if (resData.isSuccess) {
                let ids = resData.data?.map(({id}) => {
                    return id;
                })

                let formData = new FormData();
                if (fileListState?.length > 0) {
                    formData.append("file", fileListState?.[0])
                    formData.append("parentId", ids)
                    formData.append("objectKey", SERVICE_ADD_MORE)
                    if (fileDeletes?.length > 0) {
                        fileDeletes?.forEach((file) => {

                            formData.append("fileOldName", file.baseThumbnail)
                        })
                    } else {
                        formData.append("fileOldName", values.thumbnail)
                    }
                    await uploadFileImg(formData)
                } else {
                    if (fileDeletes?.length > 0) {
                        formData.append("file", null)
                        formData.append("parentId", ids)
                        formData.append("objectKey", SERVICE_ADD_MORE)
                        fileDeletes?.forEach((file) => {

                            formData.append("fileOldName", file.baseThumbnail)
                        })
                        await uploadFileImg(formData)
                    }
                }


            }
        }

        if (resData.isSuccess) {
            if (packageSelected) {
                getListBuyMore(packageSelected)();
            }

            handleCancel()
        }
    }

    const renderFooter = () => {
        return (
            <>
                <ButtonCustomize
                    title="Hủy bỏ"
                    btnType={BtnTypeEnum.OUTLINE}
                    btnSize={44}
                    onPressHandle={handleCancel}
                />
                {/*<ButtonCustomize*/}
                {/*  title="Nhập lại"*/}
                {/*  btnType={BtnTypeEnum.GREY}*/}
                {/*  btnSize={44}*/}
                {/*/>*/}
                <ButtonCustomize
                    title="Lưu lại"
                    btnType={BtnTypeEnum.GREEN}
                    btnSize={44}
                    onPressHandle={onConfirm}
                />
            </>
        );
    };

    const renderBodyModal = () => {
        return (
            <FormService ref={formServiceRef} onSave={onSaveData}/>
        );
    };

    return (
        <>
            <div className="container-service">
                <div className="add-service">
                    <span>Các dịch vụ thêm</span>
                    <ButtonCustomize
                        title="Thêm dịch vụ mới"
                        btnType={BtnTypeEnum.BLACK}
                        btnSize={44}
                        onPressHandle={onAddNew}
                    />
                </div>
                <div className="box-service">
                    {packageList.map((item) => (
                        <ButtonCustomize
                            key={item.id}
                            title={<>{item.name}</>}
                            btnType={item.id === packageSelected?.id ? BtnTypeEnum.GREEN : BtnTypeEnum.GREY}
                            btnSize={44}
                            onPressHandle={getListBuyMore(item)}
                        />
                    ))}
                </div>
                {/*{contentService?.map((item) => (*/}
                {/*    <ServiceItem key={item.id} item={item} onDelete={onDelete} onDuplicate={onDuplicate}*/}
                {/*                 onUpdate={onUpdate}>*/}

                {/*    </ServiceItem>*/}
                {/*))}*/}

                <div className="list-plugin">
                    {contentService.map((e: any, index: any) => {
                        return <CardItem isSetting={true} item={e} onDelete={onDelete} onDuplicate={onDuplicate}
                                         onUpdate={onUpdate} key={index}/>
                    })}
                </div>
            </div>
            <Modal
                showModal={modalForm.isOpen}
                item={modalForm.title}
                onClose={handleCancel}
                footerNode={renderFooter}
                // width={'auto'}
            >
                {renderBodyModal()}
            </Modal>
        </>
    );
};

export default BuyMoreService;
