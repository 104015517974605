import { useCallback } from "react";
import { notification } from "antd";

import {
  getListPackageApi,
  getUserPackageUsing,
  sendRequestBuyMoreApi,
  upForgotPasswordApi,
  postResetPasswordApi,
  postDurationTokenApi,
  postChangePasswordApi,
  getUserNameApi, getBuyMoreOfUserApi
} from "../../redux/slice/user/userApis";
import { KEY_USER_LOGIN } from "../../constants/apiConstants";
import { useDispatch } from "react-redux";
import { AlertType, openNotificationWithIcon } from "../../utils/AlertMessage";
import { ERROR_API } from "../../constants/errorMessages";
import {
  getAllStatusBuyMoreUser,
  getRequestUserTransactionListApi,
  updateRequestInfoCompanyApi,
  updateRequestInfoUserApi,
} from "../../redux/slice/admin/adminApis";

export const useUser = () => {
  const dispatch = useDispatch();
  let userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN));

  const getUserPackageUsingHandle = useCallback((params = null) => {
    return new Promise(async (resolve) => {
      try {
        const dataRes = await dispatch(
          getUserPackageUsing(
            params
              ? params
              : {
                  userId: userLocal.id,
                }
          )
        ).unwrap();
        resolve({ isSuccess: true, data: dataRes });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);
  const getBuyMoreOfUser = useCallback((params = null) => {
    return new Promise(async (resolve) => {
      try {
        const dataRes = await dispatch(
            getBuyMoreOfUserApi(
            params
              ? params
              : {
                  userId: userLocal.id,
                }
          )
        ).unwrap();
        resolve({ isSuccess: true, data: dataRes });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getListPackage = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(getListPackageApi(payload));
        resolve({ isSuccess: true });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const sendRequestBuyMore = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        payload.userId = userLocal.id;
        await dispatch(sendRequestBuyMoreApi(payload)).unwrap();
        openNotificationWithIcon(
          notification,
          AlertType.success,
          "Đã gửi yêu cầu đăng ký dịch vụ"
        );
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          "Đăng ký gói không thành công"
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getAllStatusBuyMoreUserHandle = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const resData: any = await dispatch(
          getAllStatusBuyMoreUser(payload)
        ).unwrap();
        resolve({ isSuccess: true, data: resData });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getUserRequestTransactionList = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          getRequestUserTransactionListApi(payload)
        ).unwrap();
        resolve(data);
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const updateInfoCompany = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          updateRequestInfoCompanyApi(payload)
        ).unwrap();
        resolve(data);
        console.log("data", data);
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const updateInfoUser = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(updateRequestInfoUserApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const updateForgotPassword = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(upForgotPasswordApi(payload)).unwrap();
        openNotificationWithIcon(
          notification,
          AlertType.success,
          "Yêu cầu đặt lại mật khẩu đã được gửi. Vui lòng kiểm tra email của bạn."
        );
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const updateResetPassword = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(postResetPasswordApi(payload)).unwrap();
        openNotificationWithIcon(
          notification,
          AlertType.success,
          "Mật khẩu đã được thay đổi thành công"
        )
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          "Token không hợp lệ hoặc đã hết hạn"
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  
  const upDurationToken = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(postDurationTokenApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const changePassword = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(postChangePasswordApi(payload)).unwrap();
        openNotificationWithIcon(
          notification,
          AlertType.success,
          "Mật khẩu đã được thay đổi thành công"
        )
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  
  const getUserName = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(getUserNameApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  return {
    getUserPackageUsingHandle,
    getListPackage,
    sendRequestBuyMore,
    getBuyMoreOfUser,
    getAllStatusBuyMoreUserHandle,
    getUserRequestTransactionList,
    updateInfoCompany,
    updateInfoUser,
    updateForgotPassword,
    updateResetPassword,
    upDurationToken,
    changePassword,
    getUserName
  };
};
