import React, {forwardRef, useImperativeHandle, useState} from "react";
import cloneDeep from "lodash/cloneDeep";
import {InputNumber} from "antd";

import IconSuccess from "../../../../components/svg/icon-success";
import {STATUS_REQUEST} from "../../../../constants/enum";
import './styles.scss';
import {formatInputMoney} from "../../../../utils/helper";

const formRegNew = {
    packageId: null,
    serviceId: null,
    price: 0,
    amount: 0,
    duration: 0,
    valueOther: 0,
    status: STATUS_REQUEST.WAIT_REGIS_NEW
};
const RegNewService = forwardRef(({titleForm, formType, totalDays}, ref) => {
    useImperativeHandle(ref, () => ({
        setFormSource(params) {
            setSelected(params);
            setFormData(prev => ({
                ...prev,
                packageId: params.packageId,
                serviceId: params.id,
                price: params.price,
            }))
        },
        getDataForm() {
            return formData;
        },
        clearForm(){
            setFormData(cloneDeep(formRegNew));
            setSelected(null);
        }
    }));

    const [formData, setFormData] = useState(cloneDeep(formRegNew));
    const [selected, setSelected] = useState(null);
    const [userInput, setUserInput] = useState(0);

    const onChangeInput = (e) => {
        setUserInput(e);
        const amount = e * totalDays * (formData.price ?? 0);
        setFormData(prev => ({...prev, amount, valueOther: e, duration: totalDays * 30}));
    }

    const renderBody = () => {
        if (formType === 0) {
            return <>
                <div className="title-package">{titleForm}</div>
            </>
        }
        if (formType === 1) {
            return <>
                <div>Lựa chọn số lượng phù hợp với quy mô doanh nghiệp của bạn</div>
                <div className={"user-input-box"}>
                    <div>Nhập số lượng user cần mua</div>
                    <InputNumber placeholder="Nhập số lượng"
                                 className={"card-input-box"}
                                 value={userInput}
                                 min={0}
                                 style={{width: "100%"}}
                                 onChange={onChangeInput}
                    />
                </div>

                <div className={"user-money-box"}>
                    <div>Tổng quát:</div>

                    <div className={"box-money"}>
                        <div className={"rows-content"}>
                            <span>Số lượng user mua thêm:</span>
                            <span>{userInput ?? 0} user</span>
                        </div>
                        <div className={"rows-content"}>
                            <span>Thời gian theo gói CRM đang dùng:</span>
                            <span>{totalDays * 30} Ngày</span>
                        </div>
                        <div className={"rows-content"}>
                            <span>Tổng tiền sẽ thanh toán:</span>
                            <span>{formatInputMoney(formData.amount || 0)} đ</span>
                        </div>
                    </div>
                </div>
            </>
        }

        return <>
            <div className="container-success">
                <IconSuccess/>
                <div className="item-success">Thao tác đăng ký dịch vụ thành công</div>
                <div className="content-body">Bộ phận chăm sóc khách hàng sẽ liên hệ với bạn sau khi đã xác nhận thông
                    tin.
                    Thời gian xác nhận tối thiểu từ 2 - 4 tiếng trong giờ hành chính từ Thứ2 - Thứ 7 hàng tuần (trừ ngày
                    nghỉ, lễ tết...)
                </div>
            </div>
        </>
    }

    return <div className={"reg-buymore"}>
        {formType !== 2 && (
            <span>Đăng ký {selected?.name}</span>
        )}

        {renderBody()}

        {formType !== 2 && (
            <div className="sub-footer">
                Sau khi bạn xác nhận mua thêm gói dịch vụ, bộ phận chăm sóc khách hàng sẽ liên hệ với bạn để xác minh và trao đổi về quy trình, thủ tục đăng ký và làm hợp đồng.
            </div>
        )}
    </div>
});

export default RegNewService;