import {
  STATUS_PROCESS_REQUEST,
  STATUS_REQUEST,
} from "../../../../../constants/enum";
import { useEffect, useState } from "react";

import TableComponent from "../../../components/table";
import "./ExtendPackage.scss";
import IconDow from "../../../../../components/svg/icon-dow";
import IconUp from "../../../../../components/svg/icon-up";
import { useRequest } from "../../../useRequest";
import { useDashboard } from "../../../../useDashboard";

const ExtendPackage = () => {
  const { getAllUserRequest } = useRequest();
  const { getTotalExtendPackage } = useDashboard();
  const [dataSourcePkg, setDataSourcePkg] = useState([]);
  const [totalPkgSub, setTotalPkgSub] = useState(0);
  console.log("dataSourcePkg", dataSourcePkg);
  useEffect(() => {
    getAllUserRequest({
      status: STATUS_REQUEST.WAIT_EXTEND,
      statusProcess: STATUS_PROCESS_REQUEST.NO_PROCESS,
    }).then((res) => {
      setDataSourcePkg(res);
    });
    getTotalExtendPackage().then((res) => {
      setTotalPkgSub(res.data);
    });
  }, []);

  const dataSource = [
    {
      key: "1",
      content: "Hôm nay",
      countRegister: <>{dataSourcePkg.todayCount || 0}</>,
      countRegSub: <>{totalPkgSub.todayExtend || 0}</>,
      ratio: <>{(dataSourcePkg.changeTodayYesterday || 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioTodayYesterdayExtend || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.todayCount >= dataSourcePkg.yesterdayCount ? <IconUp /> : <IconDow />,
      iconSub: totalPkgSub?.todayExtend >= totalPkgSub?.yesterdayExtend ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.todayCount >= dataSourcePkg.yesterdayCount ? "column-content-up" : "column-content-dow",
      ratioClassSub: totalPkgSub?.todayExtend >= totalPkgSub?.yesterdayExtend ? "column-content-up" : "column-content-dow",
      ratioExtendCountSub: (dataSourcePkg.todayCount > 0 ? totalPkgSub?.todayExtend / dataSourcePkg.todayCount * 100 : 0).toFixed(0) 
    },
    {
      key: "2",
      content: "Hôm qua",
      countRegister: <>{dataSourcePkg.yesterdayCount || 0}</>,
      countRegSub: <>{totalPkgSub.yesterdayExtend || 0}</>,
      ratio: <>{(dataSourcePkg.changeYesterdayTwoDaysAgo || 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioYesterdayTwoDaysAgoExtend || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.yesterdayCount >= dataSourcePkg.twoDaysAgoCount ? <IconUp /> : <IconDow />,
      iconSub: totalPkgSub?.yesterdayExtend >= totalPkgSub?.twoDaysAgoExtend ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.yesterdayCount >= dataSourcePkg.twoDaysAgoCount ? "column-content-up" : "column-content-dow",
      ratioClassSub: totalPkgSub?.yesterdayExtend >= totalPkgSub?.twoDaysAgoExtend ? "column-content-up" : "column-content-dow",
      ratioExtendCountSub: (dataSourcePkg.yesterdayCount > 0 ? totalPkgSub?.yesterdayExtend / dataSourcePkg.yesterdayCount * 100 : 0).toFixed(0)
    },
    {
      key: "3",
      content: "Tháng này",
      countRegister: <>{dataSourcePkg.thisMonthCount || 0}</>,
      countRegSub:<>{totalPkgSub.thisMonth || 0}</>,
      ratio: <>{(dataSourcePkg.ratioThisMonthLastMonth || 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioThisLastMonthExtend || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.thisMonthCount >= dataSourcePkg.lastMonthCount ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.thisMonthCount >= dataSourcePkg.lastMonthCount ? "column-content-up" : "column-content-dow",
      iconSub: totalPkgSub?.thisMonth >= totalPkgSub?.lastMonth ? <IconUp /> : <IconDow />,
      ratioClassSub: totalPkgSub?.thisMonth >= totalPkgSub?.lastMonth ? "column-content-up" : "column-content-dow",
      ratioExtendCountSub: (dataSourcePkg.thisMonthCount > 0 ? totalPkgSub?.thisMonth / dataSourcePkg.thisMonthCount * 100 : 0).toFixed(0) 
    },
    {
      key: "4",
      content: "Tháng trước",
      countRegister: <>{dataSourcePkg.lastMonthCount || 0}</>,
      countRegSub: <>{totalPkgSub.lastMonth || 0}</>,
      ratio: <>{(dataSourcePkg.ratioLastMonthTwoMonthsAgo || 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioLastTwoMonthsExtend || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.lastMonthCount >= dataSourcePkg.twoMonthsAgoCount ? <IconUp /> : <IconDow />,
      iconSub: totalPkgSub?.lastMonth >= totalPkgSub?.twoMonthsAgo ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.lastMonthCount >= dataSourcePkg.twoMonthsAgoCount ? "column-content-up" : "column-content-dow",
      ratioClassSub: totalPkgSub?.lastMonth >= totalPkgSub?.twoMonthsAgo ? "column-content-up" : "column-content-dow",
      ratioExtendCountSub: (dataSourcePkg.lastMonthCount > 0 ? totalPkgSub?.lastMonth / dataSourcePkg.lastMonthCount * 100: 0).toFixed(0) 
    }
  ]

  return (
    <div>
      <TableComponent
        dataSource={dataSource}
        bordered={true}
        // // rowSelection={true}
        // totalCount={total}
        // page={page}
        // setPage={setPage}
        // pageSize={size}
        // setSize={setSize}
        pagination={false}
        columns={[
          {
            title: "Thời gian",
            width: 150,
            dataIndex: "fullName",
            key: "fullName",
            render: (val, record) => {
              return <span>{record.content}</span>;
            },
          },
          {
            title: "Lượt gia hạn gói",
            width: 256,
            dataIndex: "userStatus",
            key: "userStatus",
            render: (val, record) => {
              return (
                <div className="column-pkg">
                  <span>{record.countRegister}</span>
                  <div className={record.ratioClass}>
                    {record.icon}
                    <span>{record.ratio}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Gia hạn gói thành công",
            width: 256,
            dataIndex: "serviceName",
            key: "serviceName",
            render: (val, record) => {
              return (
                <div className="column-pkg">
                  <span>{record.countRegSub}</span>
                  <div className={record.ratioClassSub}>
                    {record.iconSub}
                    <span>{record.ratioSub}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Tỉ lệ",
            width: 216,
            dataIndex: "ratioRegCountSub",
            key: "ratioRegCountSub",
            render: (val, record) => {
              const ratioExtendCountSub = record.ratioExtendCountSub
              const progressWidth = Math.min(ratioExtendCountSub, 100) + '%';
              
              return (
                <div className="column-content-ratio">
                  <div className="column-content-bar">
                    <div className="bar" style={{width: progressWidth, background: `linear-gradient(to right, #477aff, #22e16f)`}}></div>
                  </div>
                  <span>{(record.ratioExtendCountSub) + "%"}</span>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};
export default ExtendPackage;
