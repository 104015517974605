import React, {
    forwardRef, useEffect,
    useImperativeHandle, useRef,
    useState,
} from "react";
import {Radio, Form, Input, InputNumber, Row, Col, notification, Checkbox} from "antd";
import {useSelector} from "react-redux";
import TextArea from "antd/es/input/TextArea";
import {v4 as uuidv4} from 'uuid';

import {AlertType, openNotificationWithIcon} from "../../../utils/AlertMessage";
import {packageListSelector} from "../../../redux/slice/user/userSelector";
import "./buyMore.scss";
import {formatterNumber, parserNumber} from "../../../utils/helper";
import {EBuyMoreType, ETypeGroup} from "../../../constants/enum";

import {UploadFileCustom} from "../../../components/form/UploadFileCustom";
import {SERVER_URL} from "../../../constants/apiConstants";
import {SelectedData} from "../../../components/SettingStatusValue/SettingStatusValue";

import {useGroupObject} from "../groupObject/useGroupObject";

const FormService = forwardRef(({onSave}, ref) => {
    const [packageId, setPackageId] = useState("");
    const [form] = Form.useForm();
    const packageList = useSelector(packageListSelector) || [];
    const formIdRef = useRef(null);
    const [priceInput, setPriceInput] = useState(0);
    const [currentServiceUpdate, setCurrentServiceUpdate] = useState({});
    const [typeServiceBuyMore, setTypeServiceBuyMore] = useState(0);
    const [fileListState, setFileListState] = useState([])
    const [fileListShow, setFileListShow] = useState([])
    const [fileDeletes, setFileDeletes] = useState([]);
    const [groupOption, setGroupOption] = useState([]);
    const {
        getListGroupObjectApi,
        createGroupObjectApi,
        updateGroupObjectApi,
        deleteGroupObjectApi,
    } = useGroupObject();
    const realoadGroupObject = () => {
        getListGroupObjectApi({type: ETypeGroup.GROUP_BUY_MORE}).then((res) => {
            setGroupOption(res.data)
        })
    }
    useImperativeHandle(ref, () => ({
        submitForm() {
            form.validateFields().then((values)=>{
                console.log(values)
                onFinish(values)
            }).catch((error)=>{
                console.log(error);
            });
        },
        setDataForm(dataForm, isDuplicate = false) {
            setCurrentServiceUpdate(dataForm)

            form.setFieldsValue({
                name: dataForm?.name,
                description: dataForm?.description,
                price: dataForm?.price || 0,
                unit: dataForm?.unit,
                type: dataForm?.serviceType,
                thumbnail: dataForm?.thumbnail,
                code: dataForm?.code,
                oldCode: dataForm?.code,
                groupId: dataForm?.groupId,
            });
            if (dataForm?.serviceType === EBuyMoreType.ADDON) {
                setTypeServiceBuyMore(dataForm?.serviceType);
                if (dataForm?.thumbnail) {
                    let filePath = `${SERVER_URL}/${dataForm?.thumbnail}`;
                    setFileListShow([
                        {
                            uid: uuidv4(),
                            name: filePath.substring(filePath.lastIndexOf('/') + 1),
                            baseThumbnail: dataForm?.thumbnail,
                            status: "done",
                            url: filePath,
                            thumbUrl: filePath
                        }
                    ])
                }
            }
            if (!isDuplicate) {
                formIdRef.current = dataForm?.id;
                setPackageId([dataForm?.packageId] || []);
            } else {
                formIdRef.current = null;
                setPackageId([]);
            }

        },
        clearForm() {
            setFileListShow([]);
            setFileListState([]);
            setFileDeletes([]);
            formIdRef.current = null;
            setPackageId([]);
            setCurrentServiceUpdate(null);
            setTypeServiceBuyMore(0);
            form.resetFields();
        }
    }));
    useEffect(() => {
        realoadGroupObject()
    }, []);
    const onChange = (checkedValues) => {
        setPackageId(checkedValues || []);
    };

    const onChangeType = (e) => {
        setTypeServiceBuyMore(e.target.value);
    };
    const onFinish = (values) => {

        if (!packageId || packageId.length === 0) {
            openNotificationWithIcon(
                notification,
                AlertType.warning,
                "Vui lòng chọn gói"
            );
            return;
        }
        const params = {
            name: values.name,
            description: values.description,
            price: values.price,
            unit: values.unit,
            packageId: packageId,
            type: values.type,
            code: values.code,
            oldCode: values.oldCode,
            groupId: values.groupId,
            thumbnail: currentServiceUpdate?.thumbnail || "",
        };
        if (formIdRef.current) {
            params.id = formIdRef.current;
        }

        onSave && onSave(params, fileListState, fileDeletes);
    }

    return (
        <>
            <div className="container-add-service">
                <div className="select-package">
                    <span>Lựa chọn gói áp dụng</span>
                    <Checkbox.Group onChange={onChange} value={packageId}>
                        {packageList.map((item) => (
                            <Checkbox
                                className="custom-radio"
                                key={item.id}
                                style={{marginRight: 15}}
                                value={item.id}
                            >
                                {item.name}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                </div>
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    className="form-item"
                    onFinish={onFinish}

                    form={form}
                >
                    <Form.Item
                        label={<div className="form-title">Loại dịch vụ</div>}
                        name="type"
                    >
                        <Radio.Group onChange={onChangeType} defaultValue={EBuyMoreType.NORMAL}>
                            {[
                                {
                                    label: "Loại thường",
                                    value: EBuyMoreType.NORMAL
                                },
                                {
                                    label: "Addon",
                                    value: EBuyMoreType.ADDON
                                },
                            ].map(({label, value}) => (
                                <Radio key={Math.random()} style={{marginRight: 15}} value={value}>{label}</Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    {typeServiceBuyMore === EBuyMoreType.ADDON && (
                        <Form.Item
                            label={<div className="form-title">Ảnh dịch vụ</div>}
                            name=""
                        >
                            <UploadFileCustom
                                fileListState={fileListState}
                                accept={".png,.jpg,.svg"}
                                fileListShow={fileListShow}
                                disabled={false}
                                onRemove={(file: any, form: any) => {
                                    setFileDeletes([...fileDeletes, file]);
                                }}
                                beforeUpload={(file: any, FileList: any) => {
                                    if (fileListShow?.length >= fileListState?.length) {
                                        setFileListState(FileList);
                                    }
                                }}
                                onChange={({fileList: newFileList}) => {
                                    let newListImages: any = newFileList?.map((file: any) => {
                                        if (file.status == "error") {
                                            file.error = {
                                                statusText: "File Tải lên bị lỗi!",
                                            }
                                        } else {
                                            file.status = 'done'
                                        }

                                        return file
                                    })

                                    setFileListShow(newListImages);
                                }}

                                maxCount={1}
                            />
                        </Form.Item>
                    )}
                    {typeServiceBuyMore === EBuyMoreType.ADDON && (
                      <>
                          <Form.Item
                              label={<div className="form-title">Mã dịch vụ</div>}
                              name="code"
                              rules={[{required: true, message: "Vui lòng nhập Mã!"}]}
                          >
                              <Input placeholder="Nhập vào"/>
                          </Form.Item>
                          <Form.Item
                              style={{display:"none"}}
                              label={<div className="form-title">Mã dịch vụ</div>}
                              name="oldCode"
                           >
                              <Input placeholder="Nhập vào"/>
                          </Form.Item>
                      </>
                    )}
                    <Form.Item
                        label={<div className="form-title">Nhóm dịch vụ</div>}
                        name="groupId"

                    >
                        <SelectedData

                            typeValue={ETypeGroup.GROUP_BUY_MORE}
                            allowClear={true}
                            canEdit={true}
                            //value, reloadData
                            addItemCustom={(value: any, reloadData: any) => {
                                createGroupObjectApi({
                                    name: value,
                                    type: ETypeGroup.GROUP_BUY_MORE,
                                }).then((res) => {
                                    if (res.isSuccess) {
                                        openNotificationWithIcon(
                                            notification,
                                            AlertType.success,
                                            "Tạo thành công"
                                        );
                                        reloadData();
                                    } else {
                                        openNotificationWithIcon(
                                            notification,
                                            AlertType.error,
                                            "Tạo thất bại"
                                        );
                                    }


                                });
                            }}
                            reloadCustom={() => {
                                realoadGroupObject()
                            }}
                            //id , name , reloadData
                            updateItemCustom={(id: any, name: any, reloadData: any) => {
                                let findEl = groupOption?.find((item: any) => {
                                    return item?.id.includes(id)
                                })

                                if (findEl) {
                                    updateGroupObjectApi({
                                        id: id,

                                        name: name,
                                        type: ETypeGroup.GROUP_BUY_MORE,
                                    }).then((res) => {
                                        if (res.isSuccess) {
                                            openNotificationWithIcon(
                                                notification,
                                                AlertType.success,
                                                "Cập nhật thành công"
                                            );
                                            reloadData();
                                        } else {
                                            openNotificationWithIcon(
                                                notification,
                                                AlertType.error,
                                                "Cập nhật thất bai"
                                            );
                                        }

                                    })
                                } else {
                                    openNotificationWithIcon(
                                        notification,
                                        AlertType.error,
                                        "Cập nhật thất bai"
                                    );
                                }

                            }}
                            //id , reloadData
                            delItemCustom={(id: any, reloadData: any) => {
                                let findEl = groupOption?.find((item: any) => {
                                    return item?.id.includes(id)
                                })
                                if (findEl) {
                                    deleteGroupObjectApi({id: findEl?.id}).then((res) => {
                                        if (res.isSuccess) {
                                            openNotificationWithIcon(
                                                notification,
                                                AlertType.success,
                                                "Xóa thành công"
                                            );
                                            reloadData();
                                        } else {
                                            openNotificationWithIcon(
                                                notification,
                                                AlertType.error,
                                                "Xóa thất bai"
                                            );
                                        }

                                    });
                                } else {
                                    openNotificationWithIcon(
                                        notification,
                                        AlertType.error,
                                        "Xóa thất bai"
                                    );
                                }

                            }}
                            options={groupOption}

                        />
                    </Form.Item>
                    <Form.Item
                        label={<div className="form-title">Tên dịch vụ</div>}
                        name="name"
                        rules={[{required: true, message: "Vui lòng nhập tên dịch vụ!"}]}
                    >
                        <Input placeholder="Nhập vào"/>
                    </Form.Item>
                    <Form.Item
                        label={<div className="form-title">Mô tả dịch vụ</div>}
                        name="description"
                    >
                        <TextArea
                            placeholder="Nhập vào"
                            rows={4}
                            // autoSize={{ minRows: 5, maxRows: 6  }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<div className="form-title">Giá và đơn vị</div>}
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    name="price"
                                >
                                    <InputNumber placeholder="Nhập vào"
                                                 value={priceInput}
                                                 min={0}
                                                 formatter={formatterNumber}
                                                 parser={parserNumber}
                                                 style={{minWidth: 210}}
                                                 onChange={e => {
                                                     setPriceInput(e)
                                                 }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="unit"
                                >
                                    <Input placeholder="Nhập vào"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
});

export default FormService;
