import { combineReducers } from '@reduxjs/toolkit';
import authSlice from '../slice/auth/authSlice';
import userSlice from '../slice/user/userSlice';
import adminSlice from '../slice/admin/adminSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  admin: adminSlice,
});

export default rootReducer;
