const ElipseRight = () => {
return <svg width="118" height="277" viewBox="0 0 118 277" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M234.399 265.94C160.586 306.279 60.273 219.655 18.3944 143.025C-23.4842 66.3949 9.55474 74.367 83.3675 34.0281C157.18 -6.31089 198.767 -21.5012 240.646 55.1288C282.524 131.759 308.212 225.601 234.399 265.94Z" fill="url(#paint0_linear_1014_5718)"/>
    <defs>
        <linearGradient id="paint0_linear_1014_5718" x1="-35.7351" y1="67.0565" x2="251.627" y2="235.999" gradientUnits="userSpaceOnUse">
            <stop stop-color="#22E16F"/>
            <stop offset="1" stop-color="#477AFF"/>
        </linearGradient>
    </defs>
</svg>
}

export default ElipseRight;