import { useCallback } from 'react';
import {useDispatch} from "react-redux";
import {notification} from "antd";
import {
    createPackageApi,
    getAllPackageApi,
    updatePackageApi,
    deletePackageApi
} from "../../../redux/slice/admin/adminApis";
import {AlertType, openNotificationWithIcon} from "../../../utils/AlertMessage";
import {ERROR_API} from "../../../constants/errorMessages";

export const usePackage = () => {
    const dispatch = useDispatch();
    // let userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN));

    const getAllPackage = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {
                const resData = await dispatch(getAllPackageApi(payload)).unwrap();
                resolve({ isSuccess: true, data: resData.items});
            } catch (e) {
                resolve({ isSuccess: false });
            }
        });
    }, []);

    const createOrUpdatePackage = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {
                let msgSuccess = "Tạo gói thành công";
                // Update
                if(payload.packageId)
                {
                    await dispatch(updatePackageApi(payload)).unwrap();
                    msgSuccess = "Cập nhật gói thành công";
                    openNotificationWithIcon(notification, AlertType.success, msgSuccess);
                }
                // Insert
                else {
                    await dispatch(createPackageApi(payload)).unwrap();
                    openNotificationWithIcon(notification, AlertType.success, msgSuccess);
                }

                resolve({ isSuccess: true});
            } catch (e) {
                openNotificationWithIcon(notification, AlertType.error, e?.response?.data?.message || ERROR_API);
                resolve({ isSuccess: false });
            }
        });
    }, []);

    const deletePackage = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {
                await dispatch(deletePackageApi(payload)).unwrap();
                openNotificationWithIcon(notification, AlertType.success, "Xóa gói thành công");
                resolve({isSuccess: true});
            } catch (e) {
                openNotificationWithIcon(notification, AlertType.error, e?.response?.data?.message || ERROR_API);
                resolve({isSuccess: false});
            }
        });
    }, []);


    return {
        getAllPackage,
        createOrUpdatePackage,
        deletePackage
    };
};
