import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, notification} from "antd";

import "./ForgotPassword.scss";
import ButtonCustomize from "../../components/button";
import { BtnTypeEnum } from "../../components/button/const";
import LoginForm from "../login-register/LoginForm/LoginForm";
import { useUser } from "../../pages/client/useUser";
import { AlertType, openNotificationWithIcon } from "../../utils/AlertMessage";


function ForGotPassword() {
  const { updateForgotPassword } = useUser();
  const [form] = Form.useForm();
  const [open, isOpen] = useState(Boolean);
  const show = () => {
    isOpen(true);
  };

  const handleForgotPassword = async () => {
    try {
    const values = await form.validateFields();
    await updateForgotPassword({ email: values.email, user_name: values.user_name });
    } catch (e) {
      openNotificationWithIcon(
        notification,
        AlertType.error,
        "Đã xảy ra lỗi. Vui lòng thử lại sau."
      );
    }
  };

  return (
    <>
      {open === false && (
        <div className="forgot-password-card">
          {/* <div className="login-card-mobile">
      <img src={LogoTesop} alt={""} width={150} />
    </div> */}
          <div className="form-register">
            <h2>Yêu cầu đặt lại mật khẩu</h2>
            <p style={{ marginTop: 24, marginBottom: 24 }}>
              Nhập địa chỉ email bạn đã đăng ký tài khoản để đặt lại mật khẩu.
            </p>
            <Form
              className={"form"}
              name="basic"
              layout="vertical"
              form={form}
              onFinish={handleForgotPassword}
              autoComplete="off"
            >
              <div className="col-wrap">
                <div className={"form-item"}>
                <Form.Item
                    label={
                      <>
                        <div className="title-form">Tên tài khoản</div>
                      </>
                    }
                    name="user_name"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên tài khoản",
                      },
                    ]}
                  >
                    <Input
                      rootClassName={"form-input"}
                      placeholder={"Nhập vào"}
                      // onChange={(value) => {
                      //   return handleSearchUsername(value.target.value);
                      // }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <div className="title-form">Email</div>
                      </>
                    }
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập email",
                      },
                      {
                        type: "email",
                        message: "Không đúng định dạng E-mail!",
                      },
                    ]}
                  >
                    <Input
                      rootClassName={"form-input"}
                      placeholder={"Nhập vào"}
                      // onChange={(value) => {
                      //   return handleSearchUsername(value.target.value);
                      // }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="btn_forgot_password">
                <ButtonCustomize
                  title={"Xác nhận, Yêu cầu đặt lại mật khẩu"}
                  btnType={BtnTypeEnum.BLACK}
                  btnSize={44}
                  onPressHandle={handleForgotPassword}
                />
                <ButtonCustomize
                  title={"Hủy bỏ"}
                  btnType={BtnTypeEnum.OUTLINE}
                  btnSize={44}
                  onPressHandle={show}
                />
              </div>
              <p className="footer_forgot_password">
                Có thể mất đến vài phút để email kèm link đặt lại mật khẩu mới
                được gửi tới bạn, nếu thử lại vài lần vẫn không có email gửi tới
                bạn vui lòng liên hệ Hỗ trợ để được giúp trợ giúp.
              </p>
            </Form>
          </div>
        </div>
      )}
      <div>{open === true && <LoginForm />}</div>
    </>
  );
}

export default withRouter(ForGotPassword);
