import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authInfo: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // authenticated: (state, action) => {
    //     state.loading = false;
    //     state.redirect = '/';
    //     state.token = action.payload;
    // },
    // signOutSuccess: (state) => {
    //   state.loading = false;
    //   state.token = null;
    // },
    // showLoading: (state) => {
    //   state.loading = true;
    // },
    setAuthInfo: (state, action) => {
      state.authInfo = action.payload;
    },
    clearAuthInfo: (state) => {
      state.authInfo = null;
    }
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(signIn.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(signIn.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.token = action.payload.tokenData.token;
    //     state.userRoles = action.payload.userRoles;
    //   })
    //   .addCase(signIn.rejected, (state, action) => {
    //     state.message = `${action.payload}`;
    //     state.showMessage = true;
    //     state.loading = false;
    //   })
  },
});

export const { setAuthInfo, clearAuthInfo} = authSlice.actions;

export default authSlice.reducer;
