import React from "react";
import {Button, notification, Popconfirm} from "antd";

import ButtonCustomize from "../../../components/button";
import {BtnTypeEnum} from "../../../components/button/const";
import {formatInputMoney} from "../../../utils/helper";
import './buyMore.scss';
import {EBuyMoreSystem} from "../../../constants/enum";
import {Tooltip, Badge} from 'antd';
import {SERVER_URL} from "../../../constants/apiConstants";
import {AlertType, openNotificationWithIcon} from "../../../utils/AlertMessage";

const ServiceItem = ({item, isSameUserPkg = false, isSetting = true, onDelete, onDuplicate, onUpdate, onUserReg}) => {
    return <div className="card-service">
        <div className="title-service">
            <div className={"subtitle-service"}>
                <span>{item.name}</span>
                {isSetting && item.typeSystem > EBuyMoreSystem.NORMAL && (
                    <span>Hệ thống</span>
                )}
            </div>
            {isSetting ? (
                <>
                    {item.typeSystem === EBuyMoreSystem.NORMAL && (
                        <Popconfirm
                            title="Xóa bản ghi"
                            description="Bạn có muốn xóa bản ghi này?"
                            onConfirm={onDelete(item.id)}
                            okText="Đồng ý"
                            cancelText="Hủy"
                        >
                            <Button danger>Xóa</Button>
                        </Popconfirm>
                    )}
                    <ButtonCustomize
                        title={"Nhân bản"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onDuplicate(item)}
                    />
                    <ButtonCustomize
                        title={"Thiết lập"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onUpdate(item)}
                    />
                </>) : (
                <ButtonCustomize
                    isDisabled={!isSameUserPkg}
                    title={"Đăng ký"}
                    btnType={isSameUserPkg ? BtnTypeEnum.GREEN : BtnTypeEnum.GREY}
                    btnSize={32}
                    onPressHandle={onUserReg(item)}
                />
            )}
        </div>
        <ul className={"buy-more-desc"}>
            <li>{item.description}</li>
            <li>
                <span>{formatInputMoney(item?.price || 0)} đ</span>
                <span>{item.unit}</span>
            </li>
        </ul>
    </div>
}
const CardItem = (props: any) => {
    const {name, price, thumbnail, description, id, code, unit,typeSystem} = props.item
    const item = props.item
    const onDelete = props.onDelete
    const onDuplicate = props.onDuplicate
    const onUpdate = props.onUpdate
    const onSetting = () => {
        openNotificationWithIcon(notification, AlertType.info, "Bạn đã mua gói này");
    }

    let isSetting = props.isSetting || false
    let isBuy = props?.isBuy || false;
    const content = (
        <div className={'wrapper_word'}>
            {description}
        </div>
    )
    return (
        <section className="card-extension">

            <div className="wrap-header">

                <header>
                    <Tooltip title={name}>
                        <h2 className="title truncate">{name}
                        </h2>
                    </Tooltip>
                    <Tooltip title={price}>
                        <h1 className="price truncate">{formatInputMoney(price || 0)} {unit || ''}</h1>
                    </Tooltip>

                </header>
                {thumbnail && (
                    <img
                        src={`${SERVER_URL}/${thumbnail}`}
                        alt=""/>
                )}

            </div>


            <Tooltip title={content}>
                <p className="desc">
                    {description}
                </p>
            </Tooltip>

            {isSetting ? (
                <div  className={"actions-buy-more-admin"}>
                    {typeSystem === EBuyMoreSystem.NORMAL && (
                        <Popconfirm
                            title="Xóa bản ghi"
                            description="Bạn có muốn xóa bản ghi này?"
                            onConfirm={onDelete(item.id)}
                            okText="Đồng ý"
                            cancelText="Hủy"
                        >
                            <Button danger>Xóa</Button>
                        </Popconfirm>
                    )}
                    <ButtonCustomize
                        title={"Nhân bản"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onDuplicate(item)}
                    />
                    <ButtonCustomize
                        title={"Thiết lập"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onUpdate(item)}
                    />
                </div>) : (
            <button className={`action btn-extension ${isBuy ? "btn-disabled" : ""}`} onClick={() => {
                let buyMoreItem = props.item
                let canBuyMore = isBuy && buyMoreItem.typeSystem === EBuyMoreSystem.NORMAL
                if (canBuyMore) {
                    onSetting(code)
                } else {
                    let setModalForm = props.setModalForm
                    let formRegRef = props.formRegRef
                    setModalForm({
                        isOpen: true,
                        title: "",
                        type: buyMoreItem.typeSystem === EBuyMoreSystem.NORMAL ? 0 : 1
                    });
                    if (formRegRef.current) {
                        formRegRef.current?.setFormSource(buyMoreItem);
                    } else {
                        setTimeout(() => {
                            formRegRef.current?.setFormSource(buyMoreItem);
                        }, 200);
                    }

                }
            }}>
                {isBuy ? ( item.typeSystem === EBuyMoreSystem.NORMAL ? "Đã mua" :"Mua thêm") : "Mua ngay"}
            </button>)
            }
        </section>
    )
}
export default CardItem;