export  const businessType = [
    {
        "value": 1,
        "label": "Giáo Dục"
    },
    {
        "value": 2,
        "label": "Kế Toán/Kiểm Toán"
    },
    {
        "value": 20,
        "label": "Hành Chính Văn Phòng"
    },
    {
        "value": 3,
        "label": "Nông/Lâm/Ngư Nghiệp"
    },
    {
        "value": 4,
        "label": "Kiến Trúc/Xây Dựng"
    },
    {
        "value": 18,
        "label": "Nghệ thuật, Truyền thông/In ấn/Xuất bản"
    },
    {
        "value": 10,
        "label": "Ngân Hàng & Dịch Vụ Tài Chính"
    },
    {
        "value": 29,
        "label": "CEO & General Management"
    },
    {
        "value": 6,
        "label": "Dịch Vụ Khách Hàng"
    },
    {
        "value": 7,
        "label": "Thiết Kế"
    },
    {
        "value": 9,
        "label": "Khoa Học & Kỹ Thuật"
    },
    {
        "value": 11,
        "label": "Dịch Vụ Ăn Uống"
    },
    {
        "value": 25,
        "label": "Chính Phủ/Phi Lợi Nhuận"
    },
    {
        "value": 19,
        "label": "Y Tế/Chăm Sóc Sức Khoẻ"
    },
    {
        "value": 15,
        "label": "Nhà Hàng - Khách Sạn/Du Lịch"
    },
    {
        "value": 12,
        "label": "Nhân Sự/Tuyển Dụng"
    },
    {
        "value": 5,
        "label": "Công Nghệ Thông Tin/Viễn Thông"
    },
    {
        "value": 14,
        "label": "Bảo Hiểm"
    },
    {
        "value": 16,
        "label": "Pháp Lý"
    },
    {
        "value": 13,
        "label": "Hậu Cần/Xuất Nhập Khẩu/Kho Bãi"
    },
    {
        "value": 27,
        "label": "Sản Xuất"
    },
    {
        "value": 17,
        "label": "Tiếp Thị, Quảng Cáo/Truyền Thông"
    },
    {
        "value": 28,
        "label": "Dược"
    },
    {
        "value": 23,
        "label": "Bất Động Sản"
    },
    {
        "value": 24,
        "label": "Bán Lẻ/Tiêu Dùng"
    },
    {
        "value": 21,
        "label": "Kinh Doanh"
    },
    {
        "value": 22,
        "label": "Kỹ Thuật"
    },
    {
        "value": 26,
        "label": "Dệt May/Da Giày"
    },
    {
        "value": 8,
        "label": "Vận Tải"
    },
    {
        "value": 30,
        "label": "Khác"
    }
]