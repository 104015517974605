import "./body-modal.scss";

const ConfirmInfo = ({ data, formType }) => {
  console.log("data: ", data);
  return (
    <>
      <div className="container-body-modal">
        <div className="card-body-modal">
          <span>Thông tin cá nhân</span>
          <ul>
            <li>Họ tên:</li>
            <li>{data?.fullName}</li>
          </ul>
          <ul>
            <li>Tài khoản:</li>
            <li>{data?.username}</li>
          </ul>
          <ul>
            <li>Email:</li>
            <li>{data?.email}</li>
          </ul>
          <ul>
            <li>Số điện thoại:</li>
            <li>{data?.phoneNumber}</li>
          </ul>
        </div>

        {formType === "business" && (
          <>
            <div className="card-body-modal">
              <span>Thông tin doanh nghiệp</span>
              <ul>
                <li>Domain:</li>
                <li>{data?.tenantId}</li>
              </ul>
              <ul>
                <li>Mã số thuế:</li>
                <li>{data?.taxCode}</li>
              </ul>
              <ul>
                <li>Tên doanh nghiệp:</li>
                <li>{data?.companyName}</li>
              </ul>
              <ul>
                <li>Địa chỉ kinh doanh</li>
                <li>{data?.address}</li>
              </ul>
              <ul>
                <li>Lĩnh vực kinh doanh:</li>
                <li>{data?.business_type}</li>
              </ul>
            </div>
          </>
        )}
    
        {formType === "personal" && (
          <>
            <div className="card-body-modal">
              <span>Thông tin cá nhân</span>
              <ul>
                <li>Domain:</li>
                <li>{data?.tenantId}</li>
              </ul>
              <ul>
                <li>CMND/CCCD</li>
                <li>{data?.cardNumber}</li>
              </ul>
              <ul>
                <li>Địa chỉ kinh doanh</li>
                <li>{data?.address}</li>
              </ul>
              <ul>
                <li>Lĩnh vực kinh doanh:</li>
                <li>{data?.business_type}</li>
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConfirmInfo;
