import IconDow from "../../../../components/svg/icon-dow";
import IconUp from "../../../../components/svg/icon-up";
import "./Revenue.scss";
import { formatInputMoney } from "../../../../utils/helper";
const Revenue = (props) => {
  const { totalRevenue, totalMonthRevenue} = props;
  // const ratioFormatted = totalRevenue.ratio.toFixed(1);

  const statusRevenue = (currentRevenue, previousRevenue, ratio, content) => {
    let clsName = "";
    let icon = <IconDow />;
    if (currentRevenue > previousRevenue) {
      clsName = "column-up-revenue";
      icon = <IconUp />;
    }
    if (currentRevenue < previousRevenue) {
      clsName = "column-down-revenue";
      icon = <IconDow />;
    }
    if (currentRevenue === previousRevenue) {
      clsName = "column-equal-revenue";
      icon = null;
    }

    return (
      <>
        <div className="column-revenue">
          <div className={clsName}>
            {icon}
            <span>{(ratio)?.toFixed(0)} %</span>
          </div>
          <span>{content}</span>
        </div>
      </>
    );
  };

  const statusRevenueToday = statusRevenue(
    totalRevenue?.totalTodayRevenue,
    totalRevenue?.totalYesterdayRevenue,
    totalRevenue?.ratio,
    "So với hôm qua"
  );

  const statusRevenueYesterday = statusRevenue(
    totalRevenue?.totalYesterdayRevenue,
    totalRevenue?.totalTwoDaysAgoRevenue,
    totalRevenue?.ratioDayOneAgo,
    "So với 2 hôm trước"
  );

  const statusRevenueMonth = statusRevenue(
    totalMonthRevenue.totalThisMonthRevenue,
    totalMonthRevenue.totalLastMonthRevenue,
    totalMonthRevenue.monthRatio,
    "So với tháng trước"
  );

  const statusRevenueOneMonth = statusRevenue(
    totalMonthRevenue.totalLastMonthRevenue,
    totalMonthRevenue.totalTwoMonthsAgoRevenue,
    totalMonthRevenue.monthRatioOneAgo,
    "So với 2 tháng trước"
  );

  return (
    <div className="container-revenue">
      <p>Doanh thu</p>
      <table>
        <tr>
          <td>
            <div className="container-column">
              <div className="column-revenue">
                <span>Hôm nay</span>
                <span>
                  {formatInputMoney(totalRevenue.totalTodayRevenue || 0)} đ
                </span>
              </div>
              {statusRevenueToday}
            </div>
          </td>
          <td>
            <div className="container-column">
              <div className="column-revenue">
                <span>Hôm qua</span>
                <span>
                  {formatInputMoney(totalRevenue.totalYesterdayRevenue || 0)} đ
                </span>
              </div>
              {statusRevenueYesterday}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="container-column">
              <div className="column-revenue">
                <span>Tháng này</span>
                <span>{formatInputMoney(totalMonthRevenue.totalThisMonthRevenue || 0)} đ</span>
              </div>
             {statusRevenueMonth}
            </div>
          </td>
          <td>
            <div className="container-column">
              <div className="column-revenue">
                <span>Tháng trước</span>
                <span>{formatInputMoney(totalMonthRevenue.totalLastMonthRevenue || 0)} đ</span>
              </div>
              {statusRevenueOneMonth}
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Revenue;
