import React, { useRef, useState } from "react";
import { Tabs } from "antd";

import "./Indicators.scss";
import RegPackage from "./RegPackage";
import ExtendPackage from "./ExtendPackage";
import ChangePackage from "./ChangePackage";
import RegService from "./RegService";
import ExtendService from "./ExtendService";

const Indicators = () => {
  const [tabSource] = useState([
    {
      id: "tab-reg-sub",
      title: "Đăng ký gói",
      children: <RegPackage />,
    },
    {
      id: "tab-extend-sub",
      title: "Gia hạn gói",
      children: <ExtendPackage />,
    },
    {
      id: "tab-change-sub",
      title: "Thay đổi gói",
      children: <ChangePackage />,
    },
    {
      id: "tab-service-sub",
      title: "Đăng ký dịch vụ",
      children: <RegService />,
    },
    // {
    //   id: "tab-service-extend",
    //   title: "Gia hạn dịch vụ",
    //   children: <ExtendService />,
    // },
  ]);
  return (
    <div className="container-indicators">
      <p>Các chỉ số</p>
      <div>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"top"}
          onChange={(key) => key}
          items={tabSource.map((item) => {
            return {
              key: `${item.id}`,
              label: `${item.title}`,
              children: item.children,
            };
          })}
        />
      </div>
    </div>
  );
};

export default Indicators;
