import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";

import IconServiceBlack from "../../svg/icon-service-black";
import HeadPhone from "../../svg/headphone";
import Product from "../../svg/product";
import Profile from "../../svg/profile";
import IconProfileWhite from "../../svg/icon-profile-white";
import IconHomeWhite from "../../svg/icon-home-white";
import IconHomeBlack from "../../svg/icon-home-black";
import IconUserWhite from "../../svg/icon-user-white";
import IconUserBlack from "../../svg/icon-user-black";
import IconPackageWhite from "../../svg/icon-package-white";
import IconPackageBlack from "../../svg/icon-package-black";
import IconServiceWhite from "../../svg/icon-service-white";
import IconPackageWhiteClient from "../../svg/product-white";
import IconServiceWhiteClient from "../../svg/category-white";
import IconPhoneWhiteClient from "../../svg/phone-white";
import "./menu.scss";

function MenuLayout(props) {
  const { isAdmin, onMenuItemClick, collapsed } = props;
  console.log("collapsed", collapsed);
  const { pathname } = useLocation();
  const show = (url) => {
    props.history.push(url);
  };

  let items = [
    {
      icon: <Profile />,
      iconActive: <IconProfileWhite />,
      title: "Thông tin tài khoản",
      onClick: () => {
        onMenuItemClick("/home");
        return show("/home");
      },
      url: "/home",
    },
    {
      icon: <Product />,
      iconActive: <IconPackageWhiteClient />,
      title: "Các gói dịch vụ",
      onClick: () => {
        onMenuItemClick("/package-crm");
        return show("/package-crm");
      },
      url: "/package-crm",
    },

    {
      icon: <IconServiceBlack />,
      iconActive: <IconServiceWhiteClient />,
      title: "Dịch vụ mua thêm",
      onClick: () => {
        onMenuItemClick("/services");
        return show("/services");
      },
      url: "/services",
    },
    // {
    //   icon: <HeadPhone />,
    //   iconActive: <IconPhoneWhiteClient />,
    //   title: "Hỗ trợ khách hàng",
    //   onClick: () => {
    //     onMenuItemClick("/user-support");
    //     return show("/user-support");
    //   },
    //   url: "/user-support",
    // },
  ];

  if (isAdmin) {
    items = [
      {
        icon: <IconHomeWhite />,
        iconActive: <IconHomeBlack />,
        title: "Dashboard",
        onClick: () => show("/admin/home"),
        url: "/admin/home",
      },
      {
        icon: <IconUserBlack />,
        iconActive: <IconUserWhite />,
        title: "Khách hàng",
        onClick: () => show("/admin/user"),
        url: "/admin/user",
      },

      {
        icon: <IconPackageBlack />,
        iconActive: <IconPackageWhite />,
        title: "Các gói dịch vụ",
        onClick: () => show("/admin/package"),
        url: "/admin/package",
      },
      {
        icon: <IconServiceBlack />,
        iconActive: <IconServiceWhite />,
        title: "Dịch vụ mua thêm",
        onClick: () => show("/admin/feature/item"),
        url: "/admin/feature/item",
      },
    ];
  }

  return (
    <>
      <div className="body-menu">
        <div className={collapsed ? `box-menu ${isAdmin ? "admin" : ""}`:`box-body-menu ${isAdmin ? "admin" : ""}`}>
          {items?.map((item) => {
            let active = pathname === item.url;
            return (
              <div
                key={item.url}
                className={`content ${active ? "active" : ""}`}
                onClick={item.onClick}
              >
                {collapsed ? (active ? item.iconActive : item.icon): (active ? item.iconActive : item.icon)}
                <div className="content-menu">{collapsed && item.title}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default withRouter(MenuLayout);
