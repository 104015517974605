const IconPackageBlack = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1456_5046)">
        <path
          d="M15.5777 3.88197L17.5777 4.93152C19.7294 6.06066 20.8052 6.62523 21.4026 7.63974C22 8.65425 22 9.91667 22 12.4415V12.5585C22 15.0833 22 16.3458 21.4026 17.3603C20.8052 18.3748 19.7294 18.9393 17.5777 20.0685L15.5777 21.118C13.8221 22.0393 12.9443 22.5 12 22.5C11.0557 22.5 10.1779 22.0393 8.42229 21.118L6.42229 20.0685C4.27063 18.9393 3.19479 18.3748 2.5974 17.3603C2 16.3458 2 15.0833 2 12.5585V12.4415C2 9.91667 2 8.65425 2.5974 7.63974C3.19479 6.62523 4.27063 6.06066 6.42229 4.93152L8.42229 3.88197C10.1779 2.96066 11.0557 2.5 12 2.5C12.9443 2.5 13.8221 2.96066 15.5777 3.88197Z"
          stroke="#292D34"
          stroke-width="1.4"
          stroke-linecap="round"
        />
        <path
          d="M14.3358 6.87428L15.6414 7.5594C17.0459 8.29647 17.7482 8.66501 18.1382 9.32726C18.5281 9.9895 18.5281 10.8136 18.5281 12.4617V12.5381C18.5281 14.1862 18.5281 15.0103 18.1382 15.6726C17.7482 16.3348 17.0459 16.7033 15.6414 17.4404L14.3358 18.1255C13.1898 18.7269 12.6168 19.0277 12.0004 19.0277C11.384 19.0277 10.811 18.7269 9.66496 18.1255L8.35941 17.4404C6.95486 16.7033 6.25258 16.3348 5.86262 15.6726C5.47266 15.0103 5.47266 14.1862 5.47266 12.5381V12.4617C5.47266 10.8136 5.47266 9.9895 5.86262 9.32726C6.25258 8.66501 6.95486 8.29647 8.35941 7.5594L9.66496 6.87428C10.811 6.27287 11.384 5.97217 12.0004 5.97217C12.6168 5.97217 13.1898 6.27287 14.3358 6.87428Z"
          fill="#2DC56A"
        />
        <path
          d="M21 8L12 12.5M12 12.5L3 8M12 12.5V22"
          stroke="#292D34"
          stroke-width="1.4"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1456_5046">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconPackageBlack;
