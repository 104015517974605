import React, {useEffect} from 'react';
import {Redirect, Route, useHistory} from "react-router-dom";
import {ACCESS_TOKEN_NAME, KEY_USER_LOGIN} from '../constants/apiConstants';

function CheckAuthencationRoute({children, ...rest}) {
    const user = localStorage.getItem(KEY_USER_LOGIN) ? JSON.parse(localStorage.getItem(KEY_USER_LOGIN)) : null;
    const history = useHistory();

    useEffect(() => {
        const currentPath = history.location.pathname;
        
        if (!user && currentPath !== '/reset-password') {
            history.replace("/login");
        }
    }, [user, history]);
    

    return (
        <Route
            {...rest}
            render={({location}) =>
                localStorage.getItem(ACCESS_TOKEN_NAME) && user ? (
                    user?.is_admin ?
                        <Redirect
                            to={{
                                pathname: "/admin/home",
                                state: {from: location}
                            }}
                        />
                        :
                        <Redirect
                            to={{
                                pathname: "/home",
                                state: {from: location}
                            }}
                        />
                ) : (
                    children
                )
            }
        />
    );
}

export default CheckAuthencationRoute;