import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import ButtonCustomize from "../../../../../components/button";
import { BtnTypeEnum } from "../../../../../components/button/const";
import { useRequest } from "../../../useRequest";
import {
  DEFAULT_PAGE,
  MAX_RESULTS,
} from "../../../../../constants/apiConstants";
import "../../../user/user.scss";
import TableComponent from "../../../components/table";
import { getUserStatusName } from "../../../../../constants/enum";

const ExpiredPackage = () => {
  const { getListUsersHandle } = useRequest();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const statusPackage  = query.get("statusPackage") || 2;

  const [dataSource, setDataSource] = useState([]);
  const [size, setSize] = useState(MAX_RESULTS);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState({});
  useEffect(() => {
    loadData();
  }, [page, size, statusPackage]);

  const loadData = () => {
    getListUsersHandle({
      pageNumber: page,
      pageSize: size,
      statusPackage,
    }).then((res) => {
      setDataSource(res.data);
      setTotal(res.total);
    });
  };
  return (
    <div>
      <TableComponent
        dataSource={dataSource}
        scroll={{y: "calc(100vh - 220px)" }}
        bordered={true}
        // // rowSelection={true}
        totalCount={total}
        page={page}
        setPage={setPage}
        pageSize={size}
        setSize={setSize}
        columns={[
          {
            title: "Khách hàng",
            width: 165,
            dataIndex: "fullName",
            key: "fullName",
            render: (val, record) => {
              return (
                <div className="table-user-column">
                  <span>{record.fullName}</span>
                  <div className="table-box">
                    <span>{record.phoneNumber}</span>
                    <span>-</span>
                    <span>{record.email}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Trạng thái TK",
            width: 80,
            dataIndex: "status",
            key: "status",
            render: (val, record) => {
              const { statusName, clsName } = getUserStatusName(record.status);
              return (
                <div className="table-user-management">
                  <span className={clsName}>{statusName}</span>
                </div>
              );
            },
          },
          {
            title: "Gói đăng ký",
            width: 105,
            dataIndex: "packageName",
            key: Math.random(),
          },
          {
            title: "Ngày đăng ký",
            width: 80,
            dataIndex: "startAt",
            key: "startAt",
            render: (val, record) => {
              return (
                <span>
                  {record?.startAt
                    ? dayjs(record.startAt).format("YYYY-MM-DD")
                    : ""}
                </span>
              );
            },
          },
          {
            title: "Trạng thái gói",
            width: 105,
            dataIndex: "statusProcess",
            key: "statusProcess",
            render: (val, record) => {
              const expiredPkg = record.userPkgStatus === 2;
              if (expiredPkg) {
                return (
                  <div className="table-user-management">
                    <span className="package-status package-status-expired">
                      Đã hết hạn
                    </span>
                  </div>
                );
              }
            },
          },
          {
            title: "Hành động",
            width: 125,
            dataIndex: "action",
            key: "action",
            render: (val, record) => {
              return (
                <ButtonCustomize
                  title={"Xác nhận, đã liên hệ"}
                  btnType={BtnTypeEnum.OUTLINE}
                  btnSize={32}
                  // onPressHandle={onShowModal(record)}
                />
              );
            },
          },
        ]}
      />
    </div>
  );
};
export default ExpiredPackage;
