import React, { useState, useRef } from "react";
import { Tabs } from "antd";
import "./changeSub.scss";
import ChangeSub from "./ChangeSub";
import RegRequestReject from "./Reject";
import ListAllUser from "./ListAllUser";

const ChangeTab = () => {
  const changeRequestRef = useRef(null);
  const changeRequestRejectRef = useRef(null);
  const listAllUserRef = useRef(null);

  const [tabSource] = useState([
    {
      id: "tab-extend-sub",
      title: "Yêu cầu thay đổi gói",
      children: (
        <ChangeSub
          ref={changeRequestRef}
          regRequestRejectRef={changeRequestRejectRef}
          listAllUserRef={listAllUserRef}
        />
      ),
    },
    {
      id: "tab-canceled",
      title: "Yêu cầu bị từ chối",
      children: (
        <RegRequestReject
          ref={changeRequestRejectRef}
          regRequestRef={changeRequestRef}
          listAllUserRef={listAllUserRef}
        />
      ),
    },
    {
      id: "tab-all",
      title: "Tất cả",
      children: (
        <ListAllUser
          ref={listAllUserRef}
          regRequestRef={changeRequestRef}
          regRequestRejectRef={changeRequestRejectRef}
        />
      ),
    },
  ]);

  return (
    <>
      <div className="reg-register">Yêu cầu thay đổi gói</div>
      <div className={"reg-sub-root"}>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"top"}
          items={tabSource.map((item) => {
            return {
              key: `${item.id}`,
              label: `${item.title}`,
              children: item.children,
            };
          })}
        />
      </div>
    </>
  );
};

export default ChangeTab;
