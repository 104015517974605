const Star = () => {
  return (
    <svg
      width="86"
      height="14"
      viewBox="0 0 86 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5634 5.27737C14.4601 4.96686 14.1939 4.74046 13.8734 4.69096L10.1433 4.11543L8.46787 0.511521C8.32499 0.204333 8.01967 0.00878906 7.68423 0.00878906C7.34857 0.00878906 7.04347 0.204333 6.90059 0.511521L5.22493 4.11565L1.49487 4.69119C1.17438 4.74068 0.908182 4.96686 0.805088 5.2776C0.701994 5.58834 0.779809 5.93059 1.00644 6.16542L3.7304 8.98583L3.08524 12.9759C3.03204 13.3062 3.17075 13.6383 3.44134 13.8314C3.71171 14.0245 4.0687 14.044 4.35951 13.882L7.68445 12.0253L11.0094 13.882C11.1406 13.9552 11.285 13.9912 11.4288 13.9912C11.6038 13.9912 11.7787 13.9375 11.9276 13.8314C12.1982 13.6385 12.3366 13.3064 12.2834 12.9759L11.6383 8.98583L14.3625 6.16542C14.5887 5.93059 14.6665 5.58834 14.5634 5.27737Z"
        fill="#2DC56A"
      />
      <path
        d="M32.4113 5.27737C32.308 4.96686 32.0418 4.74046 31.7213 4.69096L27.9913 4.11543L26.3158 0.511521C26.173 0.204333 25.8676 0.00878906 25.5322 0.00878906C25.1965 0.00878906 24.8914 0.204333 24.7485 0.511521L23.0729 4.11565L19.3428 4.69119C19.0223 4.74068 18.7561 4.96686 18.653 5.2776C18.55 5.58834 18.6278 5.93059 18.8544 6.16542L21.5784 8.98583L20.9332 12.9759C20.88 13.3062 21.0187 13.6383 21.2893 13.8314C21.5597 14.0245 21.9167 14.044 22.2075 13.882L25.5324 12.0253L28.8573 13.882C28.9886 13.9552 29.133 13.9912 29.2768 13.9912C29.4517 13.9912 29.6267 13.9375 29.7755 13.8314C30.0461 13.6385 30.1846 13.3064 30.1314 12.9759L29.4862 8.98583L32.2104 6.16542C32.4366 5.93059 32.5144 5.58834 32.4113 5.27737Z"
        fill="#2DC56A"
      />
      <path
        d="M50.2593 5.27737C50.156 4.96686 49.8898 4.74046 49.5693 4.69096L45.8392 4.11543L44.1638 0.511521C44.0209 0.204333 43.7156 0.00878906 43.3802 0.00878906C43.0445 0.00878906 42.7394 0.204333 42.5965 0.511521L40.9209 4.11565L37.1908 4.69119C36.8703 4.74068 36.6041 4.96686 36.501 5.2776C36.3979 5.58834 36.4757 5.93059 36.7024 6.16542L39.4263 8.98583L38.7812 12.9759C38.728 13.3062 38.8667 13.6383 39.1373 13.8314C39.4076 14.0245 39.7646 14.044 40.0554 13.882L43.3804 12.0253L46.7053 13.882C46.8365 13.9552 46.981 13.9912 47.1247 13.9912C47.2997 13.9912 47.4747 13.9375 47.6235 13.8314C47.8941 13.6385 48.0326 13.3064 47.9794 12.9759L47.3342 8.98583L50.0584 6.16542C50.2846 5.93059 50.3624 5.58834 50.2593 5.27737Z"
        fill="#2DC56A"
      />
      <path
        d="M68.1073 5.27737C68.0039 4.96686 67.7377 4.74046 67.4173 4.69096L63.6872 4.11543L62.0118 0.511521C61.8689 0.204333 61.5636 0.00878906 61.2281 0.00878906C60.8925 0.00878906 60.5874 0.204333 60.4445 0.511521L58.7688 4.11565L55.0388 4.69119C54.7183 4.74068 54.4521 4.96686 54.349 5.2776C54.2459 5.58834 54.3237 5.93059 54.5503 6.16542L57.2743 8.98583L56.6291 12.9759C56.5759 13.3062 56.7146 13.6383 56.9852 13.8314C57.2556 14.0245 57.6126 14.044 57.9034 13.882L61.2283 12.0253L64.5533 13.882C64.6845 13.9552 64.8289 13.9912 64.9727 13.9912C65.1477 13.9912 65.3226 13.9375 65.4714 13.8314C65.742 13.6385 65.8805 13.3064 65.8273 12.9759L65.1822 8.98583L67.9063 6.16542C68.1325 5.93059 68.2104 5.58834 68.1073 5.27737Z"
        fill="#2DC56A"
      />
      <path
        d="M81.1945 4.56879L81.0818 4.32621L79.4064 0.722389C79.4063 0.72236 79.4063 0.722332 79.4063 0.722304C79.3446 0.589745 79.215 0.508789 79.0761 0.508789C78.937 0.508789 78.8075 0.589684 78.7458 0.722316C78.7458 0.72234 78.7458 0.722365 78.7458 0.722389L77.0702 4.32645L76.9574 4.56901L76.693 4.60981L72.963 5.18533C72.963 5.18533 72.963 5.18534 72.963 5.18534C72.8299 5.20591 72.7162 5.30041 72.6715 5.43504C72.6269 5.5696 72.6608 5.7173 72.7579 5.81807L81.1945 4.56879ZM81.1945 4.56879L81.4589 4.60958L85.1889 5.18511C85.1889 5.18511 85.1889 5.18511 85.189 5.18512C85.3218 5.20566 85.4359 5.30029 85.4808 5.43522C85.5253 5.57003 85.4912 5.71769 85.3944 5.81836C85.3943 5.81843 85.3943 5.81849 85.3942 5.81856L82.6705 8.63846L82.4966 8.81851L82.5365 9.06563L83.1816 13.0554C83.2048 13.1993 83.144 13.3423 83.0292 13.4242L83.0291 13.4242C82.9666 13.4688 82.8935 13.4912 82.8206 13.4912C82.7604 13.4912 82.7001 13.4762 82.6449 13.4454L79.3201 11.5888L79.0763 11.4526L78.8325 11.5888L75.508 13.4452C75.508 13.4452 75.5079 13.4452 75.5079 13.4452C75.3852 13.5135 75.2362 13.5048 75.1238 13.4245L75.1236 13.4244C75.0083 13.3421 74.9477 13.1989 74.9707 13.0556C74.9707 13.0556 74.9707 13.0555 74.9707 13.0554L75.6158 9.06563L75.6558 8.81853L75.4819 8.63847L72.7581 5.81821L81.1945 4.56879Z"
        stroke="#2DC56A"
      />
      <mask
        id="mask0_1014_5696"
        maskUnits="userSpaceOnUse"
        x="72"
        y="0"
        width="14"
        height="14"
      >
        <path
          d="M85.9552 5.27737C85.8519 4.96686 85.5857 4.74046 85.2652 4.69096L81.5352 4.11543L79.8597 0.511521C79.7168 0.204333 79.4115 0.00878906 79.0761 0.00878906C78.7404 0.00878906 78.4353 0.204333 78.2924 0.511521L76.6168 4.11565L72.8867 4.69119C72.5662 4.74068 72.3 4.96686 72.1969 5.2776C72.0938 5.58834 72.1717 5.93059 72.3983 6.16542L75.1222 8.98583L74.4771 12.9759C74.4239 13.3062 74.5626 13.6383 74.8332 13.8314C75.1036 14.0245 75.4605 14.044 75.7514 13.882L79.0763 12.0253L82.4012 13.882C82.5325 13.9552 82.6769 13.9912 82.8206 13.9912C82.9956 13.9912 83.1706 13.9375 83.3194 13.8314C83.59 13.6385 83.7285 13.3064 83.6753 12.9759L83.0301 8.98583L85.7543 6.16542C85.9805 5.93059 86.0583 5.58834 85.9552 5.27737Z"
          fill="#2DC56A"
        />
      </mask>
      <g mask="url(#mask0_1014_5696)">
        <rect
          x="72.1521"
          y="0.00878906"
          width="7.10303"
          height="14.1573"
          fill="#2DC56A"
        />
      </g>
    </svg>
  );
};

export default Star;
