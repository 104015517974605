import {Select, Spin} from 'antd';
import {debounce} from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {changePhoneNumberPrefix, checkIsPhoneNumber} from "../../utils/helper";


const {Option} = Select;


export const SelectCustomOption = ({
                                       pageSize = 10,
                                       onChange,
                                       disabled,
                                       form,
                                       fetchOptions,
                                       optionsDefault,
                                       defaultSearchText = "",

                                       debounceTimeout = 600, s
                                   }) => {

    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [params, setParams] = useState();
    const [total, setTotal] = useState();


    const loadOptions: any = useCallback(
        async (value) => {

            setLoading(true);
            let keyword = "";
            setOptions([]);
            let paramsNew = {
                searchText: value,
                pageIndex: 1,
                pageSize: pageSize,
            }

            if (checkIsPhoneNumber(value)) {
                keyword = changePhoneNumberPrefix(value)
            } else {
                keyword = value;
            }
            fetchOptions(keyword, paramsNew, form).then((res) => {
                setOptions(res.options);
                setTotal(res.total);
            }).finally(() => {
                setParams({...paramsNew});
                setTimeout(() => {
                    setLoading(false)
                }, 500)
            });
        },
        [fetchOptions, pageSize],
    );
    const debounceFetcher = useMemo(() => {
        return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout]);
    useEffect(() => {
        let defaultParams = {
            searchText: defaultSearchText,
            pageIndex: 1,
            pageSize: pageSize,
        };
        setOptions(optionsDefault);
        setParams(defaultParams)
    }, [optionsDefault])

    return (
        <Select

            onChange={(newValue) => onChange(newValue)}
            filterOption={false}
            showSearch
            dropdownRender={(menu) => (
                <>
                    {menu}
                    {loading ? <Spin size="small" style={{padding: '0 12px'}}/> : null}
                </>
            )}
            disabled={disabled}
            onSearch={debounceFetcher}
            options={options}
            onPopupScroll={async (event) => {
                const {target} = event
                if (options.length < total && target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    // call api update here
                    let newParams = {
                        searchText: params?.searchText,
                        pageIndex: params?.pageIndex + 1,
                        pageSize: params?.pageSize,
                    }
                    setLoading(true);
                    fetchOptions(params?.searchText, newParams, form).then((res) => {
                        setOptions(options?.concat(res.options));
                        setTotal(res.total);
                    }).finally(() => {
                        setParams({...newParams});
                        setTimeout(() => {
                            setLoading(false)
                        }, 500)
                    });
                }
            }}
        />

    );
};