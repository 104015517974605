import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";

const FooterCustomerModal = ({
  currentStep,
  handleCancel,
  handleAccUserSave,
  handleFinish,
  handlePrevious
}) => {
  return (
    <>
      {currentStep === 0 && (
        <>
          <ButtonCustomize
            title={"Hủy bỏ"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={handleCancel}
          />
          <ButtonCustomize
            title={"Tiếp tục"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={handleAccUserSave}
          />
        </>
      )}
      {currentStep === 1 && (
        <>
          <ButtonCustomize
            title={"Quay lại"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={handlePrevious}
          />
          <ButtonCustomize
            title={"Tiếp tục"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={handleAccUserSave}
          />
        </>
      )}
      {currentStep === 2 && (
        <>
          <ButtonCustomize
            title={"Quay lại"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={handlePrevious}
          />
          <ButtonCustomize
            title={"Hủy bỏ"}
            btnType={BtnTypeEnum.GREY}
            btnSize={44}
            onPressHandle={handleCancel}
          />
          <ButtonCustomize
            title={"Xác nhận, Thêm khách mới"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={handleFinish}
          />
        </>
      )}
    </>
  );
};

export default FooterCustomerModal;
