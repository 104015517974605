const ElipseLeft = () => {
return <svg width="185" height="446" viewBox="0 0 185 446" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-100.88 428.999C-208.604 371.677 -183.552 181.045 -124.042 69.2089C-64.5329 -42.627 -47.8645 3.77441 59.8599 61.0961C167.584 118.418 219.141 156.72 159.632 268.556C100.122 380.392 6.84463 486.32 -100.88 428.999Z" fill="url(#paint0_linear_1014_5717)"/>
    <defs>
        <linearGradient id="paint0_linear_1014_5717" x1="-20.6746" y1="-20.3384" x2="6.56248" y2="531.44" gradientUnits="userSpaceOnUse">
            <stop stop-color="#477AFF"/>
            <stop offset="1" stop-color="#22E16F"/>
        </linearGradient>
    </defs>
</svg>
}

export default ElipseLeft;