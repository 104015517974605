import { useCallback } from 'react';
import {useDispatch} from "react-redux";

import {

    listGroupObject, createGroupObject, updateGroupObject, deleteGroupObject
} from "../../../redux/slice/admin/adminApis";


export const useGroupObject = () => {
    const dispatch = useDispatch();

    const getListGroupObjectApi = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {
                const resData = await dispatch(listGroupObject({params:payload})).unwrap();
                resolve(resData);
            } catch (e) {
                resolve({ isSuccess: false });
            }
        });
    }, []);

    const createGroupObjectApi = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {

                const resData =    await dispatch(createGroupObject({params:payload})).unwrap();
                resolve(resData);
            } catch (e) {

                resolve({isSuccess: false});
            }
        });
    }, []);
    const updateGroupObjectApi = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {

                const resData =    await dispatch(updateGroupObject({params:payload})).unwrap();
                resolve(resData);
            } catch (e) {

                resolve({isSuccess: false});
            }
        });
    }, []);
    const deleteGroupObjectApi = useCallback((payload) => {
        return new Promise(async (resolve) => {
            try {

                const resData =    await dispatch(deleteGroupObject({params:payload})).unwrap();
                resolve(resData);
            } catch (e) {

                resolve({isSuccess: false});
            }
        });
    }, []);

    return {
        getListGroupObjectApi,
        createGroupObjectApi,
        updateGroupObjectApi,
        deleteGroupObjectApi,
    };
};
