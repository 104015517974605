const IconPackageWhiteClient = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1154_7061)">
        <path
          d="M15.5777 3.38197L17.5777 4.43152C19.7294 5.56066 20.8052 6.12523 21.4026 7.13974C22 8.15425 22 9.41667 22 11.9415V12.0585C22 14.5833 22 15.8458 21.4026 16.8603C20.8052 17.8748 19.7294 18.4393 17.5777 19.5685L15.5777 20.618C13.8221 21.5393 12.9443 22 12 22C11.0557 22 10.1779 21.5393 8.42229 20.618L6.42229 19.5685C4.27063 18.4393 3.19479 17.8748 2.5974 16.8603C2 15.8458 2 14.5833 2 12.0585V11.9415C2 9.41667 2 8.15425 2.5974 7.13974C3.19479 6.12523 4.27063 5.56066 6.42229 4.43152L8.42229 3.38197C10.1779 2.46066 11.0557 2 12 2C12.9443 2 13.8221 2.46066 15.5777 3.38197Z"
          stroke="white"
          stroke-width="1.4"
          stroke-linecap="round"
        />
        <path
          d="M14.3358 6.3744L15.6414 7.05952C17.0459 7.7966 17.7482 8.16513 18.1382 8.82738C18.5281 9.48963 18.5281 10.3137 18.5281 11.9618V12.0382C18.5281 13.6863 18.5281 14.5105 18.1382 15.1727C17.7482 15.8349 17.0459 16.2034 15.6414 16.9406L14.3358 17.6256C13.1898 18.227 12.6168 18.5278 12.0004 18.5278C11.384 18.5278 10.811 18.227 9.66496 17.6256L8.35941 16.9406C6.95486 16.2034 6.25258 15.8349 5.86262 15.1727C5.47266 14.5105 5.47266 13.6863 5.47266 12.0382V11.9618C5.47266 10.3137 5.47266 9.48963 5.86262 8.82738C6.25258 8.16513 6.95486 7.7966 8.35941 7.05952L9.66496 6.3744C10.811 5.773 11.384 5.47229 12.0004 5.47229C12.6168 5.47229 13.1898 5.773 14.3358 6.3744Z"
          fill="#2DC56A"
        />
        <path
          d="M21 7.5L12 12M12 12L3 7.5M12 12V21.5"
          stroke="white"
          stroke-width="1.4"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1154_7061">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPackageWhiteClient;
