import {del, post, put} from "../../redux/api/axios";
import {
    API_CREATE_SERVICE,
    API_DEL_SERVICE, API_LIST_PERMISSION_ITEM_SERVICE,
    API_LIST_PERMISSION_OF_SERVICE,
    API_LIST_SERVICE, API_UPDATE_PERMISSION_ITEM_SERVICE,
    API_UPDATE_SERVICE
} from "../../constants/apiUrls";
import {AlertType, openNotificationWithIcon} from "../../utils/AlertMessage";
import {notification} from "antd";

function FeatureUseCase() {


    const listAllPermission = async (setLoading) => {
        try {
            let response = await post(API_LIST_PERMISSION_OF_SERVICE);
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            if (error.response?.status !== 406) {
                openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
            }   return []
        } finally {
            setLoading(false)
        }
    }
    const updatePermissionsOfService = async (data) => {
        try {
            let response = await post(API_UPDATE_PERMISSION_ITEM_SERVICE, data);
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            if (error.response?.status !== 406) {
                openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
            }   throw error;
        }
    }
    const listPermissionOfService = async (searchParams) => {
        try {
            let response = await post(API_LIST_PERMISSION_ITEM_SERVICE, searchParams);
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            if (error.response?.status !== 406) {
                openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
            }   return {}
        }
    }
    return {
        listPermissionOfService,
        updatePermissionsOfService,
        listAllPermission
    }
}

export function FeatureObserver(inject) {
    const {
        setLoading,
        params,
        setParams,
    } = inject
    let useCase = FeatureUseCase();

    const getAllPermissionOfServices = async () => {
        return await useCase.listAllPermission(setLoading)
    };

    const getPermissionItemsServices = async (searchParams) => {
        return await useCase.listPermissionOfService(searchParams)
    };
    const updatePermissionsOfService = (params) => {
        setLoading(true)
        return useCase.updatePermissionsOfService(params).then((res) => {
            openNotificationWithIcon(notification, AlertType.success, "Cập nhật thành công")
            setParams({...params})
        }).catch((error) => {

        }).finally(()=>{
            setLoading(false)
        })
    };

    return {
        getAllPermissionOfServices,
        getPermissionItemsServices,
        updatePermissionsOfService,
    }
}