import {Button, Input, Form, Select, DatePicker, Row, ConfigProvider} from "antd";
import {AppstoreAddOutlined, ReloadOutlined} from "@ant-design/icons";
import React, {useEffect, useMemo, useState} from "react";
import viVN from 'antd/lib/locale/vi_VN';
import {debounce} from "lodash";
import moment from "moment";
import {optionFilterSortDateType} from "../../constants/enum";
import {optionFilterSortDate} from "../../constants/apiConstants";
import {SELECT_CUSTOM_OPTION} from "../../constants/form";
import {SelectCustomOption} from "../form/SelectCustomOption";
import './filter.scss'

const {Option} = Select;
const {RangePicker} = DatePicker;
const {Search} = Input;
export default function FilterSearch(props) {
    const {
        addButton,
        refreshButton,
        InputSelect,

        ResetFilter,
        params,
        beforeFilter,
        useDefaultValue = false,
    } = props;
    const [dateSortFilter, setdateSortFilter] = useState()
    const [checked, setChecked] = useState("long")
    const [form] = Form.useForm();

    useEffect(() => {
        if (useDefaultValue) {
            let values: any = {};
            InputSelect?.map((input: any, index: any) => {
                values = {...{[input.sendingField]: input.defaultValue}};
            });
            form.setFieldsValue(values);
        }
    }, [InputSelect]);

    useEffect(() => {
        if (beforeFilter && params) {
            beforeFilter(form, params)
        }
    }, [params])


    const searchKeyword = (keyword: any, sendingField: any, onChange: any) => {
        onChange(keyword, sendingField)
    }
    const debounceSearch = useMemo(() => {
        return debounce(searchKeyword, 600);
    }, [searchKeyword, 600]);
    return (
        <div className={"filter-search"}>
            {/*<Row style={{marginBottom:"7px",*/}
            {/*    display: "flex",*/}
            {/*    gap: "10px",*/}
            {/*    justifyContent: "end",*/}
            {/*    marginTop:"7px"*/}
            {/*}}>*/}
            {/*    <div style={{*/}
            {/*        display: "flex",*/}
            {/*        gap: "10px",*/}
            {/*        justifyContent: "end"*/}
            {/*    }}>*/}
            {/*        /!*{addButton &&*!/*/}
            {/*        /!*    <Button*!/*/}
            {/*        /!*        onClick={addButton}*!/*/}
            {/*        /!*        type="primary" shape="round" icon={<AppstoreAddOutlined/>}>*!/*/}
            {/*        /!*        Thêm mới*!/*/}
            {/*        /!*    </Button>}*!/*/}

            {/*        {refreshButton &&*/}
            {/*            <Button*/}
            {/*                onClick={refreshButton}*/}
            {/*                type="default" shape="round" icon={<ReloadOutlined/>}>*/}
            {/*                Làm mới*/}
            {/*            </Button>}*/}
            {/*        {ResetFilter &&*/}
            {/*            <Button*/}
            {/*                onClick={() => {*/}
            {/*                    form.resetFields();*/}
            {/*                    ResetFilter()*/}
            {/*                }}*/}
            {/*                type="default" danger={true} shape="round">*/}
            {/*                Bỏ lọc*/}
            {/*            </Button>}*/}
            {/*    </div>*/}
            {/*</Row>*/}
            <Row>
                <Form form={form}>
                    <div style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center"
                        }}>
                            <Form.Item name={"submit"} style={{display: "none"}}>
                                <Input
                                    type={"submit"}
                                />
                            </Form.Item>
                            <ConfigProvider locale={viVN}>
                                <Row style={{gap: "15px"}}>
                                    {InputSelect?.map((input: any, index: any) => {
                                        let field: any;
                                        switch (input.type) {
                                            case "input":
                                                field = (
                                                    <div key={index}>

                                                        <Form.Item label={"Tìm kiếm"} name={input.sendingField}
                                                                   labelCol={{
                                                                       span: 24
                                                                   }}>
                                                            <Search
                                                                ref={input?.ref}
                                                                allowClear={true}
                                                                title={input.placeholder}
                                                                placeholder={input.placeholder}
                                                                onChange={(e: any) => {
                                                                    debounceSearch(e.target.value, input.sendingField, input.change)
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                );
                                                break;
                                            case "select":
                                                let optionSelections: any = [];
                                                input.data?.forEach((option: any, index: any) => {
                                                    optionSelections.push(
                                                        <Option key={index} className="w-full" value={option?.value}>
                                                            {option?.label}
                                                        </Option>
                                                    );
                                                });
                                                field = (
                                                    <div key={index}>

                                                        <Form.Item label={input.label}
                                                                   style={{
                                                                       width: "180px"
                                                                   }}
                                                                   labelCol={{
                                                                       span: 24
                                                                   }}
                                                                   name={input.sendingField}>
                                                            <Select
                                                                ref={input?.ref}
                                                                placeholder={input.placeholder}
                                                                className={`min-w-[200px]`}
                                                                allowClear={!input.noAllowClear}
                                                                defaultValue={input.defaultValue}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input: any, option: any) =>
                                                                    option.props.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                onChange={(e) => input.change(e, input.sendingField, form)}
                                                            >
                                                                {optionSelections}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                )
                                                break;
                                            case SELECT_CUSTOM_OPTION:

                                                field = (
                                                    <div key={index}>
                                                        <Form.Item label={input.label}
                                                                   style={{
                                                                       width: "150px"
                                                                   }}
                                                                   labelCol={{
                                                                       span: 24
                                                                   }}
                                                                   name={input.sendingField}>
                                                            <SelectCustomOption
                                                                form={form}
                                                                onChange={(newValue) => {
                                                                    if (input.change) {
                                                                        input.change(newValue, input.sendingField, form);
                                                                    }
                                                                }}
                                                                optionsDefault={input.optionsDefault}
                                                                fetchOptions={input.fetchOptions}
                                                                disabled={input.visible}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                )
                                                break;
                                                default: <></>;
                                            // case "datePicker":
                                            //     field = (
                                            //         <div key={index}>
                                            //
                                            //             <Form.Item
                                            //                 label={input.label}
                                            //                 labelCol={{
                                            //                     span: 24
                                            //                 }}
                                            //                 name={input.sendingField}>
                                            //                 <DatePicker
                                            //                     ref={input?.ref}
                                            //                     onChange={(date, dateString) =>
                                            //                         input.change(date, dateString, input.sendingField)
                                            //                     }
                                            //                     placeholder={input.placeholder}
                                            //                 />
                                            //             </Form.Item>
                                            //         </div>
                                            //     )
                                            //     break;
                                            // case "dateRangePicker":
                                            //     field = (
                                            //         <div key={index}>
                                            //             <Row className={"items-end"}
                                            //                  style={{alignItems: "center", justifyContent: "start"}}>
                                            //
                                            //
                                            //                 <Form.Item
                                            //                     label={input.label}
                                            //                     labelCol={{
                                            //                         span: 24
                                            //                     }}
                                            //                     name={input.sendingField}>
                                            //                     <RangePicker
                                            //                         style={{
                                            //                             width: "100%"
                                            //                         }}
                                            //                         defaultValue={checked == "sort" ? "" : (input.defaultValue && input.defaultValue || ["", ""])}
                                            //                         placeholder={input.placeholder}
                                            //                         allowClear
                                            //                         ref={input?.ref}
                                            //                         placement="topLeft"
                                            //                         onChange={(date, dateString) => {
                                            //                             setChecked("long")
                                            //                             input.change(date, dateString, input.sendingField);
                                            //                             form.setFieldValue(`${input.sendingField}-select`, optionFilterSortDateType.OPTIONVALUE)
                                            //                         }}
                                            //                     />
                                            //                 </Form.Item>
                                            //
                                            //
                                            //                 <Form.Item
                                            //                     label={" "}
                                            //                     labelCol={{
                                            //                         span: 24
                                            //                     }}
                                            //                     name={`${input.sendingField}-select`}>
                                            //                     <Select
                                            //                         className="min-w-[100px]"
                                            //                         placeholder="Lựa chọn"
                                            //                         value={checked === "long" ? optionFilterSortDateType.OPTIONVALUE : dateSortFilter}
                                            //                         allowClear={!input.noAllowClear}
                                            //                         options={optionFilterSortDate()}
                                            //                         defaultValue={input?.defaultValueOption ? input.defaultValueOption : optionFilterSortDateType.DAYNOW}
                                            //                         onChange={(e) => {
                                            //                             setdateSortFilter(e)
                                            //                             setChecked("sort")
                                            //                             if (e === optionFilterSortDateType.DAYNOW) {
                                            //                                 // Lấy thời điểm bắt đầu của ngày hiện tại và định dạng theo yêu cầu
                                            //                                 const startOfTodayFormatted = moment()
                                            //                                     .startOf("day")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //
                                            //                                 // Lấy thời điểm kết thúc của ngày hiện tại và định dạng theo yêu cầu
                                            //                                 const endOfTodayFormatted = moment()
                                            //                                     .endOf("day")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //                                 form.setFieldValue(input.sendingField, [moment(startOfTodayFormatted), moment(endOfTodayFormatted)])
                                            //                                 return input.change(
                                            //                                     [moment(startOfTodayFormatted), moment(endOfTodayFormatted)],
                                            //                                     [startOfTodayFormatted, endOfTodayFormatted],
                                            //                                     input.sendingField
                                            //                                 );
                                            //                             }
                                            //                             if (e === optionFilterSortDateType.DAYAGO) {
                                            //                                 // Lấy thời điểm bắt đầu của ngày hôm qua và định dạng theo yêu cầu
                                            //                                 const startOfYesterdayFormatted = moment()
                                            //                                     .subtract(1, "days")
                                            //                                     .startOf("day")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //
                                            //                                 // Lấy thời điểm kết thúc của ngày hôm qua và định dạng theo yêu cầu
                                            //                                 const endOfYesterdayFormatted = moment()
                                            //                                     .subtract(1, "days")
                                            //                                     .endOf("day")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //                                 form.setFieldValue(input.sendingField, [moment(startOfYesterdayFormatted), moment(endOfYesterdayFormatted)])
                                            //
                                            //                                 return input.change(
                                            //                                     [moment(startOfYesterdayFormatted), moment(endOfYesterdayFormatted)],
                                            //                                     [startOfYesterdayFormatted, endOfYesterdayFormatted],
                                            //                                     input.sendingField
                                            //                                 );
                                            //                             }
                                            //                             if (e === optionFilterSortDateType.WEEKNOW) {
                                            //                                 const startOfThisWeekFormatted = moment()
                                            //                                     .startOf("week")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //
                                            //                                 // Lấy thời điểm kết thúc của tuần hiện tại và định dạng theo yêu cầu
                                            //                                 const endOfThisWeekFormatted = moment()
                                            //                                     .endOf("week")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //                                 form.setFieldValue(input.sendingField, [moment(startOfThisWeekFormatted), moment(endOfThisWeekFormatted)])
                                            //                                 return input.change(
                                            //                                     [moment(startOfThisWeekFormatted), moment(endOfThisWeekFormatted)],
                                            //                                     [startOfThisWeekFormatted, endOfThisWeekFormatted],
                                            //                                     input.sendingField
                                            //                                 );
                                            //                             }
                                            //                             if (e === optionFilterSortDateType.WEEKAGO) {
                                            //                                 // Lấy thời điểm bắt đầu của ngày hôm qua và định dạng theo yêu cầu
                                            //                                 const startOfWeekAgoFormatted = moment()
                                            //                                     .subtract(1, "weeks")
                                            //                                     .startOf("week")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //
                                            //                                 // Lấy thời điểm kết thúc của ngày hôm qua và định dạng theo yêu cầu
                                            //                                 const endOfWeekAgoFormatted = moment()
                                            //                                     .subtract(1, "weeks")
                                            //                                     .endOf("week")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //                                 form.setFieldValue(input.sendingField, [moment(startOfWeekAgoFormatted), moment(endOfWeekAgoFormatted)])
                                            //
                                            //                                 return input.change(
                                            //                                     [moment(startOfWeekAgoFormatted), moment(endOfWeekAgoFormatted)],
                                            //                                     [startOfWeekAgoFormatted, endOfWeekAgoFormatted],
                                            //                                     input.sendingField
                                            //                                 );
                                            //                             }
                                            //                             if (e === optionFilterSortDateType.MONTHNOW) {
                                            //                                 const startOfThisMonthFormatted = moment()
                                            //                                     .startOf("month")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //
                                            //                                 // Lấy thời điểm kết thúc của tháng hiện tại và định dạng theo yêu cầu
                                            //                                 const endOfThisMonthFormatted = moment()
                                            //                                     .endOf("month")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //                                 form.setFieldValue(input.sendingField, [moment(startOfThisMonthFormatted), moment(endOfThisMonthFormatted)])
                                            //                                 return input.change(
                                            //                                     [moment(startOfThisMonthFormatted), moment(endOfThisMonthFormatted)],
                                            //                                     [startOfThisMonthFormatted, endOfThisMonthFormatted],
                                            //                                     input.sendingField
                                            //                                 );
                                            //                             }
                                            //                             if (e === optionFilterSortDateType.MONTHAGO) {
                                            //                                 const startOfLastMonthFormatted = moment()
                                            //                                     .subtract(1, "months")
                                            //                                     .startOf("month")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //
                                            //                                 // Lấy thời điểm kết thúc của tháng trước đó và định dạng theo yêu cầu
                                            //                                 const endOfLastMonthFormatted = moment()
                                            //                                     .subtract(1, "months")
                                            //                                     .endOf("month")
                                            //                                     .format("YYYY-MM-DD HH:mm:ss");
                                            //                                 form.setFieldValue(input.sendingField, [moment(startOfLastMonthFormatted), moment(endOfLastMonthFormatted)])
                                            //                                 return input.change(
                                            //                                     [moment(startOfLastMonthFormatted), moment(endOfLastMonthFormatted)],
                                            //                                     [startOfLastMonthFormatted, endOfLastMonthFormatted],
                                            //                                     input.sendingField
                                            //                                 );
                                            //                             }
                                            //                             form.setFieldValue(input.sendingField, ['', ''])
                                            //                             input.change(
                                            //                                 "",
                                            //                                 [undefined, undefined],
                                            //                                 input.sendingField
                                            //                             );
                                            //                         }}
                                            //                     />
                                            //                 </Form.Item>
                                            //
                                            //             </Row>
                                            //         </div>
                                            //     );
                                            //     break;
                                        }
                                        return field;
                                    })}
                                </Row>

                            </ConfigProvider>
                        </div>
                    </div>
                </Form>
            </Row>
        </div>
    )
}