import {
  STATUS_PROCESS_REQUEST,
  STATUS_REQUEST,
} from "../../../../../constants/enum";
import { useEffect, useState } from "react";

import TableComponent from "../../../components/table";
import "./RegService.scss";
import IconDow from "../../../../../components/svg/icon-dow";
import IconUp from "../../../../../components/svg/icon-up";
import { useRequest } from "../../../useRequest";
import { useDashboard } from "../../../../useDashboard";
const RegService = () => {
  const { getAllRequestBuyMore } = useRequest();
  const { getTotalBuyMoreService } = useDashboard();
  const [dataSourcePkg, setDataSourcePkg] = useState([]);
  const [totalPkgSub, setTotalPkgSub] = useState(0);
  console.log("totalPkgSub", totalPkgSub);
  useEffect(() => {
    getAllRequestBuyMore({
      status: STATUS_REQUEST.WAIT_REGIS_NEW,
      statusProcess: STATUS_PROCESS_REQUEST.NO_PROCESS,
    }).then((res) => {
      setDataSourcePkg(res);
    });

    getTotalBuyMoreService().then((res) => {
      setTotalPkgSub(res.data);
    });
  }, []);

  const dataSource = [
    {
      key: "1",
      content: "Hôm nay",
      countRegister: <>{dataSourcePkg?.todayCount || 0}</>,
      countRegSub: <>{totalPkgSub.todaySub || 0}</>,
      ratio: <>{(dataSourcePkg?.ratioTodayYesterday|| 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioTodayYesterdaySub || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.todayCount >= dataSourcePkg.yesterdayCount ? <IconUp /> : <IconDow />,
      iconSub: totalPkgSub?.todaySub >= totalPkgSub?.yesterdaySub ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.todayCount >= dataSourcePkg.yesterdayCount ? "column-content-up" : "column-content-dow",
      ratioClassSub: totalPkgSub?.todaySub >= totalPkgSub?.yesterdaySub ? "column-content-up" : "column-content-dow",
      ratioServiceCountSub: (dataSourcePkg.todayCount > 0 ? totalPkgSub?.todaySub / dataSourcePkg.todayCount * 100: 0).toFixed(0) 
    },
    {
      key: "2",
      content: "Hôm qua",
      countRegister: <>{dataSourcePkg.yesterdayCount || 0}</>,
      countRegSub: <>{totalPkgSub.yesterdaySub || 0}</>,
      ratio: <>{(dataSourcePkg.ratioYesterdayTwoDaysAgo || 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioYesterdayTwoDaysAgoSub || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.yesterdayCount >= dataSourcePkg.twoDaysAgoCount ? <IconUp /> : <IconDow />,
      iconSub: totalPkgSub?.yesterdaySub >= totalPkgSub?.twoDaysAgoSub ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.yesterdayCount >= dataSourcePkg.twoDaysAgoCount ? "column-content-up" : "column-content-dow",
      ratioClassSub: totalPkgSub?.yesterdaySub >= totalPkgSub?.twoDaysAgoSub ? "column-content-up" : "column-content-dow",
      ratioServiceCountSub: (dataSourcePkg.yesterdayCount > 0 ? totalPkgSub?.yesterdaySub / dataSourcePkg.yesterdayCount * 100: 0).toFixed(0) 
    },
    {
      key: "3",
      content: "Tháng này",
      countRegister: <>{dataSourcePkg.thisMonthCount || 0}</>,
      countRegSub:<>{totalPkgSub.thisMonthSub || 0}</>,
      ratio: <>{(dataSourcePkg.ratioThisMonthLastMonth || 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioThisLastMonthSub || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.thisMonthCount >= dataSourcePkg.lastMonthCount ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.thisMonthCount >= dataSourcePkg.lastMonthCount ? "column-content-up" : "column-content-dow",
      iconSub: totalPkgSub?.thisMonthSub >= totalPkgSub?.lastMonthSub ? <IconUp /> : <IconDow />,
      ratioClassSub: totalPkgSub?.thisMonthSub >= totalPkgSub?.lastMonthSub ? "column-content-up" : "column-content-dow",
      ratioServiceCountSub: (dataSourcePkg.thisMonthCount > 0 ? totalPkgSub?.thisMonthSub / dataSourcePkg.thisMonthCount * 100: 0).toFixed(0) 
    },
    {
      key: "4",
      content: "Tháng trước",
      countRegister: <>{dataSourcePkg.lastMonthCount || 0}</>,
      countRegSub: <>{totalPkgSub.lastMonthSub || 0}</>,
      ratio: <>{(dataSourcePkg.ratioLastMonthTwoMonthsAgo || 0).toFixed(0)} %</>,
      ratioSub: <>{(totalPkgSub.ratioLastTwoMonthsSub || 0).toFixed(0)} %</>,
      icon: dataSourcePkg.lastMonthCount >= dataSourcePkg.twoMonthsAgoCount ? <IconUp /> : <IconDow />,
      iconSub: totalPkgSub?.lastMonthSub >= totalPkgSub?.twoMonthsAgoSub ? <IconUp /> : <IconDow />,
      ratioClass: dataSourcePkg.lastMonthCount >= dataSourcePkg.twoMonthsAgoCount ? "column-content-up" : "column-content-dow",
      ratioClassSub: totalPkgSub?.lastMonthSub >= totalPkgSub?.twoMonthsAgoSub ? "column-content-up" : "column-content-dow",
      ratioServiceCountSub: (dataSourcePkg.lastMonthCount  > 0 ? totalPkgSub.lastMonthSub / dataSourcePkg.lastMonthCount * 100 : 0).toFixed(0) 
    }
  ]


  return (
    <div>
      <TableComponent
        dataSource={dataSource}
        bordered={true}
        // // rowSelection={true}
        // totalCount={total}
        // page={page}
        // setPage={setPage}
        // pageSize={size}
        // setSize={setSize}
        pagination={false}
        columns={[
          {
            title: "Thời gian",
            width: 150,
            dataIndex: "fullName",
            key: "fullName",
            render: (val, record) => {
              return <span>{record.content}</span>;
            },
          },
          {
            title: "Lượt đăng ký dịch vụ",
            width: 256,
            dataIndex: "userStatus",
            key: "userStatus",
            render: (val, record) => {
              return (
                <div className="column-pkg">
                  <span>{record.countRegister}</span>
                  <div className={record.ratioClass}>
                    {record.icon}
                    <span>{record.ratio}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Đăng ký dịch vụ thành công",
            width: 256,
            dataIndex: "serviceName",
            key: "serviceName",
            render: (val, record) => {
              return (
                <div className="column-pkg">
                  <span>{record.countRegSub}</span>
                  <div className={record.ratioClassSub}>
                    {record.iconSub}
                    <span>{record.ratioSub}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Tỉ lệ",
            width: 216,
            dataIndex: "amount",
            key: "amount",
            render: (val, record) => {
              const ratioServiceCountSub = record.ratioServiceCountSub
              const progressWidth = Math.min(ratioServiceCountSub, 100) + '%';
              
              return (
                <div className="column-content-ratio">
                  <div className="column-content-bar">
                    <div className="bar" style={{width: progressWidth, background: `linear-gradient(to right, #477aff, #22e16f)`}}></div>
                  </div>
                  <span>{(record.ratioServiceCountSub) + "%"}</span>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};
export default RegService;
