const IconDow = () => {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 0.833415L6.5 5.83341L9.83333 2.50008L16.5 9.16675M16.5 9.16675L16.5 5.00008M16.5 9.16675L12.3333 9.16675"
        stroke="#FF6340"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconDow;
