const IconCircleDown = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1751_15305)">
        <path
          d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM20.7075 17.7075L16.7075 21.7075C16.6146 21.8005 16.5043 21.8742 16.3829 21.9246C16.2615 21.9749 16.1314 22.0008 16 22.0008C15.8686 22.0008 15.7385 21.9749 15.6171 21.9246C15.4957 21.8742 15.3854 21.8005 15.2925 21.7075L11.2925 17.7075C11.1049 17.5199 10.9994 17.2654 10.9994 17C10.9994 16.7346 11.1049 16.4801 11.2925 16.2925C11.4801 16.1049 11.7346 15.9994 12 15.9994C12.2654 15.9994 12.5199 16.1049 12.7075 16.2925L15 18.5863V11C15 10.7348 15.1054 10.4804 15.2929 10.2929C15.4804 10.1054 15.7348 10 16 10C16.2652 10 16.5196 10.1054 16.7071 10.2929C16.8946 10.4804 17 10.7348 17 11V18.5863L19.2925 16.2925C19.4801 16.1049 19.7346 15.9994 20 15.9994C20.2654 15.9994 20.5199 16.1049 20.7075 16.2925C20.8951 16.4801 21.0006 16.7346 21.0006 17C21.0006 17.2654 20.8951 17.5199 20.7075 17.7075Z"
          fill="#5464F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1751_15305">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCircleDown;
