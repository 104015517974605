import IconClose from "../../svg/icon-close";
import { Modal as AntModal } from "antd";
import "./index.scss";

function Modal({
  footerNode,
  className,
  onClose,
  showModal,
  item,
  children,
  ...rest
}) {
  return (
      <AntModal
          closable={false}
          title={
            <div className={`${className}`}>
              <div onClick={onClose} className="title-modal">
                {item}
                <div className="icon-close">
                  <IconClose/>
                </div>
              </div>
            </div>
          }
          footer={<>{footerNode && (<div className={`footer-content`}>
            {footerNode()}</div>)}</>
          }
          open={showModal}
          centered
          {...rest}
      >
        <div className={`body-modal-card ${className}`}>{children}</div>
      </AntModal>
  );
}

export default Modal;
