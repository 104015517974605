import { withRouter } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Form, Space, Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { TYPE_FORM_CREATE_USER } from "../../../constants/ModalType";
import {
  DEFAULT_PAGE,
  MAX_RESULTS,
  DATE_FORMAT_CREATE,
} from "../../../constants/apiConstants";

import "./user.scss";
import {
  getUserStatusName,
  STATUS_PACKAGE_FILTER,
  statusEnum,
} from "../../../constants/enum";
import TableComponent from "../components/table";

import { ACTION_FORM_ADD } from "../../../constants/form";
import FilterSearch from "../../../components/layout/FilterSearch";
import InputSelect from "../../../observer/ueser/InputSelect";
import { UserObserver } from "../../../observer/ueser/UserObserver";
import ButtonCustomize from "../../../components/button";
import { BtnTypeEnum } from "../../../components/button/const";
import { useRequest } from "../useRequest";
import Modal from "../../../components/modal/modal-card";
import PackageUserInfo from "./Modal/PackageInfo";
import LockAccount from "./Modal/LockAccount";
import AddNewCustomer from "./Modal/AddNewCustomer";
import FooterCustomerModal from "./Modal/FooterCustomerModal";
import AddSuccess from "./body-modal/add-success";
import ModalSuccessAccount from "./body-modal/modal-success";
import { ProfileInfo } from "../../client/profile";
import ServiceUserInfo from "./Modal/ServiceInfo";
import { formatInputMoney } from "../../../utils/helper";
import ModalDeleteAccount from "./body-modal/delete-account";
import IconWarning from "../../../components/svg/icon-warning";
import { useUser } from "../../client/useUser";

const typeUserModal = {
  ADD_NEW_CUS: 0,
  LOCK_ACCOUNT: 1,
  PACKAGE_INFO: 2,
  SERVICE_INFO: 3,
  ADD_SUCCESS: 4,
  LOCK_SUCCESS: 5,
  INFO_USER: 6,
  DELETE_USER: 7,
  WARNING_DELETE_USER: 8,
};
function UserPage(props) {
  const { notification } = props;
  const {
    createTenant,
    getListUsersHandle,
    pushUserRequestCreate,
    checkExistedDomain,
    lockUserAccount,
    getRequestUserInfoAccount,
    updatePkgSettingUser,
    deleteUserRequest,
  } = useRequest();

  const { getUserName } = useUser();

  const [modalForm, setModalForm] = useState({
    isOpen: false,
    title: "",
    type: -1,
    userId: null,
    packageId: null,
  });

  const [form] = Form.useForm();
  const [customerSelected, setCustomerSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [typeModal, setTypeModal] = useState(TYPE_FORM_CREATE_USER);

  const [params, setParams] = useState({ statusAccount: statusEnum.ACTIVE });
  const [loading, setLoading] = useState(false);
  const [okText, setOkText] = useState("");
  const [size, setSize] = useState(MAX_RESULTS);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState({});
  const [currenEdit, setCurrenEdit] = useState({});
  const [currenServiceUser, setCurrenServiceUser] = useState({});
  const [mode, setMode] = useState(ACTION_FORM_ADD);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [inputSelect, setInputSelect] = useState([]);

  // const [statusUsePackage, setStatusUsePackage] = useState([]);
  const { isStatus } = getUserStatusName(modalForm.status);
  const observer = UserObserver({
    setMode,
    mode,
    setOpenModal,
    currenServiceUser,
    setCurrenServiceUser,
    page,
    setPage,
    setSize,
    size,
    params,
    setParams,
    setTypeModal,
    typeModal,
    setOkText,
    selectedRowKeys,
    setLoading,
    setSelectedRowKeys,
    currenEdit,
    setCurrenEdit,

    notification,
  });

  const [savedData, setSavedData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const userFormRef = useRef(null);
  const enterpriseFormRef = useRef(null);
  const [formType, setFormType] = useState("business");
  console.log("savedData", savedData);

  const handleSave = (data) => {
    setSavedData((prev) => ({ ...prev, ...data }));
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleAccUserSave = () => {
    if (currentStep === 0) {
      userFormRef.current.handleSave();
    } else if (currentStep === 1) {
      if (formType === "personal") {
        handleSave({ ...savedData, taxCode: "", companyName: "" });
      }
      if (formType === "business") {
        handleSave({ ...savedData, cardNumber: "" });
      }
      enterpriseFormRef.current.handleSave();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFinish = async () => {
    const dataToSubmit = {
      ...savedData,
      business: savedData.business || "",
      content: savedData.content || "",
    };
    const checkDomainResult = await checkExistedDomain({
      tenant: savedData.tenantId,
    });

    if (checkDomainResult.isSuccess) {
      const usernameExists = await getUserName({
        userName: savedData.username,
      });

      if (usernameExists) {
        return null;
      }

      await pushUserRequestCreate(dataToSubmit);
      onShowModal(typeUserModal.ADD_SUCCESS, null)();
      reloadData();
    }
  };

  const onRadioChange = (e) => {
    setFormType(e.target.value);
    form.resetFields();
  };

  const lockUser = () => {
    setLoading(true);
    lockUserAccount({
      userId: modalForm.userId,
      isLocked: isStatus,
    }).then(() => {
      onShowModal(typeUserModal.LOCK_SUCCESS, modalForm)();
      reloadData();
    });
  };

  const showInfoUser = (record) => {
    getRequestUserInfoAccount({
      userId: record.id,
    }).then((res) => {
      setCustomerSelected(res);
      onShowModal(typeUserModal.INFO_USER, record)();
    });
  };

  const deleteUser = () => {
    setLoading(true);
    deleteUserRequest({
      userId: modalForm.userId,
    }).then(() => {
      handleCancel();
      reloadData();
    });
  };

  useEffect(() => {
    setLoading(true);
    // observer
    //   .getDataSource({
    //     ...params,
    //     pageNumber: page,
    //     pageSize: size,
    //   })
    //   .then((res) => {
    //     setLoading(false);
    //     setDataSource(res);
    //   });
    reloadData();
  }, [params, page, size]);

  // const statusUsePackageList = () => {
  //   getUserRequestListService({
  //     pageNumber: 1,
  //     pageSize: 20,
  //     searchText: "",
  //     serviceId: "",
  //     statusPackage: -1,
  //     statusAccount: -1,
  //   }).then((res) => {
  //     setStatusUsePackage(res);
  //   });
  // };

  useEffect(() => {
    InputSelect(propsInputSelect).then((res) => {
      setInputSelect(res);
    });
  }, [params]);

  const createPackage = observer.create;
  const refreshPackage = observer.refresh;
  const resetFilter = observer.resetFilter;
  const propsInputSelect = observer.getPropsInputSelect(params);
  const reloadData = () => {
    setLoading(true);
    getListUsersHandle({
      ...params,
      pageNumber: page,
      pageSize: size,
    }).then((res) => {
      setLoading(false);
      let newDataRes = [];
      let pageSize = size;
      res?.data?.forEach((element, index) => {
        newDataRes.push({ ...element, key: (page - 1) * pageSize + index + 1 });
      });
      setTotal(res.total);
      setDataSource(newDataRes);
    });
  };

  const onActivePkg = (item) => (onDoneCb) => {
    createTenant({ requestId: item.requestId }).then(function () {
      onDoneCb && onDoneCb();
      reloadData();
    });
  };

  const onUpdateSettingPkg = (rowItem) => () => {
    const payload = {
      userId: rowItem.id,
      serviceId: rowItem.packageId,
      activeMembersActual: rowItem.maxActiveMembers,
      createdMembersActual: rowItem.maxCreatedMembers,
    };
    updatePkgSettingUser(payload);
  };

  const onShowModal = (type, rowItem) => (e) => {
    const { isStatus } = getUserStatusName(rowItem?.status);
    if (e) {
      if (e.domEvent) {
        e.domEvent.stopPropagation();
      } else {
        if (e.stopPropagation) {
          e.stopPropagation();
        }
      }
    }
    let titleName = "";
    let packageId = null;
    if (type === typeUserModal.PACKAGE_INFO) {
      titleName = "Thông tin gói";
      packageId = rowItem?.packageId;
    } else if (type === typeUserModal.SERVICE_INFO) {
      if (rowItem.totalUsingService === 0) {
        return;
      }
      titleName = "Thông tin dịch vụ";
    } else if (type === typeUserModal.ADD_NEW_CUS) {
      titleName = "Thêm khách mới";
    } else if (type === typeUserModal.ADD_SUCCESS) {
      titleName = "Thêm khách mới";
    } else if (type === typeUserModal.LOCK_SUCCESS) {
      titleName = "Thông báo";
    } else if (type === typeUserModal.INFO_USER) {
      titleName = "Thông tin tài khoản";
    } else if (type === typeUserModal.DELETE_USER) {
      titleName = "Xóa tài khoản";
    } else if (type === typeUserModal.WARNING_DELETE_USER) {
      titleName = "Xóa tài khoản";
    } else {
      isStatus ? (titleName = "Khoá tài khoản") : (titleName = "Mở tài khoản");
    }

    setModalForm({
      ...rowItem,
      isOpen: true,
      title: titleName,
      type: type,
      userId: rowItem?.id,
      packageId,
    });
  };

  const handleCancel = () => {
    setModalForm((prev) => ({
      ...prev,
      isOpen: false,
      type: -1,
    }));
    setCurrentStep(0);
    setSavedData({});
  };

  const renderBodyModal = () => {
    if (modalForm.type === -1) {
      return null;
    }

    if (modalForm.type === typeUserModal.PACKAGE_INFO) {
      return <PackageUserInfo userId={modalForm.userId} />;
    }

    if (modalForm.type === typeUserModal.SERVICE_INFO) {
      return <ServiceUserInfo userId={modalForm.userId} />;
    }

    if (modalForm.type === typeUserModal.LOCK_ACCOUNT) {
      return <LockAccount userId={modalForm.userId} isLocked={isStatus} />;
    }

    if (modalForm.type === typeUserModal.ADD_NEW_CUS) {
      return (
        <AddNewCustomer
          onSave={handleSave}
          formRef={userFormRef}
          enterpriseFormRef={enterpriseFormRef}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          savedData={savedData}
          resetData={setSavedData}
          formType={formType}
          onRadioChange={onRadioChange}
        />
      );
    }

    if (modalForm.type === typeUserModal.ADD_SUCCESS) {
      return <AddSuccess data={savedData} />;
    }

    if (modalForm.type === typeUserModal.LOCK_SUCCESS) {
      return <ModalSuccessAccount isLocked={isStatus} />;
    }

    if (modalForm.type === typeUserModal.INFO_USER) {
      const avatarSize = 150;
      return (
        <ProfileInfo
          avatarSize={avatarSize}
          user={customerSelected}
          userId={modalForm.id}
          reloadData={reloadData}
        />
      );
    }

    if (modalForm.type === typeUserModal.DELETE_USER) {
      return <ModalDeleteAccount userId={modalForm.userId} />;
    }

    if (modalForm.type === typeUserModal.WARNING_DELETE_USER) {
      return (
        <>
          <div className="modal-refuse">
            <ul>
              <IconWarning />
              <li>Bạn có chắc muốn xóa tài khoản này</li>
            </ul>
          </div>
        </>
      );
    }

    return null;
  };

  const renderFooter = () => {
    if (
      modalForm.type === typeUserModal.PACKAGE_INFO ||
      modalForm.type === typeUserModal.SERVICE_INFO
    )
      return null;

    const { isStatus } = getUserStatusName(modalForm.status);
    return (
      <>
        {modalForm.type === typeUserModal.ADD_NEW_CUS && (
          <>
            <FooterCustomerModal
              currentStep={currentStep}
              handleCancel={handleCancel}
              handleAccUserSave={handleAccUserSave}
              handlePrevious={handlePrevious}
              handleFinish={handleFinish}
            />
          </>
        )}

        {modalForm.type === typeUserModal.ADD_SUCCESS && (
          <>
            <ButtonCustomize
              title={"Sao chép thông tin"}
              btnType={BtnTypeEnum.GREY}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Hoàn thành"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              onPressHandle={handleCancel}
            />
          </>
        )}
        {modalForm.type === typeUserModal.LOCK_ACCOUNT && (
          <>
            <ButtonCustomize
              title={"Hủy bỏ"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            {isStatus ? (
              <ButtonCustomize
                title={"Khóa tài khoản"}
                btnType={BtnTypeEnum.GREEN}
                btnSize={44}
                onPressHandle={lockUser}
              />
            ) : (
              <ButtonCustomize
                title={"Mở tài khoản"}
                btnType={BtnTypeEnum.GREEN}
                btnSize={44}
                onPressHandle={lockUser}
              />
            )}
          </>
        )}

        {modalForm.type === typeUserModal.LOCK_SUCCESS && (
          <>
            <ButtonCustomize
              title={"Hoàn thành"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              onPressHandle={handleCancel}
            />
          </>
        )}

        {modalForm.type === typeUserModal.INFO_USER && (
          <>
            <ButtonCustomize
              title={"Hủy bỏ"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
          </>
        )}

        {modalForm.type === typeUserModal.DELETE_USER && (
          <>
            <ButtonCustomize
              title={"Hủy bỏ"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Xóa tài khoản"}
              btnType={BtnTypeEnum.RED}
              btnSize={44}
              onPressHandle={onShowModal(typeUserModal.WARNING_DELETE_USER)}
            />
          </>
        )}
        {modalForm.type === typeUserModal.WARNING_DELETE_USER && (
          <>
            <ButtonCustomize
              title={"Hủy bỏ"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Xóa tài khoản"}
              btnType={BtnTypeEnum.RED}
              btnSize={44}
              onPressHandle={deleteUser}
            />
          </>
        )}
      </>
    );
  };

  const modalProps = {};
  if (modalForm.type === typeUserModal.INFO_USER) {
    modalProps.width = "auto";
  }

  return (
    <div className={"table-user-management"}>
      <div className={"header-title"}>
        <span>Khách hàng</span>

        <ButtonCustomize
          title="Thêm khách mới"
          btnType={BtnTypeEnum.BLACK}
          btnSize={44}
          onPressHandle={onShowModal(typeUserModal.ADD_NEW_CUS, null)}
        />
      </div>
      <FilterSearch
        InputSelect={inputSelect}
        ResetFilter={resetFilter}
        addButton={createPackage}
        refreshButton={refreshPackage}
      />

      <TableComponent
        dataSource={dataSource || []}
        loading={loading}
        bordered={true}
        totalCount={total}
        page={page}
        pageSize={size}
        setSize={setSize}
        scroll={{ x: 1905, y: "calc(100vh - 220px)" }}
        setPage={setPage}
        columns={[
          {
            title: "STT",
            dataIndex: "key",
            width: 45,
            fixed: "left",
          },
          {
            title: "Khách hàng",
            className: "table-info-user",
            key: Math.random(),
            dataIndex: "fullName",
            width: 200,
            ellipsis: true,
            fixed: "left",
            render: (value, record) => {
              return (
                <div
                  className="table-user-column"
                  onClick={() => showInfoUser(record)}
                >
                  <span style={{ color: "#5464F4" }}>{value}</span>
                  <div className="table-box">
                    <span>{record.phoneNumber}</span>
                    <span>-</span>
                    <span>{record.email}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Ngày đăng ký tài khoản",
            width: 145,
            dataIndex: "createdAt",
            key: "createdAt",
            render: (val, record) => {
              const createdAtFormat = record.createdAt
                ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
                : "";
              return <span>{createdAtFormat}</span>;
            },
          },
          {
            title: "Loại hình KD&MST",
            key: Math.random(),
            width: 140,
            dataIndex: "id",
            ellipsis: true,
            render: (_, record) => {
              return (
                <div className="table-user-column">
                  <span>{record.businessType}</span>
                  <span>{record.taxCode}</span>
                </div>
              );
            },
          },
          {
            title: "Tài khoản SaaS",
            key: Math.random(),
            dataIndex: "username",
            width: 125,
            ellipsis: true,
            render: (value, record) => {
              const { statusName, clsName } = getUserStatusName(record.status);
              return (
                <div className="table-user-column">
                  <span>{value}</span>
                  <div className="table-box">
                    <span className={clsName}>{statusName}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Gói sử dụng",
            dataIndex: "packageName",
            key: Math.random(),
            width: 126,
            align: "left",
            ellipsis: true,
            render: (value, row) => {
              let className = "";
              let statusName = "";
              const isActive =
                row.packageId !== null && row.userPkgStatus === 1; //Đã kích hoạt
              const isWaitApprove =
                row.requestId != null && row.packageId === null; //Chờ kích hoạt
              const isExpired = row.userPkgStatus === 2; //Đã hết hạn
              const isExpireSoonPkg =
                row.isExpireSoon === true && row.userPkgStatus === 1; //Sắp hết hạn
              const isRejected =
                row.packageId === null &&
                row.requestRejectId !== null &&
                row.requestId === null; //Bị tự chối
              if (isActive) {
                statusName = "Đã kích hoạt";
                className = "package-status package-status-activated";
              }
              if (isWaitApprove) {
                statusName = "Chờ kích hoạt";
                className = "package-status package-status-no-active";
              }
              if (isExpired) {
                statusName = "Đã hết hạn";
                className = "package-status package-status-expired";
              }
              if (
                (params.statusPackage === STATUS_PACKAGE_FILTER.ALL ||
                  params.statusPackage === undefined ||
                  params.statusPackage === STATUS_PACKAGE_FILTER.EXPIRE_SOON) &&
                isExpireSoonPkg
              ) {
                statusName = "Sắp hết hạn";
                className = "package-status package-status-expire_soon";
              }
              if (isRejected) {
                statusName = "Bị từ chối";
                className = "package-status package-status-reject";
              }

              if (!statusName) return null;

              return (
                <div className="table-user-column">
                  <span>{value}</span>
                  <div className="table-box">
                    <span className={className}>{statusName}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Tên miền",
            dataIndex: "tenantId",
            key: Math.random(),
            width: 200,
            ellipsis: true,
            render: (_, record) => {
              if (record.e) {
                record.e.stopPropagation();
              }
              return (
                <div className="table-user-column">
                  <a
                    target="_blank"
                    href={`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                    rel="noopener noreferrer"
                  >
                    {`https://${record.tenantId}.${process.env.REACT_APP_DOMAIN_TENANT}`}
                  </a>
                  <div className="table-box">
                    {record.isActiveDomain === 0 ? (
                      <span className="user-status-locked">Chờ khởi tạo</span>
                    ) : (
                      <>
                        <span className="user-status-active">
                          Đang hoạt động
                        </span>
                      </>
                    )}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Các dịch vụ",
            dataIndex: "totalUsingService",
            key: Math.random(),
            width: 80,
            align: "center",
            ellipsis: true,
            render: (value, row) => {
              return (
                <div onClick={onShowModal(typeUserModal.SERVICE_INFO, row)}>
                  <span className="total-using-service">{value}</span>
                </div>
              );
            },
          },
          {
            title: "Tổng tiền đã mua",
            dataIndex: "totalUsingService",
            key: Math.random(),
            width: 140,
            align: "center",
            ellipsis: true,
            render: (_, record) => {
              return <div>{formatInputMoney(record.totalAmount)} đ</div>;
            },
          },
          {
            title: "Hành động",
            key: Math.random(),
            dataIndex: "action",
            fixed: "right",
            width: 105,
            render: (item: any, row: any) => {
              const { isStatus } = getUserStatusName(row.status);
              const isActive =
                row.packageId !== null && row.userPkgStatus === 1;
              const activeDomain = row.isActiveDomain === 0;
              const isWaitApprove =
                row.packageStatus === null ? row.requestId != null : false;
              if (isWaitApprove) {
                return (
                  <ButtonCustomize
                    title={"Kiểm duyệt"}
                    btnType={BtnTypeEnum.GREEN}
                    btnSize={32}
                    showLoading={true}
                    onPressHandle={onActivePkg(row)}
                  />
                );
              }

              const style = {
                width: "100%",
                // display: "flex",
                // justifyContent: "start",
                // alignItems: "center",
                gap: "10px",
              };
              const menu = (
                <Menu>
                  {
                    <>
                      {row.packageStatus !== null && (
                        <>
                          <Menu.Item
                            onClick={onShowModal(
                              typeUserModal.PACKAGE_INFO,
                              row
                            )}
                          >
                            <div className="user-menu-dropdown">
                              <span>Xem thông tin gói</span>
                            </div>
                          </Menu.Item>
                          {row.totalUsingService > 0 && (
                            <Menu.Item
                              onClick={onShowModal(
                                typeUserModal.SERVICE_INFO,
                                row
                              )}
                            >
                              <div className="user-menu-dropdown">
                                <span>Xem thông tin dịch vụ</span>
                              </div>
                            </Menu.Item>
                          )}

                          <Menu.Item onClick={onUpdateSettingPkg(row)}>
                            <div className="user-menu-dropdown">
                              <span>Cập nhật thiết lập gói</span>
                            </div>
                          </Menu.Item>
                        </>
                      )}
                      <Menu.Item>
                        <div
                          onClick={onShowModal(typeUserModal.LOCK_ACCOUNT, row)}
                          className="user-menu-dropdown"
                        >
                          <span>
                            {isStatus ? "Khóa tài khoản" : "Mở tài khoản"}
                          </span>
                        </div>
                      </Menu.Item>
                      {!isActive && activeDomain && (
                        <Menu.Item>
                          <div
                            onClick={onShowModal(
                              typeUserModal.WARNING_DELETE_USER,
                              row
                            )}
                            className="user-menu-dropdown"
                          >
                            <span>Xóa tài khoản</span>
                          </div>
                        </Menu.Item>
                      )}
                    </>
                  }
                </Menu>
              );

              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Dropdown overlay={menu}>
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        <MoreOutlined style={{ color: "#292D34" }} />
                      </Space>
                    </a>
                  </Dropdown>
                </div>
              );
            },
          },
        ]}
      />

      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        onOpenSuccess={modalForm.onOpenSuccess}
        destroyOnClose={true}
        footerNode={
          modalForm.type === typeUserModal.PACKAGE_INFO ? null : renderFooter
        }
        {...modalProps}
        // width={modalForm.type === typeUserModal.INFO_USER ? 'auto' : null}
      >
        <div className="user-management-modal">{renderBodyModal()}</div>
      </Modal>
    </div>
  );
}

export default withRouter(UserPage);
