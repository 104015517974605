import {useEffect} from "react";
import {useSelector} from "react-redux";
import dayjs from "dayjs";

import {useUser} from "../../../client/useUser";
import {packageUsingSelector} from "../../../../redux/slice/user/userSelector";
import './styles.scss';
import {APP_CONST} from "../../../../constants/enum";
import { DATE_FORMAT_CREATE } from "../../../../constants/apiConstants";

const PackageUserInfo = ({userId}) => {
    const { getUserPackageUsingHandle } = useUser();

    const usePackage = useSelector(packageUsingSelector);

    useEffect(() => {
        if(userId) {
            getUserPackageUsingHandle({userId});
        }
    }, [userId]);

    let createdAtFormat = "";
    let expiredAtFormat = "";
    let days = "";
    if(usePackage) {
        createdAtFormat = dayjs(usePackage.createdAt).format(DATE_FORMAT_CREATE)
        if (usePackage.service?.packageList) {
            days = `${Math.max(0,dayjs(usePackage.expiredAt).diff(dayjs(), "day"))} ngày`;
            expiredAtFormat = dayjs(usePackage.expiredAt).format(DATE_FORMAT_CREATE)
        } else {
            days = APP_CONST.NO_LIMIT_TIME;
            expiredAtFormat = APP_CONST.NO_LIMIT_TIME;
        }
    }

    return <div className={"package-user-management"}>
        <div className={"row-info"}>
            <span>Trạng thái gói</span>
            <span className={"pkg-active"}>Đã kích hoạt</span>
        </div>
        <div className={"row-info"}>
            <span>Tên gói</span>
            <span>{usePackage?.service?.name}</span>
        </div>
        <div className={"row-info"}>
            <span>Thời gian còn lại</span>
            <span>{days}</span>
        </div>
        <div className={"row-info"}>
            <span>Ngày đăng ký</span>
            <span>{createdAtFormat}</span>
        </div>
        <div className={"row-info"}>
            <span>Ngày hết hạn</span>
            <span>{expiredAtFormat}</span>
        </div>
    </div>
}

export default PackageUserInfo;