const IconWarning = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1751_13965)">
        <path
          d="M29.6 23.5112L18.6687 4.52746C18.3956 4.06237 18.0056 3.67673 17.5375 3.40879C17.0694 3.14084 16.5394 2.99988 16 2.99988C15.4606 2.99988 14.9306 3.14084 14.4625 3.40879C13.9944 3.67673 13.6044 4.06237 13.3312 4.52746L2.4 23.5112C2.13717 23.9611 1.99866 24.4727 1.99866 24.9937C1.99866 25.5147 2.13717 26.0264 2.4 26.4762C2.66966 26.9441 3.05896 27.3318 3.52795 27.5996C3.99694 27.8674 4.52873 28.0056 5.06875 28H26.9312C27.4708 28.0051 28.0021 27.8667 28.4706 27.599C28.9391 27.3312 29.328 26.9437 29.5975 26.4762C29.8607 26.0266 29.9997 25.5151 30.0001 24.994C30.0005 24.473 29.8624 23.9613 29.6 23.5112ZM15 13C15 12.7347 15.1054 12.4804 15.2929 12.2929C15.4804 12.1053 15.7348 12 16 12C16.2652 12 16.5196 12.1053 16.7071 12.2929C16.8946 12.4804 17 12.7347 17 13V18C17 18.2652 16.8946 18.5195 16.7071 18.7071C16.5196 18.8946 16.2652 19 16 19C15.7348 19 15.4804 18.8946 15.2929 18.7071C15.1054 18.5195 15 18.2652 15 18V13ZM16 24C15.7033 24 15.4133 23.912 15.1666 23.7472C14.92 23.5823 14.7277 23.3481 14.6142 23.074C14.5006 22.7999 14.4709 22.4983 14.5288 22.2073C14.5867 21.9164 14.7296 21.6491 14.9393 21.4393C15.1491 21.2295 15.4164 21.0867 15.7074 21.0288C15.9983 20.9709 16.2999 21.0006 16.574 21.1141C16.8481 21.2277 17.0824 21.4199 17.2472 21.6666C17.412 21.9133 17.5 22.2033 17.5 22.5C17.5 22.8978 17.342 23.2793 17.0607 23.5606C16.7794 23.8419 16.3978 24 16 24Z"
          fill="#FFB55E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1751_13965">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconWarning;
