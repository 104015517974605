import { useEffect, useState } from "react";

import { UserObserver } from "../../../../observer/ueser/UserObserver";
import dayjs from "dayjs";
import "./styles.scss";

const LockAccountUser = ({ userId, isLocked }) => {
  let observer = UserObserver({});
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (userId) {
      observer.getUserInfo(userId).then((result) => {
        const starAtFormat = result?.startAt
          ? dayjs(result?.startAt).format("HH:mm_DD.MM.YYYY")
          : "";
        setUserInfo({ ...result, starAtFormat });
      });
    }
  }, [userId]);

  return (
    <div className={"lock-user-management"}>
      {isLocked ? (
        <span>Thông tin tài khoản sẽ khóa </span>
      ) : (
        <span>Thông tin tài khoản mở khóa</span>
      )}

      <table>
        <tr>
          <td>Họ tên</td>
          <td>{userInfo?.fullName}</td>
        </tr>
        <tr>
          <td>Tài khoản</td>
          <td>{userInfo?.username}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{userInfo?.email}</td>
        </tr>
        <tr>
          <td>Số điện thoại</td>
          <td>{userInfo?.phoneNumber}</td>
        </tr>
        <tr>
          <td>Domain</td>
          <td>
            <p>{userInfo?.tenantId}</p>
          </td>
        </tr>
        <tr>
          <td>Tên doanh nghiệp</td>
          <td>{userInfo?.companyName}</td>
        </tr>
        <tr>
          <td>Lĩnh vực hoạt động</td>
          <td>{userInfo?.business}</td>
        </tr>
        <tr>
          <td>Ngày đăng ký tài khoản</td>
          <td>{userInfo?.starAtFormat}</td>
        </tr>
      </table>
    </div>
  );
};

export default LockAccountUser;
