import {withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Col,  Row, Spin} from 'antd';
import {FeatureObserver} from "../../../observer/package/FeatureObserver";
import {ServicePermission} from "./includes/ServicePermission";
import {DEFAULT_PAGE} from "../../../constants/apiConstants";

function FeaturePage() {
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState({});
    const [size, setSize] = useState(4);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [dataSource, setDataSource] = useState([]);
    const [dataServices, setDataServices] = useState({});

    const observer = FeatureObserver(
        {
            setLoading,
            params,
            setParams,
        }
    );
    useEffect(() => {
        observer.getAllPermissionOfServices().then((res) => {
            setDataSource(res?.map((item) => {
                return {
                    title: item?.ScopeName,
                    key: `Scope-${item?.Scope}`,
                    children: item?.Permissions?.map((permission) => {
                        const objResult = {
                            key: `${item?.Scope}-${permission?.Id}`,
                            title: permission?.Name,
                        };
                        if(Array.isArray(permission.Child) && permission.Child.length > 0)
                        {
                            objResult.children = permission.Child.map(childItem => (
                                {
                                key: `${item?.Scope}-${childItem.Id}`,
                                title: childItem.Name,}
                            ));
                        }
                        return objResult
                    }) || [],
                }
            }))
        })
    }, []);

    useEffect(() => {
        observer.getPermissionItemsServices({
            "pageNumber": page,
            "pageSize": size
        }).then((res) => {
            setDataServices(res);
            setLoading(false);
        })
    }, [page, size, params]);

    return (
        <div className={"feature-root"}>
            {/*<div style={{*/}
            {/*    display: "flex",*/}
            {/*    gap: "10px",*/}
            {/*    justifyContent: "center",*/}
            {/*    alignItems: "center",*/}
            {/*}}>*/}
            {/*    <h2>Quyền theo gói</h2>*/}
            {/*    <Tooltip title="Làm mới">*/}
            {/*        <Button shape="circle"*/}
            {/*                onClick={() => {*/}
            {/*                    setLoading(true)*/}
            {/*                    setParams({...params});*/}
            {/*                }}*/}
            {/*                icon={<RedoOutlined/>}/>*/}
            {/*    </Tooltip>*/}
            {/*</div>*/}
            <div>
                {/*<Divider orientation="left">Danh sách gói</Divider>*/}
                {/*<Space>*/}
                {/*    <Pagination onChange={(page, pageSize) => {*/}
                {/*        setPage(page)*/}
                {/*    }} pageSize={size} total={dataServices?.total || 0}/>*/}
                {/*</Space>*/}
                <div style={{marginBottom: "20px"}}></div>
                <Spin spinning={loading}>
                    <Row gutter={16}>
                        {dataServices?.items?.map((service) => {
                            return (
                                <Col className="gutter-row" span={6}>
                                    <ServicePermission onSave={observer.updatePermissionsOfService} service={service}
                                                       dataSource={dataSource}/>
                                </Col>
                            );
                        }) || []}
                    </Row>
                </Spin>
            </div>
        </div>
    )
}

export default withRouter(FeaturePage);