import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  changeRequestBuyMoreApi,
  changeStatusUserRequestApi,
  createTenantApi,
  getAllRequestBuyMoreApi,
  getAllUserRequestApi,
  getExtendPackage,
  pushChangePackage,
  updatePkgSettingTenant,
  pushRequestUserCreateApi,
  checkRequestDomainApi,
  lockRequestUserApi,
  getRequestUserInfoApi,
  getRequestUserListServiceApi,
  deleteRequestUserApi,
} from "../../redux/slice/admin/adminApis";
import { AlertType, openNotificationWithIcon } from "../../utils/AlertMessage";
import { notification } from "antd";
import { STATUS_PROCESS_REQUEST } from "../../constants/enum";
import { ERROR_API } from "../../constants/errorMessages";
import { getListUsers } from "../../redux/slice/user/userApis";

export const useRequest = () => {
  const dispatch = useDispatch();
  // let userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN));

  const getListUsersHandle = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(getListUsers(payload)).unwrap();
        resolve({
          isSuccess: true,
          data: data.items,
          total: data.total,
        });
        console.log("data", data);
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getAllRequestBuyMore = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(getAllRequestBuyMoreApi(payload)).unwrap();
        resolve({
          isSuccess: true,
          data: data.items,
          total: data.total,
          todayCount: data.todayCount,
          yesterdayCount: data.yesterdayCount,
          twoDaysAgoCount: data.twoDaysAgoCount,
          ratioTodayYesterday: data.ratioTodayYesterday,
          ratioYesterdayTwoDaysAgo: data.ratioYesterdayTwoDaysAgo,
          thisMonthCount: data.thisMonthCount,
          lastMonthCount: data.lastMonthCount,
          twoMonthsAgoCount: data.twoMonthsAgoCount,
          ratioThisMonthLastMonth: data.ratioThisMonthLastMonth,
          ratioLastMonthTwoMonthsAgo: data.ratioLastMonthTwoMonthsAgo,
        });
        // console.log("data", data);
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const changeRequestBuyMore = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const { status } = payload;
        console.log("status", status);
        await dispatch(changeRequestBuyMoreApi(payload)).unwrap();
        let msg = "";
        switch (status) {
          case STATUS_PROCESS_REQUEST.DONE:
            msg = "Kiểm duyệt thành công";
            break;
          case STATUS_PROCESS_REQUEST.NO_PROCESS:
            msg = "Chuyển trạng thái yêu cầu thành công";
            break;
          case STATUS_PROCESS_REQUEST.REJECT:
            msg = "Đã từ chối yêu cầu";
            break;
        }
        openNotificationWithIcon(notification, AlertType.success, msg);
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getAllUserRequest = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(getAllUserRequestApi(payload)).unwrap();
        resolve({
          isSuccess: true,
          data: data.items,
          total: data.total,
          todayCount: data.todayCount,
          yesterdayCount: data.yesterdayCount,
          twoDaysAgoCount: data.twoDaysAgoCount,
          thisMonthCount: data.thisMonthCount,
          lastMonthCount: data.lastMonthCount,
          twoMonthsAgoCount: data.twoMonthsAgoCount,
          changeTodayYesterday: data.changeTodayYesterday,
          changeYesterdayTwoDaysAgo: data.changeYesterdayTwoDaysAgo,
          ratioThisMonthLastMonth: data.ratioThisMonthLastMonth,
          ratioLastMonthTwoMonthsAgo: data.ratioLastMonthTwoMonthsAgo,
        });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const changeUserRequest = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const { status } = payload;
        await dispatch(changeStatusUserRequestApi(payload)).unwrap();
        let msg = "";
        switch (status) {
          case STATUS_PROCESS_REQUEST.NO_PROCESS:
            msg = "Chuyển trạng thái yêu cầu thành công";
            break;
          case STATUS_PROCESS_REQUEST.REJECT:
            msg = "Đã từ chối yêu cầu";
            break;
        }
        openNotificationWithIcon(notification, AlertType.success, msg);
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const createTenant = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(createTenantApi(payload)).unwrap();
        openNotificationWithIcon(
          notification,
          AlertType.success,
          "Kích hoạt gói cho người dùng thành công"
        );
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const confirmExtend = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(getExtendPackage(payload)).unwrap();
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const changePackage = useCallback(async (payload) => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(pushChangePackage(payload)).unwrap();
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const updatePkgSettingUser = useCallback(async (payload) => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(updatePkgSettingTenant(payload)).unwrap();
        openNotificationWithIcon(
          notification,
          AlertType.success,
          "Cập nhật thiết lập thành công"
        );
        resolve({ isSuccess: true });
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const checkExistedDomain = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(checkRequestDomainApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);
  

  const lockUserAccount = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(lockRequestUserApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getRequestUserInfoAccount = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(getRequestUserInfoApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const pushUserRequestCreate = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(pushRequestUserCreateApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          e?.response?.data?.message || ERROR_API
        );
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getUserRequestListService = useCallback((payload, setLoading) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          getRequestUserListServiceApi(payload)
        ).unwrap();
        resolve(data);
      } catch (error) {
        if (error.response?.status !== 406) {
          openNotificationWithIcon(
            notification,
            AlertType.error,
            error?.response?.data?.message || "Lỗi hệ thống"
          );
        }
        return {};
      } finally {
        if (setLoading) {
          setLoading(false);
        }
      }
    });
  }, []);

  const deleteUserRequest = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(deleteRequestUserApi(payload)).unwrap();
        resolve(data);
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  return {
    changeUserRequest,
    getAllUserRequest,
    createTenant,
    confirmExtend,
    changePackage,

    getAllRequestBuyMore,
    changeRequestBuyMore,
    getListUsersHandle,
    updatePkgSettingUser,
    deleteUserRequest,

    pushUserRequestCreate,
    checkExistedDomain,
    lockUserAccount,
    getRequestUserInfoAccount,
    getUserRequestListService,
  };
};
