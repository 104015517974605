const IconUp = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 14.1666L7.5 9.16659L10.8333 12.4999L17.5 5.83325M17.5 5.83325V9.99992M17.5 5.83325H13.3333"
        stroke="#2DC56A"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default IconUp;
