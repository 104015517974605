const IconProfileWhite = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="12.042"
        cy="8.20922"
        rx="3.92382"
        ry="3.92382"
        fill="#2DC56A"
      />
      <path
        d="M18.1502 8.00589C18.1502 11.4029 15.3963 14.1568 11.9993 14.1568C8.60227 14.1568 5.84844 11.4029 5.84844 8.00589C5.84844 4.60887 8.60227 1.85503 11.9993 1.85503C15.3963 1.85503 18.1502 4.60887 18.1502 8.00589Z"
        stroke="white"
        stroke-width="1.4"
      />
      <path
        d="M20.298 20.0006C19.0372 16.6436 15.7976 14.2546 12.0001 14.2546C8.20255 14.2546 4.96296 16.6436 3.70215 20.0006"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
      />
      <path
        d="M11.9992 16.2637C8.92902 16.2637 6.33054 18.2039 5.4598 20.8776C5.11777 21.9279 6.04387 22.8451 7.14844 22.8451H16.85C17.9545 22.8451 18.8806 21.9279 18.5386 20.8776C17.6679 18.2039 15.0694 16.2637 11.9992 16.2637Z"
        fill="#2DC56A"
      />
    </svg>
  );
};
export default IconProfileWhite;
