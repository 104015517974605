import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { getDashboardHome } from "../redux/slice/user/userApis";
import {
  getRequestRevenueApi,
  getRequestIndicatorsApi,
  getRequestExpiredServiceApi,
  getRequestTotalRegisterPackageApi,
  getRequestTotalExtendPackageApi,
  getRequestTotalChangePackageApi,
  getRequestTotalBuyMoreServiceApi
} from "../redux/slice/admin/adminApis";

export const useDashboard = () => {
  const dispatch = useDispatch();
  // let userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN));

  const handleGetDashboardInfo = useCallback(() => {
    return new Promise(async (resolve) => {
      try {
        await dispatch(getDashboardHome());
        resolve({ isSuccess: true });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getRequestRevenue = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(getRequestRevenueApi(payload)).unwrap();
        resolve({ isSuccess: true, data });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getRequestIndicators = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(getRequestIndicatorsApi(payload)).unwrap();
        resolve({ isSuccess: true, data });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getExpiredService = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          getRequestExpiredServiceApi(payload)
        ).unwrap();
        resolve({ isSuccess: true, data });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getTotalRegisterPackage = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          getRequestTotalRegisterPackageApi(payload)
        ).unwrap();
        resolve({ isSuccess: true, data });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getTotalExtendPackage = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          getRequestTotalExtendPackageApi(payload)
        ).unwrap();
        resolve({ isSuccess: true, data });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getTotalChangePackage = useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          getRequestTotalChangePackageApi(payload)
        ).unwrap();
        resolve({ isSuccess: true, data });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  const getTotalBuyMoreService= useCallback((payload) => {
    return new Promise(async (resolve) => {
      try {
        const data = await dispatch(
          getRequestTotalBuyMoreServiceApi(payload)
        ).unwrap();
        resolve({ isSuccess: true, data });
      } catch (e) {
        resolve({ isSuccess: false });
      }
    });
  }, []);

  return {
    handleGetDashboardInfo,
    getRequestRevenue,
    getRequestIndicators,
    getExpiredService,
    getTotalRegisterPackage,
    getTotalExtendPackage,
    getTotalChangePackage,
    getTotalBuyMoreService
  };
};
