import IconSuccess from "../../../../components/svg/icon-success";

const ModalSuccessAccount = ({ isLocked }) => {
  return (
    <>
      <div className="modal-add">
        <div className="body-add">
          <IconSuccess />
          {isLocked ? (
            <span>Đã khóa tài khoản thành công!</span>
          ) : (
            <span>Đã mở khóa tài khoản thành công!</span>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalSuccessAccount;
