import {statusEnum, StatusSelect, STATUS_PACKAGE_FILTER} from "../../constants/enum";
// import {businessType} from "../../constants/businessFieldType";
import {SELECT_CUSTOM_OPTION} from "../../constants/form";


const InputSelect = async (propsInputSelect) => {
    let listServices = propsInputSelect.listServices
    console.log("listServices", listServices)
        const fetchOptionsServices = async (search: string, page) => {
    
            try {
                let options = [];
                const response = await propsInputSelect.useCaseService.listServices({
                    searchText: search,
                    pageNumber: page.pageIndex,
                    pageSize: page.pageSize
                });
                options = response?.items?.map((value: any) => ({
                    value: value.id,
                    label: `${value.name}`,
                }));
                return {
                    total: response?.total,
                    options:[
                        {
                            label: "Tất cả",
                            value: null,
                        }
                    ].concat( options),
                };
            } catch (error) {
    
                return [];
            }
        };
        let defaultOptionsService = await fetchOptionsServices("", {pageIndex: 1, pageSize: 10})
    
    return [
        {
            key: "searchText",
            sendingField: "searchText",
            type: "input",
            placeholder: "Tìm kiếm theo tên, tài khoản, tên miền, SĐT, email",
            change: propsInputSelect.handleChangInput,
        },

        {
            type: "select",
            label: "Trạng thái tài khoản",
            placeholder: "Chọn Trạng thái gói",
            sendingField: "statusAccount",
            defaultValue: statusEnum.ACTIVE,
            data: StatusSelect, //Trường gửi api
            change: propsInputSelect.handleChangInput,
        },
        // {
        //     type: "select",
        //     label: "Lĩnh vực kinh doanh",
        //     placeholder: "Lĩnh vực kinh doanh",
        //     sendingField: "businessType",
        //
        //     data: businessType, //Trường gửi api
        //     change: propsInputSelect.handleChangInput,
        // },
        {
            sendingField: "serviceId",
            type: SELECT_CUSTOM_OPTION,
            label: "Gói dịch vụ",
            change: propsInputSelect.handleChangInput,

            optionsDefault: defaultOptionsService?.options || [],
            fetchOptions: fetchOptionsServices,
        },
        {
            type: "select",
            label: "Trạng thái sử dụng gói",
            placeholder: "Trạng thái sử dụng gói",
            sendingField: "statusPackage",
            defaultValue: -1,
            data: [
                {label: "Tất cả", value: STATUS_PACKAGE_FILTER.ALL},
                {label: "Đã kích hoạt", value: STATUS_PACKAGE_FILTER.ACTIVATED},
                {label: "Chờ kích hoạt", value: STATUS_PACKAGE_FILTER.WAIT_APPROVAL},
                {label: "Đã hết hạn", value: STATUS_PACKAGE_FILTER.EXPIRED},
                {label: "Sắp hết hạn", value: STATUS_PACKAGE_FILTER.EXPIRE_SOON},
                {label: "Bị từ chối", value: STATUS_PACKAGE_FILTER.REJECTED},
            ],
            change: propsInputSelect.handleChangInput,
        },
        // {
        //     type: "select",
        //     label: "Thời gian sử dụng gói",
        //     placeholder: "Thời gian sử dụng gói",
        //     sendingField: "packetTime",
        //     defaultValue: 3,
        //     data: [
        //         {label: "Tất cả", value: 3},
        //         {label: "Quá hạn", value: -1},
        //         {label: "Sắp đến hạn", value: 1},
        //         {label: "Còn hạn", value: 2},
        //     ],
        //     change: propsInputSelect.handleChangInput,
        // },
        // {
        //     type: "select",
        //     label: "Trạng thái Domain",
        //     placeholder: "Trạng thái Domain",
        //     sendingField: "isActiveDomain",
        //     defaultValue: 2,
        //     data: [
        //         {label: "Tất cả", value: 2},
        //         {label: "Chưa kích hoạt", value: -1},
        //         {label: "Đã kích hoạt", value: 1},
        //     ],
        //     change: propsInputSelect.handleChangInput,
        // },
        // {
        //     type: "dateRangePicker",
        //     label: "Thời gian tạo",
        //     placeholder: ["Bắt đầu", "kết thúc"],
        //     sendingField: ["fromCreatedAt", "toCreatedAt"], //Trường gửi api
        //     change: propsInputSelect.handleChangDatePicker,
        // },
    ]
}
export default InputSelect