import {useEffect, useState} from "react";

import {useUser} from "../../../client/useUser";
import './styles.scss';
import {EBuyMoreSystem, STATUS_USING} from "../../../../constants/enum";
import dayjs from "dayjs";
import { formatInputMoney } from "../../../../utils/helper";

const ServiceUserInfo = ({userId}) => {
    const { getAllStatusBuyMoreUserHandle } = useUser();
    const [dataSource, setDataSource] = useState([]);


    useEffect(() => {
        if(userId) {
            getAllStatusBuyMoreUserHandle({userId}).then(result => {
                setDataSource(result.data);
            });
        }
    }, [userId]);

    return <div className={"service-user-management"}>
        {dataSource?.map(item => {
            const createdAtFormat = item.createdAt ? dayjs(item.createdAt).format("DD/MM/YYYY_HH:mm") : "";
            const expiredAtFormat = item.expiredAt ? dayjs(item.expiredAt).format("DD/MM/YYYY_HH:mm") : "";
            const formatMoney = formatInputMoney(item.price);
            return <div className={"service-box-item"} key={`${item.id}`}>
                <div className="box-service-info">
                    <span className={"service-name"}>{item.packageName} - {item.serviceName}</span>
                    {item.userServiceStatus === STATUS_USING.ACTIVE && (
                        <span className={"service-status-activated"}>Đang sử dụng</span>
                    )}
                </div>

                <div className={"box-content"}>
                    {item.valueOther > 0 && (
                        <div>Đã mua: <span>{item.valueOther} user</span></div>
                    )}
                    <div>Ngày mua: <span>{createdAtFormat}</span></div>
                    <div>Ngày hết hạn: <span>{item.systemType > EBuyMoreSystem.NORMAL ?  expiredAtFormat : 'Vô thời hạn'}</span></div>
                    <div>Giá dịch vụ: <span>{formatMoney} đ</span></div>
                </div>
            </div>;
        })}
    </div>
}

export default ServiceUserInfo;