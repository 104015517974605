import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

import {
  getStatusText,
  STATUS_PROCESS_REQUEST,
  STATUS_REQUEST,
} from "../../../../constants/enum";
import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import TableComponent from "../../components/table";
import Modal from "../../../../components/modal/modal-card";
import IconSuccess from "../../../../components/svg/icon-success";
import IconWarning from "../../../../components/svg/icon-warning";
import { useRequest } from "../../useRequest";
import {
  AlertType,
  openNotificationWithIcon,
} from "../../../../utils/AlertMessage";
import { notification } from "antd";
import { formatInputMoney } from "../../../../utils/helper";
import { DEFAULT_PAGE, MAX_RESULTS } from "../../../../constants/apiConstants";
import { DATE_FORMAT_CREATE } from "../../../../constants/apiConstants";

const RegRequest = forwardRef(({ regRequestRejectRef, listAllUserRef }, ref) => {
  const { getAllUserRequest, createTenant, changeUserRequest } = useRequest();

  const [dataSource, setDataSource] = useState([]);
  const [rejectReasonVal, setRejectReason] = useState("");
  const [selected, setSelected] = useState(null);
  const [size, setSize] = useState(MAX_RESULTS);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState({});
  const [modalForm, setModalForm] = useState({
    isOpen: false,
    isClose: true,
    title: "",
    type: 0,
  });

  useImperativeHandle(ref, () => ({
    reloadList() {
      onLoadData();
    },
  }));

  useEffect(() => {
    onLoadData();
  }, [page, size]);

  const onLoadData = () => {
    getAllUserRequest({
      status: STATUS_REQUEST.WAIT_REGIS_NEW,
      statusProcess: STATUS_PROCESS_REQUEST.NO_PROCESS,
      pageNumber: page,
      pageSize: size,
    }).then((result) => {
      let newDataRes = [];
      let pageSize = size;
      result?.data?.forEach((element, index) => {
        newDataRes.push({ ...element, key: (page - 1) * pageSize + index + 1 });
      });
      setDataSource(newDataRes);
      setTotal(result.total);
    });
  };

  const onShowModal = (record) => () => {
    if (!record) return;

    const createdAtFormat = record.createdAt
      ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
      : "";
    setSelected({ ...record, createdAtFormat });
    setModalForm({
      isOpen: true,
      title: "Yêu cầu đăng ký gói",
      type: 1,
    });
  };

  const onCreateTenant = (onDoneCb) => {
    createTenant({ requestId: selected.requestId }).then(function (result) {
      onDoneCb && onDoneCb();
      if (result.isSuccess) {
        setModalForm({
          isOpen: true,
          title: "Thông báo",
          type: 2,
        });
      }
    });
  };

  const onGoRejectForm = () => {
    setModalForm({
      isOpen: true,
      title: "Thông báo",
      type: 3,
    });
  };
  const onReject = (onDoneCb) => {
    if (!rejectReasonVal || rejectReasonVal.trim().length === 0) {
      onDoneCb && onDoneCb();
      openNotificationWithIcon(
        notification,
        AlertType.error,
        "Vui lòng nhập lý do từ chối"
      );
      return;
    }

    changeUserRequest({
      requestId: selected.requestId,
      status: STATUS_PROCESS_REQUEST.REJECT,
      reason: rejectReasonVal,
    }).then((result) => {
      onDoneCb && onDoneCb();
      if (result.isSuccess) {
        regRequestRejectRef.current?.reloadList();
        listAllUserRef.current?.reloadList();
        onLoadData();
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    setModalForm({
      isClose: false,
    });
    setSelected(null);
    setRejectReason("");
  };

  const renderFooter = () => {
    if (modalForm.type === 1) {
      return (
        <>
          <ButtonCustomize
            title={"Thoát"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={handleCancel}
          />
          <ButtonCustomize
            title={"Từ chối"}
            btnType={BtnTypeEnum.GREY}
            btnSize={44}
            onPressHandle={onGoRejectForm}
          />
          <ButtonCustomize
            title={"Kích hoạt gói"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            showLoading={true}
            onPressHandle={onCreateTenant}
          />
        </>
      );
    }

    if (modalForm.type === 2) {
      return (
        <>
          <ButtonCustomize
            title={"Hoàn thành"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={handleCancel}
          />
        </>
      );
    }

    if (modalForm.type === 3) {
      return (
        <>
          <ButtonCustomize
            title={"Hủy bỏ"}
            btnType={BtnTypeEnum.GREY}
            btnSize={44}
            onPressHandle={handleCancel}
          />
          <ButtonCustomize
            title={"Xác nhận từ chối"}
            btnType={BtnTypeEnum.BLACK}
            btnSize={44}
            showLoading={true}
            onPressHandle={onReject}
          />
        </>
      );
    }
  };

  return (
    <>
      <div className="layout-package">
        {/*<p1>Đã chọn</p1>*/}
        {/*<p2>02</p2>*/}
        {/*<button>Từ chối đăng ký gói</button>*/}
        {/*<button>Kích hoạt gói</button>*/}
      </div>
      <TableComponent
        scroll={{ x: 1400, y: "calc(100vh - 220px)" }}
        dataSource={dataSource}
        bordered={true}
        // rowSelection={true}
        totalCount={total}
        page={page}
        setPage={setPage}
        pageSize={size}
        setSize={setSize}
        columns={[
          {
            title: "STT",
            dataIndex: "key",
            width: 20,
          },
          {
            title: "Khách hàng",
            width: 105,
            dataIndex: "fullName",
            key: "fullName",
            render: (val, record) => {
              return (
                <div className="table-user-column">
                  <span>{record.fullName}</span>
                  <div className="table-box">
                    <span>{record.phoneNumber}</span>
                    <span>-</span>
                    <span>{record.email}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Ngày đăng ký",
            width: 60,
            dataIndex: "createdAt",
            key: "createdAt",
            render: (val, record) => {
              const createdAtFormat = record.createdAt
                ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
                : "";
              return <span>{createdAtFormat}</span>;
            },
          },
          {
            title: "Trạng thái TK",
            className: "account-status",
            width: 45,
            dataIndex: "userStatus",
            key: "userStatus",
            render: (val) => {
              return <span>{getStatusText(val)}</span>;
            },
          },
          {
            title: "Gói đăng ký",
            width: 45,
            dataIndex: "serviceName",
            key: "serviceName",
          },
          {
            title: "Giá gói",
            width: 45,
            dataIndex: "amount",
            key: "amount",
            render: (_, record) => {
              return <span>{formatInputMoney(record.amount)} đ</span>;
            },
          },
          {
            title: "Trạng thái gói",
            width: 45,
            dataIndex: "statusProcess",
            key: "statusProcess",
            render: () => {
              return <span className="sub-package">Chờ kích hoạt</span>;
            },
          },
          {
            title: "Hành động",
            width: 38,
            dataIndex: "fullName",
            key: "fullName",
            fixed: "right",
            render: (val, record) => {
              return (
                <ButtonCustomize
                  title={"Kiểm duyệt"}
                  btnType={BtnTypeEnum.GREEN}
                  btnSize={32}
                  onPressHandle={onShowModal(record)}
                />
              );
            },
          },
        ]}
      />
      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        footerNode={renderFooter}
      >
        {/*Yêu cầu đăng ký gói*/}
        {modalForm.type === 1 && (
          <>
            <div className="item-modal">
              <ul>
                <li>Trạng thái</li>
                <li>
                  <span>Chờ kích hoạt</span>
                </li>
              </ul>
              <ul>
                <li>Gói đăng ký</li>
                <li>{selected?.serviceName}</li>
              </ul>
              <ul>
                <li>Thời gian gói đăng ký</li>
                <li>{selected?.duration} ngày</li>
              </ul>
              <ul>
                <li>Ngày yêu cầu đăng ký</li>
                <li>{selected?.createdAtFormat}</li>
              </ul>
              <ul>
                <li>Giá gói</li>
                <li>{formatInputMoney(selected?.amount)} đ</li>
              </ul>
            </div>
          </>
        )}

        {/*Kích hoạt thành công*/}
        {modalForm.type === 2 && (
          <>
            <div className="modal-success">
              <IconSuccess />
              <span>Kích hoạt gói thành công</span>
            </div>
          </>
        )}

        {/*Từ chối đăng ký gói*/}
        {modalForm.type === 3 && (
          <>
            <div className="modal-refuse">
              <ul>
                <IconWarning />
                <li>Từ chối đăng ký gói</li>
              </ul>
              <ul>
                <li>Nhập nội dung lý do từ chối</li>
                <TextArea
                  value={rejectReasonVal}
                  rows={4}
                  placeholder="Nhập vào"
                  onChange={(e) => setRejectReason(e.target.value)}
                />
              </ul>
            </div>
          </>
        )}
      </Modal>
    </>
  );
});

export default RegRequest;
