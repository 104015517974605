import { withRouter } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import {Button, Popconfirm} from "antd";

// import { DEFAULT_PAGE, MAX_RESULTS } from "../../../constants/apiConstants";
import "./package.scss";
import ButtonCustomize from "../../../components/button";
import { BtnTypeEnum } from "../../../components/button/const";
import Modal from "../../../components/modal/modal-card";
import AddPackageForm from "./components/AddPackage";
import ModalSetting from "./components/setting";
import { formatInputMoney } from "../../../utils/helper";
import {usePackage} from "./usePackage";
import FeaturePage from "./FeaturePage";

function PackagePage() {
  const {getAllPackage, createOrUpdatePackage, deletePackage} = usePackage();
  const [dataSource, setDataSource] = useState([]);
  // const [size, setSize] = useState(MAX_RESULTS);
  // const [page, setPage] = useState(DEFAULT_PAGE);
  const [modalForm, setModalForm] = useState({
    isOpen: false,
    title: "",
    type: 0, // 0: Setting package, 1: Setting Permission
    btn_hidden: false,
    btn_container: "",
    btn_success: "",
    successTitle: "",
    successContent:
        "Bộ phận chăm sóc khách hàng sẽ liên hệ với bạn sau khi đã xác nhận thông tin.\n" +
        "Thời gian xác nhận tối thiểu  từ 2 - 4 tiếng  trong giờ hành chính từ  Thứ2 - Thứ 7 hàng tuần (trừ ngày nghỉ, lễ tết...)",
  });

  const packageSelected = useRef(null);
  const addPackageFormRef = useRef(null);

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = () => {
      getAllPackage(
          //     {
          //   pageNumber: page,
          //   pageSize: size,
          // }
      ).then((res) => {
        setDataSource(res.data);
      });
  }

  const showModal = () => {
    packageSelected.current = null;
    setModalForm({
      isOpen: true,
      title: "Thêm gói mới",
      type: 0,
    });
    addPackageFormRef.current?.clearSource();
  };

  const handleCancel = () => {
    setModalForm((prev) => ({
      ...prev,
      isOpen: false,
      type: -1,
    }));
  };

  const onDetailPackage = (packageItem) => () => {
    packageSelected.current = packageItem;
    setModalForm({
      isOpen: true,
      title: "Thêm gói mới",
      type: 0,
    });
  }

  const onDeletePackage = (packageItem) => () => {
    deletePackage(packageItem.id).then(() => {
      reloadData();
    });
  }

  const renderBodyModal = () => {
    if (modalForm.type === 0) {
      return <AddPackageForm ref={addPackageFormRef} serviceId={packageSelected.current?.id} packageName={packageSelected.current?.name || ""}
                             description={packageSelected.current?.description || ""}
                             packageActive={packageSelected.current?.active_members || 0} priceList={packageSelected.current?.packageList || []}/>;
    }

    return <ModalSetting />;
  };

  const renderFooter = () => {
    const onSave = (onDoneCb) => {
      const payload = addPackageFormRef.current.getDataForm();
      if (packageSelected.current?.id) {
        payload.packageId = packageSelected.current?.id;
      }

      createOrUpdatePackage(payload).then(() => {
        onDoneCb && onDoneCb();
        handleCancel();
        reloadData();
      });
    }

    return (
      <>
        {modalForm.type === 0 && (
          <>
            <ButtonCustomize
              title={"Thoát"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            {/*<ButtonCustomize*/}
            {/*  title={"Nhập lại"}*/}
            {/*  btnType={BtnTypeEnum.GREY}*/}
            {/*  btnSize={44}*/}
            {/*  // onPressHandle={onConfirm}*/}
            {/*/>*/}
            <ButtonCustomize
              title={"Lưu lại"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              showLoading={true}
              onPressHandle={onSave}
            />
          </>
        )}
        {modalForm.type === 1 && (
          <>
            <ButtonCustomize
              title={"Thoát"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Lưu lại"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              // onPressHandle={onConfirm}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="body-content-package">
        <div className="title-list">
          <span>Danh sách gói</span>
          <ButtonCustomize
            title={"Thêm gói mới"}
            btnType={BtnTypeEnum.BLACK}
            btnSize={44}
            onPressHandle={showModal}
          />
        </div>
        <div className="card-package">
          {dataSource?.map((item) => {
            return (
              <div className="card-free">
                <div className="box-free">
                  <p>Gói {item.name}</p>
                  <div className={"btn-right"}>
                    <Popconfirm
                        title="Xóa bản ghi"
                        description="Bạn có muốn xóa bản ghi này?"
                        onConfirm={onDeletePackage(item)}
                        okText="Đồng ý"
                        cancelText="Hủy"
                    >
                      <Button danger>Xóa</Button>

                      {/*<ButtonCustomize*/}
                      {/*      title={"Xóa"}*/}
                      {/*      btnType={BtnTypeEnum.OUTLINE}*/}
                      {/*      btnSize={32}*/}
                      {/*      // onPressHandle={onDeletePackage(item)}*/}
                      {/*  />*/}
                    </Popconfirm>

                    <ButtonCustomize
                        title={"Thiết lập"}
                        btnType={BtnTypeEnum.BLACK}
                        btnSize={32}
                        onPressHandle={onDetailPackage(item)}
                    />
                  </div>
                </div>
                <div className="content-card">
                  <ul>
                    <li>- User khởi tạo:</li>
                    <li>{item.active_members || 0}</li>
                  </ul>
                  {/* {item.can_try_free && (
                    <ul>
                      <li>- Giá 15 ngày:</li>
                      <li>0đ</li>
                    </ul>
                  )} */}
                  {item.packageList.map((pg) => (
                    <>
                      {/* <ul>
                        <li>- Giá {pg.duration} ngày:</li>
                        <li>{formatInputMoney(pg.price_org)}đ</li>
                      </ul> */}
                      <div className="item-price">
                        <span>- Giá {pg.duration} ngày:</span>
                        <span>{pg.discount > 0 ? `${formatInputMoney(pg.price_org)}đ` : ""}</span>
                        <span>{formatInputMoney(pg.price)}đ</span>
                        {pg.discount > 0 && (
                          <span>{pg.discount}%</span>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <div className="title-list">
          <span>Tính năng</span>
          {/*<ButtonCustomize*/}
          {/*  title={"Thiết lập tính năng"}*/}
          {/*  btnType={BtnTypeEnum.BLACK}*/}
          {/*  btnSize={44}*/}
          {/*  // onPressHandle={open}*/}
          {/*/>*/}
        </div>
        <div>
          <FeaturePage />
          {/*<TableComponent dataSource={data} columns={columns} />*/}
        </div>
      </div>

      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        onOpenSuccess={modalForm.onOpenSuccess}
        footerNode={renderFooter}
        width={"auto"}
      >
        <div className="package-modal">
          {/*<div className="package-body">*/}
            {renderBodyModal()}
          {/*</div>*/}
        </div>
      </Modal>

      {/* 
      <FilterSearch
        InputSelect={InputSelect(propsInputSelect)}
        ResetFilter={resetFilter}
        addButton={createPackage}
        refreshButton={refreshPackage}
      />

      <TableCustomer
        loading={loading}
        dataColumns={columnPackage(propsColumn)}
        dataSource={dataSource?.items || []}
        setSize={setSize}
        pageSize={size}
        page={page}
        setPage={setPage}
        scrollSize={0}
        rowSelection={observer.getRowSelection()}
        totalCount={dataSource?.total || 0}
        indexBool={true}
        localStorageColumn={`${TABLE_STORAGE}Package`}
      />
      <ModalComponent
        open={openModal}
        type={TYPE_FORM_CREATE_PACKAGE}
        onCancel={onCancel}
        dataModal={dataModal}
        okText={okText}
        form={form}
        span={1167}
      /> */}
    </>
  );
}

export default withRouter(PackagePage);
