import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Route } from "react-router-dom";
import { Layout, notification } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { KEY_USER_LOGIN } from "../constants/apiConstants";
import Home from "../components/Home/Home";
import userSupport from "../pages/client/user-support";
import packageCrm from "../pages/client/package-crm";
import UserPage from "../pages/admin/user/NewUserPage";
import PackagePage from "../pages/admin/package/PackagePage";
import TopBar from "../components/layout/header-layout/Header";
import MenuLayout from "../components/layout/menu-layout/menu";
import { Content } from "antd/es/layout/layout";
import AdminHome from "../pages/admin/home";

import "./adminRoute.scss";
import { ROUTE_URL } from "../constants/routeUrl";
import RegSub from "../pages/admin/home/RegSub";
import ExtendTab from "../pages/admin/home/ExtendSub";
import ChangeTab from "../pages/admin/home/ChangeSub";
import RegServiceTab from "../pages/admin/home/RegService";
import ExtendServiceTab from "../pages/admin/home/ExtendService";
import BuyMoreService from "../pages/admin/buyMore";
import ServiceByPkg from "../pages/client/services";
import ExpiredPackage from "../pages/admin/dashboard/ContactList/ExpiredPackage";
import ExpireSoonPackage from "../pages/admin/dashboard/ContactList/ExpireSoonPackage";
import ExpireService from "../pages/admin/dashboard/ContactList/ExpireService";

const { Sider, Header } = Layout;

function AdminRoute() {
  const [api] = notification.useNotification();
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  let user = localStorage.getItem(KEY_USER_LOGIN)
    ? JSON.parse(localStorage.getItem(KEY_USER_LOGIN))
    : null;
  const isAdmin = user && user.is_admin;

  useEffect(() => {
    setCollapsed(!isMobile);
  }, [isMobile]);

  const privatePage = [
    {
      url: "/home",
      component: Home,
      props: {
        notification: api,
      },
    },
    {
      url: "/user-support",
      component: userSupport,
      props: {
        notification: api,
      },
    },
    {
      url: "/package-crm",
      component: packageCrm,
      props: {
        notification: api,
      },
    },
    {
      url: "/services",
      component: ServiceByPkg,
      props: {
        notification: api,
      },
    },
    

  ];
  const adminPage = [
    {
      url: ROUTE_URL.ADMIN.HOME,
      component: AdminHome,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.REG_SUB,
      component: RegSub,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.EXTEND_SUB,
      component: ExtendTab,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.CHANGE_SUB,
      component: ChangeTab,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.REG_SERVICE,
      component: RegServiceTab,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.EXTEND_SERVICE,
      component: ExtendServiceTab,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.EXPIRED_PACKAGE,
      component: ExpiredPackage,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.EXPIRE_SOON_PACKAGE,
      component: ExpireSoonPackage,
      props: {
        notification: api,
      },
    },
    {
      url: ROUTE_URL.ADMIN.EXPIRED_SERVICE,
      component: ExpireService,
      props: {
        notification: api,
      },
    },
    {
      url: "/admin/user",
      component: UserPage,
      props: {
        notification: api,
      },
    },
    {
      url: "/admin/package",
      component: PackagePage,
      props: {
        notification: api,
      },
    },
    {
      url: "/admin/feature/item",
      component: BuyMoreService,
      props: {
        notification: api,
      },
    },
  ];

  const toggleMenu = (e) => {
    e.stopPropagation();
    setCollapsed(!collapsed);
  };

  const handleMenuItemClick = () => {
    setCollapsed(false);
  };

  const handleMenuItemClickWithRoleCheck = () => {
    if (isAdmin) {
      handleMenuItemClick();
    }
  };

  const handleClickOutside = (event) => {
    if (collapsed && !event.target.closest(".sidebar-layout-app") && isMobile) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [collapsed]);

  return (
    <Layout className={"layout-app"}>
      <Header className={"header-layout-app"}>
        <TopBar />
      </Header>
      <Layout>
        <Sider
          width={!isMobile ? collapsed ? "264px" : "80px" : collapsed ? "264px" : "0px"}
          className={"sidebar-layout-app"}
          style={{
            position: "fixed",
            top: 0,
            background: "#FFFFFF",
            transition: "all 0.3s ease-in-out",
            padding: collapsed ? "24px" : "24px 0px",
          }}
        >
          {isAdmin ? (
            <>
              <button
                className={
                  collapsed
                    ? `button-menu ${collapsed ? "button-collapsed" : ""}`
                    : `button-item ${collapsed ? "button-collapsed" : ""}`
                }
                onClick={toggleMenu}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </button>
              <MenuLayout
                collapsed={collapsed}
                isAdmin={isAdmin}
                onMenuItemClick={handleMenuItemClick}
              />
            </>
          ) : (
            <>
              {isMobile ? (
                <>
                  <button
                    className={`button-menu ${
                      collapsed ? "button-collapsed" : ""
                    }`}
                    onClick={toggleMenu}
                  >
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  </button>
                  {collapsed && (
                    <MenuLayout
                      collapsed={collapsed}
                      isAdmin={isAdmin}
                      onMenuItemClick={handleMenuItemClick}
                    />
                  )}
                </>
              ) : (
                <>
                  {collapsed && (
                    <MenuLayout
                      collapsed={collapsed}
                      isAdmin={isAdmin}
                      onMenuItemClick={handleMenuItemClickWithRoleCheck}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Sider>

        <Content
          className="body-content-layout"
          style={{
            marginLeft: collapsed ? "264px" : "80px",
            transition: "margin-left 0.3s ease-in-out",
          }}
        >
          {isAdmin ? (
            <>
              {adminPage.map((page, index) => {
                return (
                  <Route
                    path={page.url}
                    key={index}
                    render={() => (
                      <>{React.createElement(page.component, page.props)}</>
                    )}
                  />
                );
              })}
            </>
          ) : (
            <>
              {privatePage.map((page, index) => {
                return (
                  <>
                    <Route
                      path={page.url}
                      key={index}
                      render={() => (
                        <>{React.createElement(page.component, page.props)}</>
                      )}
                    />
                  </>
                );
              })}
            </>
          )}
        </Content>
      </Layout>
    </Layout>
  );
}

export default AdminRoute;
