import {DEFAULT_PAGE, MAX_RESULTS} from "../../constants/apiConstants";
import {ACTION_FORM_ADD, ACTION_FORM_EDIT} from "../../constants/form";
import {AlertType, openNotificationWithIcon} from "../../utils/AlertMessage";
import {notification} from "antd";
import {del, post, put} from "../../redux/api/axios";
import {
    API_ADD_SERVICE_USER, API_CHANGE_PACKAGE_USER,
    API_CREATE_TENANT,
    API_CREATE_USER,
    API_EDIT_USER, API_EXTEND_PACKAGE_USER,
    API_FIND_SERVICE_USER,
    API_GET_DATA_INIT_DOMAIN,
    API_LIST_USER,
    API_LIST_WITH_SERVICE_USER,
    API_LOCK_USER, API_POST_USER_INFO,
    API_RE_OPEN_USER,
    API_REMOVE_SERVICE_USER, API_SEND_REQUEST_SERVICE_USER, API_STOP_TENANT, API_UPDATE_COMPANY_USER, 
    API_UPDATE_USER_CONFIG, API_LIST_REQUEST
} from "../../constants/apiUrls";
import {
    TYPE_FORM_ADD_SERVICE_USER,
    TYPE_FORM_CREATE_USER,
} from "../../constants/ModalType";
import {PackageUseCase} from "../package/PackageObserver";

export function UserUseCase() {
    const listUser = async (searchParams) => {
        try {
            let response = await post(API_LIST_USER, searchParams);
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            if (error.response?.status !== 406) {
                if (error.response?.status !== 406) {
                    openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                }
            }
        }
    }
    const listUserWithService = async (searchParams) => {
        try {
            let response = await post(API_LIST_WITH_SERVICE_USER, searchParams);
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            if (error.response?.status !== 406) {
                if (error.response?.status !== 406) {
                    openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                }
            }
        }
    }

    const listUserRequestService = async (searchParams) => {
        try {
            let response = await post(API_LIST_REQUEST, searchParams);
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            if (error.response?.status !== 406) {
                if (error.response?.status !== 406) {
                    openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                }
            }
        }
    }
    const create = async (params) => {
        try {
            return await post(API_CREATE_USER, {
                "description": params.description || "",
                "username": params.username,
                "password": params.password,
                "confirmPassword": params.confirmPassword,
                "email": params.email,
                "fullName": params.fullName,
                "tenantId": params.tenantId,
                "phoneNumber": params.phoneNumber,
                "taxCode": params.taxCode,
                "companyName": params.companyName,
                "businessFieldType": params.businessFieldType,

            })
        } catch (error) {
            throw error;
        }
    }
    const update = async (params, item) => {
        try {
            return await put(API_EDIT_USER, {
                "id": item.id,
                "name": params.name || "",
                "status": params.status,
                "email": params.email,
                "businessFieldType": params.businessFieldType,
                "phoneNumber": params.phoneNumber,
                "companyName": params.companyName,
                "taxCode": params.taxCode,
                "fullName": params.fullName,
                "description": params.description || "",
            })
        } catch (error) {
            throw error;
        }
    }
    const lock = async (id) => {
        try {
            return await post(API_LOCK_USER, {userId: id})
        } catch (error) {
            throw error;
        }
    }
    const removeService = async (id, serviceId) => {
        try {
            return await del(API_REMOVE_SERVICE_USER, {userId: id, serviceId: serviceId})
        } catch (error) {
            throw error;
        }
    }
    const reOpen = async (userId, serviceId) => {
        try {
            return await put(API_RE_OPEN_USER, {userId: userId, serviceId: serviceId})
        } catch (error) {
            throw error;
        }
    }
    const addService = async (params) => {
        try {
            return await post(API_ADD_SERVICE_USER, {
                userId: params.id,
                serviceId: params.serviceId,
                startAt: params.startAt,
            })
        } catch (error) {
            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
            return {
                isSuccess: false
            };
        }
    }
    const requestAddService = async (params) => {
        try {
            return await post(API_SEND_REQUEST_SERVICE_USER, params)
        } catch (error) {
            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
            return {
                isSuccess: false
            };
        }
    }
    // const changeUserPackage = async (params) => {
    //     try {
    //         return await post(API_CHANGE_PACKAGE_USER, {
    //             userId: params.id,
    //             serviceId: params.serviceId,
    //         })
    //     } catch (error) {
    //         throw error;
    //     }
    // }
    // const extendUserPackage = async (params) => {
    //     try {
    //         return await post(API_EXTEND_PACKAGE_USER, {
    //             serviceId: params.serviceId,
    //             duration: params.duration,
    //         })
    //     } catch (error) {
    //         throw error;
    //     }
    // }
    const updateCompanyInformation = async (params) => {
        try {
            return await post(API_UPDATE_COMPANY_USER, {
                id: params.id,
                tenantId: params.tenantId,
                taxCode: params.taxCode,
                companyName: params.companyName,
                business_type: params.business_type,
                address: params.address,
                cardNumber: params.cardNumber,
            })
        } catch (error) {
            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
            return {
                isSuccess: false
            };
        }
    }
    const findService = async (params) => {
        try {
            const response = await post(API_FIND_SERVICE_USER, {
                userId: params.userId,
            })
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            throw error;
        }
    }
    const findUser = async (userId) => {
        try {
            const response = await post(API_GET_DATA_INIT_DOMAIN, {
                userId: userId,
            })
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            throw error;
        }
    }
    const createTenant = async (params) => {
        try {
            const response = await post(API_CREATE_TENANT, params)
            if (response.isSuccess) {
                return response?.data?.message
            }
        } catch (error) {
            throw error;
        }
    }
    const stopDomain = async (params) => {
        try {
            const response = await post(API_STOP_TENANT, params)
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            throw error;
        }
    }
    const updateUserConfig = async (params) => {
        try {
            const response = await put(API_UPDATE_USER_CONFIG, {
                userId: params.userId,
                serviceId: params.serviceId,
                activeMembersActual: params.activeMembersActual,
                createdMembersActual: params.createdMembersActual,
            })
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            throw error;
        }
    }
    const getUserInfo = async (userId) => {
        try {
            const response = await post(API_POST_USER_INFO, {
                userId: userId,
            })
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            throw error;
        }
    }

    /** Update permission + sub config when change to other subscription by tenant */
    const updatePermissionMenu = async (userId) => {
        try {
            const response = await post(API_POST_USER_INFO, {
                userId: userId,
            })
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            throw error;
        }
    }

    return {
        listUser,
        getUserInfo,
        listUserWithService,
        create,
        update,
        findUser,
        createTenant,
        lock,
        removeService,
        reOpen,
        stopDomain,
        addService,
        findService,
        updateUserConfig,
        requestAddService,
        updateCompanyInformation,
        listUserRequestService
        // changeUserPackage,
        // extendUserPackage
    }
}

export function UserObserver(props) {
    const {
        setMode,
        mode,
        setOpenModal,
        setPage,
        currenServiceUser,
        setCurrenServiceUser,
        setSize,
    
        setParams,
        setLoading,
        setOkText,
        selectedRowKeys,
        setTypeModal,
        typeModal,
        setSelectedRowKeys,
        currenEdit,
        setCurrenEdit,

    } = props;

    const usecase = UserUseCase();
    let useCaseService = PackageUseCase();
    const onSelectChangeCheckBox = (e) => {
        setSelectedRowKeys(e);
    };
    const create = () => {
        setMode(ACTION_FORM_ADD);
        setTypeModal(TYPE_FORM_CREATE_USER);
        setOpenModal(true);
        setOkText("Thêm mới");
    }
    const getRowSelection = () => {
        return {
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChangeCheckBox,
        };
    }
    const refresh = () => {
        // setParams({...params});
    }

    const resetFilter = () => {
        setParams({
            packetExpiredDate: localStorage.getItem("packetExpiredDate") || 7
        });
        setPage(DEFAULT_PAGE);
        setSize(MAX_RESULTS);
    }
    const onCancel = () => {

        setOpenModal(false)
        setTypeModal(TYPE_FORM_CREATE_USER);
        setCurrenEdit(null);
    }
    const handleChangInput = (value, key,params) => {
      
        setPage(1);
        setParams({
            ...params,
            [key]: value,
        });
    };
    const handleChangDatePicker = (date, dateString, key,params) => {
        setParams({
            ...params,
            [key[0]]: dateString[0],
            [key[1]]: dateString[1],
        });

    };
    const getPropsInputSelect = (params) => {
        return {
            useCaseService,
            handleChangInput:(value, key)=>{
                handleChangInput(value, key,params)
            },
            handleChangDatePicker:(date, dateString, key)=>{
                handleChangDatePicker(date, dateString, key,params)
            },
        };
    }
    const getDataSource = async (params) => {
        return await usecase.listUserWithService(params)
    }

    const getDataTable = async (params) => {
        return await usecase.listUserRequestService(params)
    }
    const getPropsColumn = () => {
        return {
            handleEdit: (row) => {
                setMode(ACTION_FORM_EDIT);
                setTypeModal(TYPE_FORM_CREATE_USER);
                setOpenModal(true);
                setCurrenEdit(row)
                setOkText("Cập nhật");
            },
            handleConfirmDelete: (row) => {
                usecase.lock(row?.id)
                    .then(function (response) {
                        if (response.isSuccess) {
                            openNotificationWithIcon(notification, AlertType.success, "Khóa người dùng thành công")
                            refresh();
                        }
                    })
                    .catch(function (error) {
                        if (error.response?.status !== 406) {
                            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                        }
                    });
            },
            handleAddService: (row) => {
                usecase.findService({
                    userId: row.id
                }).then((res) => {
                    if (res?.isFound) {
                        setCurrenServiceUser(res);
                        setMode(ACTION_FORM_EDIT);
                        setOkText("Cập nhật gói");
                        setOkText("Thêm mới gói");
                        setTypeModal(TYPE_FORM_ADD_SERVICE_USER);
                        setOpenModal(true);
                        setCurrenEdit(row);
                    } else {
                        setCurrenServiceUser(null);
                        setMode(ACTION_FORM_ADD);
                        setOkText("Thêm mới gói");
                        setTypeModal(TYPE_FORM_ADD_SERVICE_USER);
                        setOpenModal(true);
                        setCurrenEdit(row);
                    }
                }).catch((error) => {
                    openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")

                })
            },
            handleReOpen: (row) => {

                usecase.reOpen(row?.id, row?.serviceId)
                    .then(function (response) {
                        if (response.isSuccess) {
                            openNotificationWithIcon(notification, AlertType.success, "Mở khóa người dùng thành công")
                            refresh();
                        }
                    })
                    .catch(function (error) {
                        if (error.response?.status !== 406) {
                            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                        }
                    });
            },
            handleStopDomain: (row) => {
                usecase.stopDomain({
                    userId: row?.id,
                    tenantId: row?.tenantId
                })
                    .then(function (response) {
                        openNotificationWithIcon(notification, AlertType.success, "Ngừng Domain thành công")
                        refresh();
                    })
                    .catch(function (error) {
                        if (error.response?.status !== 406) {
                            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                        }
                    });
            },
            handleRemoveService: (row) => {

                usecase.removeService(row?.id, row?.serviceId)
                    .then(function (response) {
                        if (response.isSuccess) {
                            openNotificationWithIcon(notification, AlertType.success, "Gỡ gói thành công")
                            refresh();
                        }
                    })
                    .catch(function (error) {
                        if (error.response?.status !== 406) {
                            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                        }
                    });
            },
            handleOpenModalInitDomain: (row) => {
                setLoading(true)
                usecase.findUser(row.id)
                    .then(function (response) {
                        usecase.createTenant(response).then(function (res) {
                            openNotificationWithIcon(notification, AlertType.success, res)
                        }).catch(function (error) {
                            if (error.response?.status !== 406) {
                                openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                            }
                        }).finally(
                            () => {
                                setLoading(false)
                            }
                        );

                    })
                    .catch(function (error) {
                        setLoading(false)
                        if (error.response?.status !== 406) {
                            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                        }
                    });

            },
            handleUpdatePermissionMenu: (row) => {
                setLoading(true);
                usecase.updateUserConfig(
                    {
                        userId: row.id,
                        serviceId: row.serviceId,
                        createdMembersActual: row.maxCreatedMembers,
                        activeMembersActual: row.maxActiveMembers,
                    }
                ).then(() => {
                    openNotificationWithIcon(notification, AlertType.success, "Cập nhật thiết lập thành công");
                    refresh();
                    onCancel();
                }).catch(function (error) {
                    if (error.response?.status !== 406) {
                        openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                    }
                }).finally(
                    () => {
                        setLoading(false)
                    }
                );
            },
        }
    }
    const getDataModal = () => {

        return {
            mode,
            currenEdit,
            useCaseService,
            currenServiceUser,
            submit: (values) => {
                switch (typeModal) {
                    case TYPE_FORM_CREATE_USER:
                    default:
                        if (window.tinymce != undefined) {
                            let Description = window.tinymce.get("description");
                            values.description = Description?.getContent({format: "html"}) || "";
                        } else {
                            values.description = "";
                        }
                        if (mode === ACTION_FORM_ADD) {
                            usecase.create(values)
                                .then(function (response) {
                                    if (response.isSuccess) {
                                        openNotificationWithIcon(notification, AlertType.success, "Tạo người dùng thành công")
                                        refresh();
                                        onCancel();
                                    }
                                })
                                .catch(function (error) {
                                    if (error.response?.status !== 406) {
                                        openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                                    }
                                });
                        } else if (mode === ACTION_FORM_EDIT && currenEdit) {
                            usecase.update(values, currenEdit)
                                .then(function (response) {
                                    if (response.isSuccess) {
                                        openNotificationWithIcon(notification, AlertType.success, "Cập nhật người dùng thành công")
                                        refresh();
                                        onCancel();
                                    }
                                })
                                .catch(function (error) {
                                    if (error.response?.status !== 406) {
                                        openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                                    }
                                });
                        }
                        break;
                    case TYPE_FORM_ADD_SERVICE_USER:
                        usecase.addService(values)
                            .then(function (response) {
                                if (response.isSuccess) {
                                    usecase.updateUserConfig(
                                        {
                                            userId: response.data?.userId,
                                            serviceId: response.data?.serviceId,
                                            activeMembersActual: values.activeMembersActual,
                                            createdMembersActual: values.createdMembersActual,
                                        }
                                    ).then(() => {
                                        openNotificationWithIcon(notification, AlertType.success, "Tạo gói cho người dùng thành công");
                                        refresh();
                                        onCancel();
                                    }).catch(function (error) {
                                        if (error.response?.status !== 406) {
                                            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                                        }
                                    });
                                }
                            })
                            .catch(function (error) {
                                if (error.response?.status !== 406) {
                                    openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                                }
                            });
                        break;
                }

            }
        }
    }

    const getUserInfo = (userId) => {
        return usecase.getUserInfo(userId).then(function (response) {
            return response;
        })
            .catch(function (error) {

                if (error.response?.status !== 406) {
                    openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                }

            });
    }
    return {
        getPropsColumn,
        getUserInfo,
        getDataSource,
        getRowSelection,
        create,
        refresh,
        resetFilter,
        onCancel,
        getDataModal,
        getPropsInputSelect,
        getDataTable
    }
}