import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Radio, Form, Input, notification, message } from "antd";
import { useMediaQuery } from "react-responsive";

import Modal from "../../../components/modal/modal-card";
import IconSuccess from "../../../components/svg/icon-success";
import {
  DATE_FORMAT_CREATE,
  KEY_USER_LOGIN,
} from "../../../constants/apiConstants";
import { UserUseCase } from "../../../observer/ueser/UserObserver";
import {
  AlertType,
  openNotificationWithIcon,
} from "../../../utils/AlertMessage";
import ButtonCustomize from "../../../components/button";
import { BtnTypeEnum } from "../../../components/button/const";
import { APP_CONST, STATUS_REQUEST } from "../../../constants/enum";
import { MODAL_FORM_TYPE } from "../../constants";
import "./index.scss";
import PackageUserCommon from "../components/package";
import { useUser } from "../useUser";
import { useSelector } from "react-redux";
import {
  packageListSelector,
  packageUsingSelector,
} from "../../../redux/slice/user/userSelector";
import { formatInputMoney } from "../../../utils/helper";
import dayjs from "dayjs";
import { UserObserver } from "../../../observer/ueser/UserObserver";

function Packages() {
  const { Search } = Input;
  const { getUserPackageUsingHandle, getListPackage } = useUser();
  const [formType, setFormType] = useState("business");
  const usePackage = useSelector(packageUsingSelector);
  const packageList = useSelector(packageListSelector) || [];
  const [userPkg, setUserPkg] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [modalForm, setModalForm] = useState({
    isOpen: false,
    title: "",
    type: MODAL_FORM_TYPE.EXTEND, // 0: Gia han, 1: Thay doi, 2: "Đăng ký gói", 3: "Cập nhật thông tin DN", 4: "Success"
    btn_hidden: false,
    btn_container: "",
    btn_success: "",
    successTitle: "",
    successContent:
      "Bộ phận chăm sóc khách hàng sẽ liên hệ với bạn sau khi đã xác nhận thông tin.\n" +
      "Thời gian xác nhận tối thiểu  từ 2 - 4 tiếng  trong giờ hành chính từ  Thứ2 - Thứ 7 hàng tuần (trừ ngày nghỉ, lễ tết...)",
  });
  const usecase = UserUseCase();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [pkgPriceId, setPkgPriceId] = useState(null);
  const [form] = Form.useForm();
  const [packageSelected, setPackageSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  let userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN));
  let observer = UserObserver({});

  console.log("usePackage", usePackage);

  useEffect(() => {
    getUserPackageUsingHandle();
    getListPackage({
      pageNumber: page,
      pageSize: size,
    });
    if (userLocal) {
      observer.getUserInfo(userLocal.id).then((res) => {
        setUserPkg(res);
      });
    }
  }, []);

  const fetchBusinessInfo = async (taxCode) => {
    if (taxCode) {
      setLoading(true);
      try {
        const response = await fetch(
          `https://api.vietqr.io/v2/business/${taxCode}`
        );
        const data = await response.json();
        if (data && data.data) {
          form.setFieldsValue({
            companyName: data.data.name,
            address: data.data.address,
          });
          message.success("Tra cứu thông tin doanh nghiệp thành công!");
        } else {
          message.error("Không tìm thấy thông tin doanh nghiệp.");
        }
      } catch (error) {
        message.error("Có lỗi xảy ra khi tra cứu mã số thuế.");
      } finally {
        setLoading(false);
      }
    }
  };

  const apiPackage = usePackage?.service;
  const itemId = packageList.find((item) => item.id === apiPackage?.id);

  let days = "",
    startDay = "",
    exitDay = "";
  if (usePackage) {
    startDay = usePackage?.startAt
      ? dayjs(usePackage.startAt).format(DATE_FORMAT_CREATE)
      : "";
    if (!usePackage.service?.packageList) {
      days = APP_CONST.NO_LIMIT_TIME;
      exitDay = APP_CONST.NO_LIMIT_TIME;
    } else {
      exitDay = usePackage?.expiredAt
        ? dayjs(usePackage.expiredAt).format(DATE_FORMAT_CREATE)
        : "";
      // days = usePackage.expiredAt
      //     ? `${Math.round(
      //         moment
      //             .duration(moment(usePackage.expiredAt) - moment().startOf("day"))
      //             .asDays())} Ngày`
      //     : "";
      days = usePackage.expiredAt
        ? `${Math.max(
            0,
            dayjs(usePackage.expiredAt).diff(dayjs(), "day")
          )} Ngày`
        : "";
    }
  }

  const onRadioChange = (e) => {
    setFormType(e.target.value);
    form.resetFields();
  };

  // let days = usePackage?.expiredAt
  //   ? Math.round(
  //       moment
  //         .duration(moment(usePackage.expiredAt) - moment().startOf("day"))
  //         .asDays()
  //     )
  //   : 0;
  // let startDay = usePackage?.startAt
  //   ? moment(usePackage.startAt).format(DATE_FORMAT_CREATE)
  //   : "";
  // let exitDay = usePackage?.expiredAt
  //   ? moment(usePackage.expiredAt).format(DATE_FORMAT_CREATE)
  //   : "";

  let contents = [
    {
      item: "Tên gói:",
      content_modal: apiPackage ? apiPackage.name : "",
    },
    {
      item: "Thời gian còn lại:",
      content_modal: `${days}`,
    },
    {
      item: "Ngày đăng ký:",
      content_modal: startDay,
    },
    {
      item: "Ngày hết hạn:",
      content_modal: exitDay,
    },
  ];

  const handleCancel = () => {
    setModalForm((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setPackageSelected(null);
  };

  const onChange = (e) => {
    setPkgPriceId(e.target.value);
  };

  const register = (packageItem) => () => {
    setPackageSelected(packageItem);
    setModalForm((prev) => ({
      ...prev,
      isOpen: true,
      type:
        !userPkg?.tenantId?.length > 0
          ? MODAL_FORM_TYPE.UPDATE_COMPANY
          : MODAL_FORM_TYPE.REGIS,
      btn_success: "Xác nhận, Cập nhật",
      btn_container: "Nhập lại",
    }));
  };

  const onRegisterFinish = (values) => {
    usecase
      .updateCompanyInformation({ ...values, id: userLocal.id })
      .then(function (response) {
        if (response.isSuccess) {
          openNotificationWithIcon(
            notification,
            AlertType.success,
            "Cập nhật doanh nghiệp thành công"
          );
          setModalForm((prev) => ({
            ...prev,
            isOpen: true,
            type: MODAL_FORM_TYPE.REGIS,
            btn_success: "Xác nhận, Cập nhật",
            btn_container: "Nhập lại",
          }));
        }
      })
      .catch(function (error) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          error?.response?.data?.message ||
            "Có lỗi xảy ra. Vui lòng thử lại sau"
        );
      });
  };

  const onPackageSelected = (packageItem) => () => {
    setPackageSelected(packageItem);
  };

  const onSearch = (value) => {
    fetchBusinessInfo(value);
  };

  const renderModal = () => {
    return (
      <>
        {modalForm.type === MODAL_FORM_TYPE.EXTEND && (
          <>
            <div className="title-package">Gia hạn gói CRM360</div>
            <div className="title-use">Gói đang sử dụng</div>
            <div className="body-use">
              {contents.map((content) => (
                <div className="modal-box">
                  <span className="item-use">{content.item}</span>
                  <span className="content-use">{content.content_modal}</span>
                </div>
              ))}
            </div>
            <div className="title-use">Thời hạn gia hạn thêm</div>
            <div className="box-radio ">
              <Radio.Group onChange={onChange} value={pkgPriceId}>
                {usePackage?.packageList?.map((itemPrice) => (
                  <Radio value={itemPrice.id}>{itemPrice.duration} ngày</Radio>
                ))}
              </Radio.Group>
            </div>
            <div className="content-body ">
              Sau khi bạn xác nhận đăng ký gói Crm360, bộ phận chăm sóc khách
              hàng sẽ liên hệ với bạn để xác minh và trao đổi về quy trình, thủ
              tục và làm hợp đồng.
            </div>
          </>
        )}

        {modalForm.type === MODAL_FORM_TYPE.CHANGE && (
          <>
            <div className="title-package">Thay đổi gói CRM360</div>
            <div className="title-use">Gói đang sử dụng</div>
            <div className="body-use">
              {contents.map((content) => (
                <div className="modal-box">
                  <span className="item-use">{content.item}</span>
                  <span className="content-use">{content.content_modal}</span>
                </div>
              ))}
            </div>
            <div className="title-use">Lựa chọn gói để thay đổi</div>
            <div className="box-option">
              {packageList.map((itemSub) => (
                <button
                  onClick={onPackageSelected(itemSub)}
                  disabled={itemSub.id === usePackage.id}
                  className={`btn-option ${
                    itemSub.id === usePackage.id ? "btn-option-disable" : ""
                  } ${
                    itemSub.id === packageSelected?.id
                      ? "btn-option-selected"
                      : ""
                  }`}
                >
                  {itemSub.name}
                </button>
              ))}
            </div>
            {packageSelected?.packageList?.length > 0 && (
              <>
                <div className="title-use">Thời hạn sử dụng</div>
                <div className="box-radio ">
                  <Radio.Group onChange={onChange} value={pkgPriceId}>
                    {packageSelected?.packageList?.map((itemPrice) => (
                      <Radio value={itemPrice.id}>
                        {itemPrice.duration} ngày
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              </>
            )}
            <div className="content-body">
              Sau khi bạn xác nhận thay đổi gói Crm360, bộ phận chăm sóc khách
              hàng sẽ liên hệ với bạn để xác minh và trao đổi về quy trình, thủ
              tục và làm hợp đồng.
            </div>
          </>
        )}

        {modalForm.type === MODAL_FORM_TYPE.UPDATE_COMPANY && (
          <>
            <div className="title-package">Cập nhật thông tin doanh nghiệp</div>
            <div className="content-body">
              Hãy đảm bảo những thông tin cập nhật được chính xác, việc này sẽ
              giúp đội ngũ tư vấn hỗ trợ bạn được được tốt nhất.
            </div>
            <Radio.Group
              className="radio-button"
              onChange={onRadioChange}
              value={formType}
            >
              <Radio value="business">Doanh nghiệp</Radio>
              <Radio value="personal">Cá nhân</Radio>
            </Radio.Group>

            {formType === "business" && (
              <Form
                name="basic"
                onFinish={onRegisterFinish}
                autoComplete="off"
                layout="vertical"
                className="card-form"
                form={form}
              >
                <Form.Item
                  label={<div className="title-item">Tên miền</div>}
                  name="tenantId"
                  rules={[
                    { required: true, message: "Vui lòng nhập tên miền" },
                    {
                      pattern: /^[a-z0-9]+$/,
                      message:
                        "Tên miền không được viết hoa và chứa ký tự đặc biệt",
                    },
                  ]}
                >
                  <Input
                    addonBefore="http://"
                    addonAfter={`.${process.env.REACT_APP_DOMAIN_TENANT}`}
                    placeholder="Nhập vào"
                  />
                </Form.Item>
                <Form.Item
                  label={<div className="title-item">Mã số thuế</div>}
                  name="taxCode"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mã số thuế",
                    },
                  ]}
                >
                  <Search
                    placeholder="Nhập mã số thuế"
                    allowClear
                    enterButton="Tra cứu"
                    size="large"
                    onSearch={onSearch}
                    loading={loading}
                  />
                </Form.Item>
                <Form.Item
                  label={<div className="title-item">Tên doanh nghiệp</div>}
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập tên doanh nghiệp",
                    },
                  ]}
                >
                  <Input className="card-input" placeholder="Nhập vào" />
                </Form.Item>
                <div className="item-domain">
                  Đây là đường dẫn tên miền mà bạn sẽ quản trị, ví dụ
                  http://name.
                  {`.${process.env.REACT_APP_DOMAIN_TENANT}`}
                </div>
                <Form.Item
                  label={<div className="title-item">Địa chỉ kinh doanh</div>}
                  name="address"
                >
                  <Input className="card-input" placeholder="Nhập vào" />
                </Form.Item>
                <Form.Item
                  label={<div className="title-item">Lĩnh vực kinh doanh</div>}
                  name="business_type"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập lĩnh vực kinh doanh",
                    },
                  ]}
                >
                  <Input className="card-input" placeholder="Nhập vào" />
                </Form.Item>
              </Form>
            )}

            {formType === "personal" && (
              <Form
                name="basic"
                onFinish={onRegisterFinish}
                autoComplete="off"
                layout="vertical"
                className="card-form"
                form={form}
              >
                <Form.Item
                  label={<div className="title-item">Tên miền</div>}
                  name="tenantId"
                  rules={[
                    { required: true, message: "Vui lòng nhập tên miền" },
                    {
                      pattern: /^[a-z0-9]+$/,
                      message:
                        "Tên miền không được viết hoa và chứa ký tự đặc biệt",
                    },
                  ]}
                >
                  <Input
                    addonBefore="http://"
                    addonAfter={`.${process.env.REACT_APP_DOMAIN_TENANT}`}
                    placeholder="Nhập vào"
                  />
                </Form.Item>
                <div className="item-domain">
                  Đây là đường dẫn tên miền mà bạn sẽ quản trị, ví dụ
                  http://name.
                  {`.${process.env.REACT_APP_DOMAIN_TENANT}`}
                </div>
                <Form.Item
                  label={<div className="title-item">Căn cước công dân</div>}
                  name="cardNumber"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập căn cước công dân",
                    },
                  ]}
                >
                  <Input className="card-input" placeholder="Nhập vào" />
                </Form.Item>
                <Form.Item
                  label={<div className="title-item">Lĩnh vực kinh doanh</div>}
                  name="business_type"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập lĩnh vực kinh doanh",
                    },
                  ]}
                >
                  <Input className="card-input" placeholder="Nhập vào" />
                </Form.Item>
                <Form.Item
                  label={<div className="title-item">Địa chỉ kinh doanh</div>}
                  name="address"
                >
                  <Input className="card-input" placeholder="Nhập vào" />
                </Form.Item>
              </Form>
            )}
          </>
        )}

        {modalForm.type === MODAL_FORM_TYPE.SUCCESS && (
          <div className="container-success">
            <IconSuccess />
            <div className="item-success ">{modalForm.successTitle}</div>
            <div className="content-body">{modalForm.successContent}</div>
          </div>
        )}

        {modalForm.type === MODAL_FORM_TYPE.REGIS && (
          <>
            <div className="title-package">Đăng ký gói</div>
            <div className="modal-item">
              Lựa chọn gói phù hợp với doanh nghiệp của bạn.
            </div>
            <div className="title-use">Lựa chọn gói</div>
            <div className="box-option">
              {packageList?.map((itemSub) => (
                <button
                  onClick={() => {
                    setPackageSelected(itemSub);
                  }}
                  disabled={itemSub.id === apiPackage?.id}
                  className={`btn-option ${
                    itemSub.id === apiPackage?.id ? "btn-option-disable" : ""
                  } ${
                    itemSub.id === packageSelected?.id
                      ? "btn-option-selected"
                      : ""
                  }`}
                >
                  {itemSub.name}
                </button>
              ))}
            </div>
            <div className="title-use">Thời hạn sử dụng</div>
            <div className="box-radio ">
              <Radio.Group onChange={onChange} value={pkgPriceId}>
                {packageSelected?.packageList?.map((itemPrice) => (
                  <Radio value={itemPrice.id}>{itemPrice.duration} ngày</Radio>
                ))}
              </Radio.Group>
            </div>
            <div className="content-body">
              Sau khi bạn xác nhận thay đổi gói, bộ phận chăm sóc khách hàng sẽ
              liên hệ với bạn để xác minh và trao đổi về quy trình, thủ tục và
              làm hợp đồng.
            </div>
          </>
        )}
      </>
    );
  };

  const renderFooter = () => {
    const onExit = () => {
      setModalForm((prev) => ({
        ...prev,
        isOpen: false,
      }));
      setPackageSelected(null);
    };

    const onConfirmForm = async (onDoneCb) => {
      if (modalForm.type === MODAL_FORM_TYPE.UPDATE_COMPANY) {
        onDoneCb && onDoneCb();
        form.submit();
        return;
      }
      let params = {
        userId: userLocal.id,
        serviceId: packageSelected?.id,
        pkgPriceId: pkgPriceId,
        status: STATUS_REQUEST.WAIT_REGIS_NEW,
      };
      let successTitle = "Thao tác đăng ký gói thành công";
      if (!params.serviceId) {
        onDoneCb && onDoneCb();
        openNotificationWithIcon(
          notification,
          AlertType.error,
          "Vui lòng lựa chọn gói"
        );
        return;
      }

      if (modalForm.type === MODAL_FORM_TYPE.CHANGE) {
        params.status = STATUS_REQUEST.WAIT_CHANGE;
        successTitle = "Thao tác yêu cầu thay đổi gói thành công";
      }

      const resData = await usecase.requestAddService(params);
      onDoneCb && onDoneCb();
      if (resData.isSuccess) {
        setModalForm((prev) => ({
          ...prev,
          type: MODAL_FORM_TYPE.SUCCESS,
          title: "Thông báo",
          btn_hidden: false,
          btn_success: "Hoàn thành",
          successTitle: successTitle,
        }));
      }
    };

    if (modalForm.type === MODAL_FORM_TYPE.UPDATE_COMPANY) {
      return (
        <>
          <ButtonCustomize
            title={"Nhập lại"}
            btnType={BtnTypeEnum.GREY}
            btnSize={44}
            onPressHandle={onExit}
          />
          <ButtonCustomize
            title={"Xác nhận, Cập nhật"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            showLoading={true}
            onPressHandle={onConfirmForm}
          />
        </>
      );
    }

    if (modalForm.type === MODAL_FORM_TYPE.REGIS) {
      return (
        <>
          <ButtonCustomize
            title={"Thoát"}
            btnType={BtnTypeEnum.GREY}
            btnSize={44}
            onPressHandle={onExit}
          />
          <ButtonCustomize
            title={"Xác nhận, Đăng ký"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            showLoading={true}
            onPressHandle={onConfirmForm}
          />
        </>
      );
    }

    // if(modalForm.type === MODAL_FORM_TYPE.EXTEND)
    // {
    //   return <>
    //     <ButtonCustomize
    //         title={"Thoát"}
    //         btnType={BtnTypeEnum.OUTLINE}
    //         btnSize={44}
    //         onPressHandle={onExit}
    //     />
    //     <ButtonCustomize
    //         title={"Xác nhận, gia hạn gói"}
    //         btnType={BtnTypeEnum.GREEN}
    //         btnSize={44}
    //         onPressHandle={onConfirmForm}
    //     />
    //   </>
    // }

    if (modalForm.type === MODAL_FORM_TYPE.CHANGE) {
      return (
        <>
          <ButtonCustomize
            title={"Thoát"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={onExit}
          />
          <ButtonCustomize
            title={"Xác nhận, Thay đổi"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            showLoading={true}
            onPressHandle={onConfirmForm}
          />
        </>
      );
    }

    if (modalForm.type === MODAL_FORM_TYPE.SUCCESS) {
      return (
        <>
          <ButtonCustomize
            title={"Hoàn thành"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={handleCancel}
          />
        </>
      );
    }
  };

  return (
    <>
      {itemId && (
        <div className="container-package">
          <div>
            <div className="title-package">Gói đang sử dụng</div>
            <PackageUserCommon
              packageUser={usePackage}
              apiPackage={apiPackage}
              items={packageList}
            />
          </div>
        </div>
      )}

      <div className="detail-package">
        <div className="title-content">Các gói dịch vụ</div>
        {/*<button className="btn-view">Xem chi tiết các gói</button>*/}
      </div>
      {packageList?.map((item) => {
        let textSub = "Đăng ký gói";
        if (apiPackage) {
          const foundPkg = packageList.find((x) => x.id === apiPackage.id);
          textSub =
            (item.minPrice || 0) >= (foundPkg?.minPrice || 0)
              ? "Nâng cấp gói"
              : "Hạ cấp gói";
        }

        return (
          <div className="card-package" key={`${item.id}`}>
            <div className="title-card item1">
              {item.name}{" "}
              {!isMobile && (
                <>{apiPackage?.id === item?.id && <span>Đang sử dụng</span>}</>
              )}
            </div>
            <div className="body-option item2">
              <div className="content-option">
                <span>{item.description}</span>
              </div>
            </div>
            {!isMobile && <div className="item5"></div>}
            <div className="card-box item3">
              <div className="item-option">
                {item.packageList?.length === 0 ? (
                  <div className="container_free">
                    <div className="card-content">Miễn phí</div>
                  </div>
                ) : (
                  <>
                    {item.packageList?.map((priceItem) => {
                      return (
                        <>
                          {priceItem.discount === 0 && (
                            <>
                              <div className="content-item">
                                <span className="card-content">
                                  {formatInputMoney(priceItem.price_org)}đ
                                </span>
                                <span className="card-body">
                                  / {priceItem.duration} ngày
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
                {item.packageList?.map((priceItem) => {
                  return (
                    priceItem.discount > 0 && (
                      <div className="content-item">
                        <span className="package-content">
                          -{priceItem.discount}%
                        </span>
                        <span className="package-decoration">
                          {formatInputMoney(priceItem.price_org)}đ
                        </span>
                        <span className="card-item">
                          {formatInputMoney(priceItem.price)} đ
                        </span>
                        <span className="card-body">
                          / {priceItem.duration} ngày
                        </span>
                      </div>
                    )
                  );
                })}
              </div>
              {!isMobile && (
                <>{newFunction(apiPackage, item, register, textSub)} </>
              )}
            </div>
            {isMobile && (
              <>{newFunction(apiPackage, item, register, textSub)}</>
            )}
          </div>
        );
      })}

      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        onOpenSuccess={modalForm.onOpenSuccess}
        footerNode={renderFooter}
        // width={modalForm.type === MODAL_FORM_TYPE.CHANGE ? "auto" : null}
      >
        {renderModal()}
      </Modal>
    </>
  );
}

export default withRouter(Packages);

function newFunction(apiPackage, item, register, textSub) {
  return (
    <div className="btn-box item4">
      {apiPackage?.id === item?.id ? (
        <button className="btn-use">Đang sử dụng</button>
      ) : (
        <button className="btn-package" onClick={register(item)}>
          {textSub}
        </button>
      )}
    </div>
  );
}
