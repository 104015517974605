import "./styles.scss";
import { BtnTypeEnum } from "./const";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

/**
 * Button customize style
 * @param title
 * @param isDisabled
 * @param btnType => BtnTypeEnum
 * @param btnSize => BtnSizeEnum
 * @param onPressHandle => onPressHandle
 * @param showLoading => showLoading
 * @return {JSX.Element}
 */
const ButtonCustomize = ({
  title,
  isDisabled = false,
  btnType,
  btnSize = 44,
  onPressHandle,
  showLoading = false,
}) => {
  const [isLoading, setLoading] = useState(false);

  const onClickHandle = (e) => {
    e.preventDefault();

    if (showLoading) {
      setLoading(true);
    }

    onPressHandle &&
      onPressHandle(() => {
        setLoading(false);
      });
  };

  let classBtn = "";
  switch (btnType) {
    case BtnTypeEnum.GREEN:
      classBtn = `btn-customize-${btnSize}-green`;
      break;
    case BtnTypeEnum.BLACK:
      classBtn = `btn-customize-${btnSize}-black`;
      break;
    case BtnTypeEnum.GREY:
      classBtn = `btn-customize-${btnSize}-grey`;
      break;
    case BtnTypeEnum.OUTLINE:
      classBtn = `btn-customize-${btnSize}-outline`;
      break;
    case BtnTypeEnum.WHITE:
      classBtn = `btn-customize-${btnSize}-white`;
      break;
    case BtnTypeEnum.RED:
      classBtn = `btn-customize-${btnSize}-red`;
      break;
    default:
      classBtn = `btn-customize-${btnSize}-border-empty`;
      break;
  }

  return (
    <button
      onClick={onClickHandle}
      className={`btn-customize ${classBtn}`}
      disabled={isLoading || isDisabled}
    >
      {showLoading && (
        <Spin
          style={{ marginRight: 8 }}
          spinning={isLoading}
          indicator={<LoadingOutlined spin />}
          size="default"
        />
      )}
      {title}
    </button>
  );
};

export default ButtonCustomize;
