import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { Form, Input } from "antd";
import "./body-modal.scss";

const InfoUser = forwardRef(({ onSave, initialValues }, ref) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      form
        .validateFields()
        .then((values) => {
          onSave(values); // Gọi hàm onSave từ props
          console.log("Form Data: ", values); // In ra dữ liệu đã lưu
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    },
  }));

  return (
    <>
      <div className="form-register info-user">
        <Form
          className={"form"}
          name="basic"
          layout="vertical"
          autoComplete="off"
          form={form}
        >
          <div className="col-wrap">
            <div className={"form-item"}>
              <Form.Item
                label={
                  <>
                    <div className="title-form">Tài khoản</div>
                  </>
                }
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng điền tài khoản của bạn",
                  },
                  {
                    pattern: /^[a-zA-Z0-9]*$/,
                    message: "Tài khoản không được chứa ký tự đặc biệt, dấu cách hoặc ký tự có dấu.",
                  },
                ]}
              >
                <Input rootClassName={"form-input"} placeholder={"Nhập vào"} />
              </Form.Item>
            </div>
            <div className={"form-item"}>
              <Form.Item
                label={
                  <>
                    <div className="title-form">Mật khẩu</div>
                  </>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng điền mật khẩu của bạn",
                  },
                  {
                    min: 6,
                    message: "Mật khẩu phải đủ 6 ký tự",
                  },
                ]}
              >
                <Input rootClassName={"form-input"} placeholder={"Nhập vào"} />
              </Form.Item>
            </div>
            <div className={"form-item"}>
              <Form.Item
                name="fullName"
                label={
                  <>
                    <div className="title-form">Họ và tên</div>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Vui lòng điền họ tên của bạn",
                    whitespace: true,
                  },
                ]}
              >
                <Input rootClassName={"form-input"} placeholder={"Nhập vào"} />
              </Form.Item>
            </div>
            <div className={"form-item"}>
              <Form.Item
                name="phoneNumber"
                label={
                  <>
                    <div className="title-form">Số điện thoại</div>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại của bạn",
                  },
                  {
                    pattern: /^[\d]*$/,
                    message: "Vui lòng nhập đúng định dạng",
                  },
                ]}
              >
                <Input
                  rootClassName={"form-input-custom"}
                  placeholder={"Nhập vào"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            <div className={"form-item"}>
              <Form.Item
                name="email"
                label={
                  <>
                    <div className="title-form">Email</div>
                  </>
                }
                rules={[
                  {
                    type: "email",
                    message: "Không đúng định dạng E-mail!",
                  },
                  {
                    required: true,
                    message: "Vui lòng điền E-mail!",
                  },
                ]}
              >
                <Input rootClassName={"form-input"} placeholder={"Nhập vào"} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
});
export default InfoUser;
