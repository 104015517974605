import { withRouter } from "react-router-dom";

import Chat from "../../../components/svg/comment";
import Call from "../../../components/svg/phone";
import Email from "../../../components/svg/email";
import "./index.scss";

function UserSupport() {
  let items = [
    {
      icon: <Chat />,
      title: "Tư vấn trực tiếp",
      content:
        "Bạn sẽ trao đổi trực tiếp với tư vấn viên của CRM360 qua hộp thoại bên dưới phía phải màn hình.",
      time: "Khung giờ tư vấn trực tiếp",
      tiem_slot: "09-12h & 14h-17h, từ thứ 2 đến thứ 6",
      text: "Chat Ngay",
    },
    {
      icon: <Call />,
      title: "Tổng đài tư vấn",
      content:
        "Bạn liên hệ với chúng tôi qua số điện thoại cấp dưới đây, tổng đài tư vấn sẽ hỗ trợ mọi câu hỏi & thắc mắc giúp bạn.",
      time: "Khung giờ tư vấn",
      tiem_slot: "09-12h & 14h-17h, từ thứ 2 đến thứ 6",
      text: "Liên hệ ngay",
      show: true,
    },
    {
      icon: <Email />,
      title: "Liên hệ qua email",
      content:
        "Vui lòng nhập nội dung câu hỏi cần tu vấn vào form liên hệ và gửi cho chúng tôi, bộ phận CSKH sẽ check & phản hồi bạn.",
      time: "Thời gian phản hồi",
      tiem_slot: "Phản hồi 2-4h, từ thứ 2 đến thứ 6",
      text: "Liên hệ ngay",
    },
  ];
  return (
      <div>
        <div className="title-sp">Hỗ trợ khách hàng</div>
        <div className="box-card">
          {items?.map((item) => (
            <div className="container-chat">
              {item.icon}
              <div className="content-comment">{item.title}</div>
              <div className="box-content">{item.content}</div>
              <div className="body-card">{item.time}</div>
              <div className="time-slot">{item.tiem_slot}</div>
              {item.show ? (
                <>
                  <button className={"btn-phone"} type={"submit"}>
                    0352 310 777
                  </button>
                  <button className={"btn-phone-2"} type={"submit"}>
                    0907 711 888
                  </button>
                </>
              ) : (
                <button className={"btn-chat"} type={"submit"}>
                  {item.text}
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="title-box">Thông tin tham khảo</div>
        <div className="box-card">
          <ContainerSection content="Hướng dẫn sử dụng" />
          <ContainerSection content="Hướng dẫn đăng ký" />
          <ContainerSection content="Tin tức và thông báo" />
          <ContainerSection content="Tài liệu về Crm360" />
        </div>
      </div>
  );
}

function ContainerSection({ content }) {
  return (
    <div className="box-chat">
      <div className="user-manual">{content}</div>
      <button className={"btn-detail"} type={"submit"}>
        Xem chi tiết
      </button>
    </div>
  );
}

export default withRouter(UserSupport);
