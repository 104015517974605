import {API_BASE_URL} from "./apiConstants";

export const API_REFRESH_TOKEN = `${API_BASE_URL}auth/refresh`
export const API_CREATE_SERVICE = `${API_BASE_URL}service/create`
export const API_UPDATE_SERVICE = `${API_BASE_URL}service/update`
export const API_DEL_SERVICE = `${API_BASE_URL}service/delete`
export const API_LIST_SERVICE = `${API_BASE_URL}service/list`
export const API_LIST_PERMISSION_OF_SERVICE = `${API_BASE_URL}service/all-permission`
export const API_LIST_PERMISSION_ITEM_SERVICE = `${API_BASE_URL}service/permission`
export const API_UPDATE_PERMISSION_ITEM_SERVICE = `${API_BASE_URL}service/permission-update`
//User
export const API_LIST_USER = `${API_BASE_URL}user/list`
export const API_LIST_REQUEST = `${API_BASE_URL}user/list-request`
export const API_LIST_WITH_SERVICE_USER = `${API_BASE_URL}user/list-service`
export const API_CREATE_USER = `${API_BASE_URL}user/create`
export const API_EDIT_USER = `${API_BASE_URL}user/update`
export const API_LOCK_USER = `${API_BASE_URL}user/lock`
export const API_REMOVE_SERVICE_USER = `${API_BASE_URL}user/remove-service`
export const API_RE_OPEN_USER = `${API_BASE_URL}user/re-open`
export const API_ADD_SERVICE_USER = `${API_BASE_URL}user/add-service`
export const API_UPDATE_COMPANY_USER = `${API_BASE_URL}user/update-company`
export const API_SEND_REQUEST_SERVICE_USER = `${API_BASE_URL}user/request-package-approval`
export const API_CHANGE_PACKAGE_USER = `${API_BASE_URL}user/change-package`
export const API_EXTEND_PACKAGE_USER = `${API_BASE_URL}user/extend-package`
export const API_FIND_SERVICE_USER = `${API_BASE_URL}user/find-service`
export const API_UPDATE_USER_CONFIG = `${API_BASE_URL}user/update-config`
export const API_GET_DATA_INIT_DOMAIN = `${API_BASE_URL}user/init-domain`
export const API_CREATE_TENANT = `${API_BASE_URL}tenant`
export const API_STOP_TENANT = `${API_BASE_URL}tenant/stop-service`

export const API_POST_USER_INFO= `${API_BASE_URL}user/info`
