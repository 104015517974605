import React, { useState } from "react";
import { Table, Button, Popover } from "antd";
import "./table.scss";

const TableComponent = (props) => {
  const {
    // dataColumns,
    // indexBool = true,
    dataSource,
    columns,
    setPage,
    setSize,
    scrollSize,
    loading,
    pageSize,
    totalCount,
    page,
    ExpandedTable,
    bordered = false,
    pagination = true,
    rowSelection = false,
    ...rest
  } = props;
  // const newDataSource = dataSource?.map((item: any, index: any) => {
  //     return {
  //         ...item,
  //         key: indexBool ? index : item?.Id,
  //     };
  // });
  // const defaultCheckedList = dataColumns.map((item: any) => String(item.dataIndex));
  // const [columns, setColumns] = useState([]);

  return (
    <div className="table-component">
      {/*{tool && <div style={*/}
      {/*    {*/}
      {/*        display: "flex",*/}
      {/*        justifyContent: "end",*/}
      {/*        padding:"5px"*/}
      {/*    }*/}
      {/*}>*/}
      {/*    <Popover placement="rightBottom" title={*/}
      {/*        <div style={{display: "flex", justifyContent: "end"}}>*/}
      {/*            <Button type={"link"} className={"flex items-center"} onClick={() => {*/}
      {/*                localStorage.removeItem(localStorageColumn + "_sort")*/}
      {/*                localStorage.removeItem(localStorageColumn)*/}
      {/*                if (localStorageColumn) {*/}
      {/*                    localStorage.setItem(localStorageColumn, JSON.stringify(defaultCheckedList))*/}
      {/*                }*/}
      {/*                setColumns(dataColumns.filter((item: any) => {*/}
      {/*                    return defaultCheckedList.includes(String(item.dataIndex)) ||*/}
      {/*                        !item.dataIndex;*/}
      {/*                }));*/}
      {/*            }}>*/}
      {/*                Mặc định*/}
      {/*            </Button>*/}
      {/*        </div>}>*/}
      {/*        <Button shape="circle" icon={<TableOutlined/>}/>*/}
      {/*    </Popover>*/}
      {/*</div>}*/}
      <Table
        locale={{
          triggerDesc: "Bấm vào để sắp xếp",
          triggerAsc: "Bấm vào để sắp xếp",
          cancelSort: "Bấm vào để sắp xếp",
        }}
        expandable={{ expandedRowRender: ExpandedTable }}
        rowSelection={rowSelection}
        className="table_customer"
        columns={columns}
        dataSource={dataSource}
        pagination={
          pagination
            ? {
                onChange: (value) => setPage(value),
                onShowSizeChange(current, size) {
                    setSize(size);
                },
                pageSizeOptions: ["10", "20", "30", "50", "100"],
                defaultPageSize: pageSize,
                showSizeChanger: true,
                pageSize: pageSize,
                current: page,
                total: totalCount,
              }
            : false
        }
        loading={loading}
        scroll={scrollSize}
        bordered={bordered}
        // summary={(pageData) => summary != undefined ? summary(pageData) : undefined}
        {...rest}
      />
    </div>
  );
};
export default TableComponent;
