const Call = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5203 24.824C23.5203 25.1846 23.4401 25.5552 23.2699 25.9158C23.0996 26.2764 22.8792 26.6169 22.5888 26.9375C22.0979 27.4784 21.5571 27.869 20.9461 28.1194C20.3451 28.3698 19.694 28.5 18.9929 28.5C17.9712 28.5 16.8794 28.2596 15.7275 27.7688C14.5757 27.278 13.4238 26.6169 12.2819 25.7856C11.13 24.9442 10.0382 24.0127 8.99653 22.981C7.96485 21.9393 7.03332 20.8475 6.20197 19.7057C5.38062 18.5638 4.71954 17.4219 4.23876 16.2901C3.75797 15.1482 3.51758 14.0564 3.51758 13.0147C3.51758 12.3336 3.63777 11.6825 3.87817 11.0816C4.11856 10.4706 4.49918 9.90964 5.03005 9.40882C5.6711 8.77779 6.37224 8.46729 7.11346 8.46729C7.39391 8.46729 7.67437 8.52738 7.92478 8.64758C8.18521 8.76778 8.41558 8.94807 8.59588 9.2085L10.9197 12.4839C11.1 12.7343 11.2302 12.9646 11.3203 13.185C11.4105 13.3953 11.4606 13.6057 11.4606 13.796C11.4606 14.0364 11.3904 14.2768 11.2502 14.5072C11.12 14.7375 10.9297 14.9779 10.6893 15.2183L9.92806 16.0096C9.81788 16.1198 9.76779 16.25 9.76779 16.4103C9.76779 16.4904 9.77781 16.5605 9.79784 16.6406C9.82789 16.7208 9.85794 16.7809 9.87797 16.841C10.0583 17.1715 10.3688 17.6022 10.8095 18.1231C11.2602 18.6439 11.741 19.1748 12.2619 19.7057C12.8028 20.2365 13.3236 20.7273 13.8545 21.1781C14.3753 21.6188 14.806 21.9193 15.1466 22.0996C15.1967 22.1196 15.2568 22.1496 15.3269 22.1797C15.407 22.2097 15.4871 22.2198 15.5773 22.2198C15.7476 22.2198 15.8778 22.1597 15.988 22.0495L16.7492 21.2983C16.9996 21.0478 17.24 20.8575 17.4704 20.7373C17.7008 20.5971 17.9311 20.527 18.1815 20.527C18.3719 20.527 18.5722 20.5671 18.7925 20.6572C19.0129 20.7474 19.2433 20.8776 19.4937 21.0478L22.8091 23.4017C23.0695 23.582 23.2498 23.7923 23.36 24.0427C23.4602 24.2932 23.5203 24.5436 23.5203 24.824Z"
        fill="#2DC56A"
      />
      <path
        d="M28.4842 22.0568C28.4842 22.4659 28.3933 22.8864 28.2001 23.2955C28.0069 23.7045 27.7569 24.0909 27.4274 24.4545C26.8706 25.0682 26.2569 25.5114 25.5637 25.7955C24.8819 26.0795 24.1433 26.2273 23.3478 26.2273C22.1887 26.2273 20.9501 25.9545 19.6433 25.3977C18.3365 24.8409 17.0297 24.0909 15.7342 23.1477C14.4274 22.1932 13.1887 21.1364 12.0069 19.9659C10.8365 18.7841 9.77965 17.5455 8.83647 16.25C7.90465 14.9545 7.15465 13.6591 6.6092 12.375C6.06374 11.0795 5.79102 9.84091 5.79102 8.65909C5.79102 7.88636 5.92738 7.14773 6.20011 6.46591C6.47283 5.77273 6.90465 5.13636 7.50692 4.56818C8.2342 3.85227 9.02965 3.5 9.87056 3.5C10.1887 3.5 10.5069 3.56818 10.791 3.70455C11.0865 3.84091 11.3478 4.04545 11.5524 4.34091L14.1887 8.05682C14.3933 8.34091 14.541 8.60227 14.6433 8.85227C14.7456 9.09091 14.8024 9.32954 14.8024 9.54545C14.8024 9.81818 14.7228 10.0909 14.5637 10.3523C14.416 10.6136 14.2001 10.8864 13.9274 11.1591L13.0637 12.0568C12.9387 12.1818 12.8819 12.3295 12.8819 12.5114C12.8819 12.6023 12.8933 12.6818 12.916 12.7727C12.9501 12.8636 12.9842 12.9318 13.0069 13C13.2115 13.375 13.5637 13.8636 14.0637 14.4545C14.5751 15.0455 15.1206 15.6477 15.7115 16.25C16.3251 16.8523 16.916 17.4091 17.5183 17.9205C18.1092 18.4205 18.5978 18.7614 18.9842 18.9659C19.041 18.9886 19.1092 19.0227 19.1887 19.0568C19.2797 19.0909 19.3706 19.1023 19.4728 19.1023C19.666 19.1023 19.8137 19.0341 19.9387 18.9091L20.8024 18.0568C21.0865 17.7727 21.3592 17.5568 21.6206 17.4205C21.8819 17.2614 22.1433 17.1818 22.4274 17.1818C22.6433 17.1818 22.8706 17.2273 23.1206 17.3295C23.3706 17.4318 23.6319 17.5795 23.916 17.7727L27.6774 20.4432C27.9728 20.6477 28.1774 20.8864 28.3024 21.1705C28.416 21.4545 28.4842 21.7386 28.4842 22.0568Z"
        stroke="#292D34"
        stroke-width="1.4"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default Call;
