const ElipseTop = () => {
return <svg width="350" height="259" viewBox="0 0 350 259" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M671.351 -201.245C724.57 -2.62802 460.906 185.659 254.709 240.91C48.5116 296.16 86.5172 222.281 33.2981 23.6641C-19.921 -174.952 -32.0018 -282.505 174.196 -337.755C380.393 -393.006 618.131 -399.861 671.351 -201.245Z" fill="url(#paint0_linear_1014_5716)"/>
    <defs>
        <linearGradient id="paint0_linear_1014_5716" x1="3.09066" y1="371.443" x2="508.881" y2="-346.922" gradientUnits="userSpaceOnUse">
            <stop stop-color="#22E16F"/>
            <stop offset="1" stop-color="#477AFF"/>
        </linearGradient>
    </defs>
</svg>
}

export default ElipseTop;