const IconDelete = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0147 20.6682C10.8402 20.6682 9.6951 20.6552 8.56215 20.6317C7.11281 20.6031 6.10988 19.6635 5.94605 18.1795C5.673 15.7177 5.20578 9.9151 5.20144 9.85702C5.17197 9.49902 5.43895 9.18522 5.79696 9.15662C6.14976 9.14708 6.46875 9.395 6.49736 9.75213C6.50169 9.81108 6.96805 15.5937 7.2385 18.0365C7.33125 18.8799 7.78634 19.315 8.58902 19.3315C10.7561 19.3774 12.9674 19.38 15.3512 19.3367C16.2042 19.3202 16.6653 18.8937 16.7607 18.0304C17.0294 15.6085 17.4975 9.81108 17.5027 9.75213C17.5313 9.395 17.8477 9.14535 18.2022 9.15662C18.5602 9.18609 18.8272 9.49902 18.7986 9.85702C18.7934 9.91596 18.3236 15.7333 18.0531 18.1734C17.8849 19.6878 16.8846 20.6092 15.3746 20.637C14.2191 20.6569 13.1044 20.6682 12.0147 20.6682Z"
        fill="#292D34"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.3499 7.65645H4.65012C4.29126 7.65645 4 7.36519 4 7.00633C4 6.64746 4.29126 6.3562 4.65012 6.3562H19.3499C19.7087 6.3562 20 6.64746 20 7.00633C20 7.36519 19.7087 7.65645 19.3499 7.65645Z"
        fill="#292D34"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5172 7.65642C15.5307 7.65642 14.6743 6.95341 14.4801 5.98603L14.2695 4.93196C14.2252 4.7716 14.0423 4.63204 13.8343 4.63204H10.165C9.95696 4.63204 9.77406 4.7716 9.72118 4.97183L9.51921 5.98603C9.32591 6.95341 8.46861 7.65642 7.48216 7.65642C7.12329 7.65642 6.83203 7.36516 6.83203 7.00629C6.83203 6.64742 7.12329 6.35617 7.48216 6.35617C7.85143 6.35617 8.17215 6.09265 8.24497 5.73031L8.45561 4.67624C8.66972 3.86836 9.36925 3.33179 10.165 3.33179H13.8343C14.6301 3.33179 15.3296 3.86836 15.535 4.63724L15.7552 5.73031C15.8272 6.09265 16.1479 6.35617 16.5172 6.35617C16.876 6.35617 17.1673 6.64742 17.1673 7.00629C17.1673 7.36516 16.876 7.65642 16.5172 7.65642Z"
        fill="#292D34"
      />
    </svg>
  );
};
export default IconDelete;
