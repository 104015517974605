import React, { useImperativeHandle, forwardRef, useEffect, useState } from "react";
import { Form, Input, Radio, message} from "antd";

const { Search } = Input;

const InfoEnterprise = forwardRef(({ onSave, initialValues, onRegisterFinish, onRadioChange, formType }, ref) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  useImperativeHandle(ref, () => ({
    handleSave: () => {
      form
        .validateFields()
        .then((values) => {
          onSave(values); /// Gọi hàm onSave từ props
          console.log("Form Data: ", values); // In ra dữ liệu đã lưu
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    },
  }));

  const fetchBusinessInfo = async (taxCode) => {
    if (taxCode) {
      setLoading(true);
      try {
        const response = await fetch( `https://api.vietqr.io/v2/business/${taxCode}`);
        const data = await response.json();
        if (data && data.data) {
          form.setFieldsValue({
            companyName: data.data.name,
            address: data.data.address,
          });
          message.success("Tra cứu thông tin doanh nghiệp thành công!");
        } else {
          message.error("Không tìm thấy thông tin doanh nghiệp.");
        }
      } catch (error) {
        message.error("Có lỗi xảy ra khi tra cứu mã số thuế.");
      } finally {
        setLoading(false);
      }
    }
  };

  const onSearch = (value) => {
    fetchBusinessInfo(value);
  };

  return (
    <>
      <Radio.Group
        className="radio-button"
        onChange={onRadioChange}
        value={formType}
      >
        <Radio value="business">Doanh nghiệp</Radio>
        <Radio value="personal">Cá nhân</Radio>
      </Radio.Group>

      {formType === "business" && (
        <Form
          name="basic"
          onFinish={onRegisterFinish}
          autoComplete="off"
          layout="vertical"
          className="card-form"
          form={form}
        >
          <Form.Item
            label={<div className="title-item">Tên miền</div>}
            name="tenantId"
            rules={[
              { required: true, message: "Vui lòng nhập tên miền" },
              {
                pattern: /^[a-z0-9]+$/,
                message: "Tên miền không được viết hoa và chứa ký tự đặc biệt",
              },
            ]}
          >
            <Input
              addonBefore="http://"
              addonAfter={`.${process.env.REACT_APP_DOMAIN_TENANT}`}
              placeholder="Nhập vào"
            />
          </Form.Item>
          <Form.Item
            label={<div className="title-item">Mã số thuế</div>}
            name="taxCode"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập mã số thuế",
              },
            ]}
          >
            <Search
              placeholder="Nhập mã số thuế"
              allowClear
              enterButton="Tra cứu"
              size="large"
              onSearch={onSearch}
              loading={loading}
            />
          </Form.Item>
          <Form.Item
            label={<div className="title-item">Tên doanh nghiệp</div>}
            name="companyName"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập tên doanh nghiệp",
              },
            ]}
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
          <div className="item-domain">
            Đây là đường dẫn tên miền mà bạn sẽ quản trị, ví dụ http://name.
            {`.${process.env.REACT_APP_DOMAIN_TENANT}`}
          </div>
          <Form.Item
            label={<div className="title-item">Địa chỉ kinh doanh</div>}
            name="address"
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
          <Form.Item
            label={<div className="title-item">Lĩnh vực kinh doanh</div>}
            name="business_type"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập lĩnh vực kinh doanh",
              },
            ]}
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
        </Form>
      )}

      {formType === "personal" && (
        <Form
          name="basic"
          onFinish={onRegisterFinish}
          autoComplete="off"
          layout="vertical"
          className="card-form"
          form={form}
        >
          <Form.Item
            label={<div className="title-item">Tên miền</div>}
            name="tenantId"
            rules={[
              { required: true, message: "Vui lòng nhập tên miền" },
              {
                pattern: /^[a-z0-9]+$/,
                message: "Tên miền không được viết hoa và chứa ký tự đặc biệt",
              },
            ]}
          >
            <Input
              addonBefore="http://"
              addonAfter={`.${process.env.REACT_APP_DOMAIN_TENANT}`}
              placeholder="Nhập vào"
            />
          </Form.Item>
          <div className="item-domain">
            Đây là đường dẫn tên miền mà bạn sẽ quản trị, ví dụ http://name.
            {`.${process.env.REACT_APP_DOMAIN_TENANT}`}
          </div>
          <Form.Item
            label={<div className="title-item">Căn cước công dân</div>}
            name="cardNumber"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập căn cước công dân",
              },
            ]}
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
          <Form.Item
            label={<div className="title-item">Lĩnh vực kinh doanh</div>}
            name="business_type"
            rules={[
              {
                required: true,
                message: "Vui lòng nhập lĩnh vực kinh doanh",
              },
            ]}
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
          <Form.Item
            label={<div className="title-item">Địa chỉ kinh doanh</div>}
            name="address"
          >
            <Input className="card-input" placeholder="Nhập vào" />
          </Form.Item>
        </Form>
      )}
    </>
  );
});

export default InfoEnterprise;
