

export const openNotificationWithIcon = (notification,type, message) => {
    notification[type]({
        message: message,
        description:
            '',
    });
};

export const AlertType = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error'
};