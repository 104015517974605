import React, {useEffect, useState} from "react";
import {Button, Tooltip, Tree} from "antd";
import {CheckOutlined} from "@ant-design/icons";

import './styles.scss';

export function ServicePermission(props) {
    const {dataSource, service, onSave} = props;
    // let style = {
    //     // background: '#0092ff',
    //     background: '#ffffff',
    //     position: "relative",
    //     display: "flex",
    //     gap: "15px",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     padding: '18px',
    //     color: "#fff"
    // };
    let defaultChecked = service?.permissionIds?.map((permissionId) => {
        return `${permissionId?.scope}-${permissionId.permission_id}`
    });
    const [checked, setChecked] = useState([]);
    useEffect(() => {
        setChecked(defaultChecked)
    }, [service]);
    const onCheck = (checkedKeys, info) => {
        setChecked(checkedKeys)
    };
    const onSaveChange = () => {
        onSave({
            items: checked?.map((item) => {
                let scopeAndPermission = item.split("-");
                if (scopeAndPermission?.[0] !== "Scope") {
                    return {
                        serviceId: service?.id,
                        scope: scopeAndPermission?.[0],
                        permissionId: scopeAndPermission?.[1],
                    }
                }

                return undefined;
            }).filter((item) => {
                return item !== undefined;
            }), serviceId: service?.id
        })

    }
    return (
        <>
            <div className={"feature-tree"}>{service?.name || ""}

                <div style={{
                    position: "absolute",
                    // left: "85%",
                    right: 0,
                    transform: "translate(-50%,0%)",
                    gap: "5px",
                    marginLeft: "auto",
                    display: "flex"
                }}>
                    <Tooltip title="Lưu thay đổi">
                        <Button shape="circle"
                                onClick={onSaveChange}
                                icon={<CheckOutlined/>}/>
                    </Tooltip>
                    {/*<Tooltip title="Hủy bỏ thay đổi">*/}
                    {/*    <Button shape="circle"*/}
                    {/*            onClick={() => {*/}
                    {/*                setChecked(defaultChecked)*/}
                    {/*            }}*/}
                    {/*            icon={<CloseOutlined/>}/>*/}
                    {/*</Tooltip>*/}
                </div>
            </div>
            <Tree
                checkable
                checkedKeys={checked}
                selectable={false}
                onCheck={onCheck}
                treeData={dataSource}
            />
        </>
    );
}