import { useRequest } from "../../useRequest";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import dayjs from "dayjs";

import {
  getStatusText,
  STATUS_PROCESS_REQUEST,
  STATUS_REQUEST,
} from "../../../../constants/enum";
import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import TableComponent from "../../components/table";
import Modal from "../../../../components/modal/modal-card";
import { DEFAULT_PAGE, MAX_RESULTS } from "../../../../constants/apiConstants";
import { formatInputMoney } from "../../../../utils/helper";
import { DATE_FORMAT_CREATE } from "../../../../constants/apiConstants";

const RegRequestReject = forwardRef(({ regRequestRef, listAllUserRef }, ref) => {
  const { getAllUserRequest, changeUserRequest } = useRequest();
  const [dataSource, setDataSource] = useState([]);
  const [selected, setSelected] = useState(null);
  const [size, setSize] = useState(MAX_RESULTS);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [total, setTotal] = useState({});
  const [modalForm, setModalForm] = useState({
    isOpen: false,
    isClose: true,
    title: "",
    type: 0,
  });

  useImperativeHandle(ref, () => ({
    reloadList() {
      onLoadData();
    },
  }));

  useEffect(() => {
    onLoadData();
  }, [page, size]);

  const onLoadData = () => {
    getAllUserRequest({
      status: STATUS_REQUEST.WAIT_REGIS_NEW,
      statusProcess: STATUS_PROCESS_REQUEST.REJECT,
      pageNumber: page,
      pageSize: size,
    }).then((result) => {
      let newDataRes = [];
      let pageSize = size;
      result?.data?.forEach((element, index) => {
        newDataRes.push({ ...element, key: (page - 1) * pageSize + index + 1 });
      });
      setDataSource(newDataRes);
      setTotal(result.total);
    });
  };

  const showModal = (record) => () => {
    setSelected(record);
    setModalForm({
      isOpen: true,
      title: "Lý do từ chối",
      type: 1,
    });
  };

  const onConfirm = (onDoneCb) => {
    changeUserRequest({
      requestId: selected.requestId,
      status: STATUS_PROCESS_REQUEST.NO_PROCESS,
    }).then((result) => {
      onDoneCb && onDoneCb();
      if(result.isSuccess) {
        regRequestRef.current?.reloadList();
        listAllUserRef.current?.reloadList();
        onLoadData();
        handleCancel();
      }
    });
  };

  const handleCancel = () => {
    setModalForm({
      isClose: false,
    });
  };

  const renderFooter = () => {
    return (
      <>
        <ButtonCustomize
          title={"Chuyển về trạng thái yêu cầu"}
          btnType={BtnTypeEnum.OUTLINE}
          btnSize={44}
          showLoading={true}
          onPressHandle={onConfirm}
        />
        <ButtonCustomize
          title={"Thoát"}
          btnType={BtnTypeEnum.OUTLINE}
          btnSize={44}
          onPressHandle={handleCancel}
        />
      </>
    );
  };

  return (
    <>
      <div className="layout-package">
        {/*<p1>Đã chọn</p1>*/}
        {/*<p2>02</p2>*/}
        {/* <button>Chuyển về trạng thái yêu cầu</button> */}
      </div>
      <TableComponent
        scroll={{ x: 1400, y: "calc(100vh - 220px)" }}
        dataSource={dataSource}
        bordered={true}
        // rowSelection={true}
        totalCount={total}
        page={page}
        setPage={setPage}
        pageSize={size}
        setSize={setSize}
        columns={[
          {
            title: "STT",
            dataIndex: "key",
            width: 20,
          },
          {
            title: "Khách hàng",
            width: 105,
            dataIndex: "fullName",
            key: "fullName",
            render: (val, record) => {
              return (
                <div className="table-user-column">
                  <span>{record.fullName}</span>
                  <div className="table-box">
                    <span>{record.phoneNumber}</span>
                    <span>-</span>
                    <span>{record.email}</span>
                  </div>
                </div>
              );
            },
          },
          {
            title: "Ngày đăng ký",
            width: 60,
            dataIndex: "createdAt",
            key: "createdAt",
            render: (val, record) => {
              const createdAtFormat = record.createdAt
                ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
                : "";
              return <span>{createdAtFormat}</span>;
            },
          },
          {
            title: "Trạng thái TK",
            className: "account-status",
            width: 45,
            dataIndex: "userStatus",
            key: "userStatus",
            render: (val) => {
              return <span>{getStatusText(val)}</span>;
            },
          },
          {
            title: "Gói đăng ký",
            width: 45,
            dataIndex: "serviceName",
            key: "serviceName",
          },
          {
            title: "Giá gói",
            width: 60,
            dataIndex: "amount",
            key: "amount",
            render: (_, record) => {
              return <span>{formatInputMoney(record.amount)} đ</span>;
            },
          },
          {
            title: "Trạng thái gói",
            width: 45,
            dataIndex: "fullName",
            key: "fullName",
            render: () => {
              return <span className="package-status">Bị từ chối</span>;
            },
          },
          {
            title: "Hành động",
            width: 38,
            dataIndex: "action",
            key: "action",
            fixed: "right",
            render: (_, record) => {
              return (
                <ButtonCustomize
                  title={"Xem lý do"}
                  btnType={BtnTypeEnum.OUTLINE}
                  btnSize={32}
                  onPressHandle={showModal(record)}
                />
              );
            },
          },
        ]}
      />
      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        footerNode={renderFooter}
      >
        {/*Modal lý do từ chối*/}
        {modalForm.type === 1 && (
          <div className="reason-refuse">
            <p>{selected?.rejectReason}</p>
          </div>
        )}
      </Modal>
    </>
  );
});

export default RegRequestReject;
