import { Tabs } from "antd";
import React, { useState, useRef } from "react";

import ExtendSub from "./Extend";
import "./extendSub.scss";
import ListAllUser from "./ListAllUser";
import ExtendRequestReject from "./Reject";

const ExtendTab = () => {
  const extendRequestRef = useRef(null);
  const extendRequestRejectRef = useRef(null);
  const listAllUserRef = useRef(null);
  const [tabSource] = useState([
    {
      id: "tab-extend-sub",
      title: "Yêu cầu gia hạn gói",
      children: (
        <ExtendSub
          ref={extendRequestRef}
          extendRequestRejectRef={extendRequestRejectRef}
          listAllUserRef={listAllUserRef}
        />
      ),
    },
    {
      id: "tab-canceled",
      title: "Yêu cầu bị từ chối",
      children: (
        <ExtendRequestReject
          ref={extendRequestRejectRef}
          extendRequestRejectRef={extendRequestRef}
          listAllUserRef={listAllUserRef}
        />
      ),
    },
    {
      id: "tab-all",
      title: "Tất cả",
      children: (
        <ListAllUser
          ref={listAllUserRef}
          extendRequestRef={extendRequestRef}
          extendRequestRejectRef={extendRequestRejectRef}
        />
      ),
    },
  ]);

  return (
    <>
      <div className="reg-register">Yêu cầu gia hạn gói</div>
      <div className={"reg-sub-root"}>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"top"}
          items={tabSource.map((item) => {
            return {
              key: `${item.id}`,
              label: `${item.title}`,
              children: item.children,
            };
          })}
        />
      </div>
    </>
  );
};

export default ExtendTab;
