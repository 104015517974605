const IconServiceWhite = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35821 4.03883C3.53783 4.03883 3.53783 4.25215 3.53783 6.85921V6.88485C3.53783 8.02018 3.53783 8.84065 3.81576 9.1873C4.08755 9.5237 4.89469 9.67959 6.35821 9.67959C7.82173 9.67959 8.62887 9.52267 8.90065 9.18628C9.17859 8.84065 9.17858 8.02018 9.17858 6.88382C9.17858 4.25215 9.17858 4.03883 6.35821 4.03883ZM6.35877 11.218C4.62962 11.218 3.33224 11.0365 2.61946 10.1514C2 9.38323 2 8.33508 2 6.88489L2.76919 6.85925H2C2 3.91581 2.18563 2.50049 6.35877 2.50049C10.5319 2.50049 10.7175 3.91581 10.7175 6.85925C10.7175 8.33405 10.7175 9.38323 10.0981 10.1514C9.38529 11.0365 8.08791 11.218 6.35877 11.218Z"
        fill="white"
      />
      <rect
        x="4.70801"
        y="5.20825"
        width="3.30209"
        height="3.30209"
        rx="1"
        fill="#292D34"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6411 4.03883C14.8207 4.03883 14.8207 4.25215 14.8207 6.85921V6.88485C14.8207 8.02018 14.8207 8.84065 15.0986 9.1873C15.3704 9.5237 16.1776 9.67959 17.6411 9.67959C19.1046 9.67959 19.9117 9.52267 20.1835 9.18628C20.4615 8.84065 20.4615 8.02018 20.4615 6.88382C20.4615 4.25215 20.4615 4.03883 17.6411 4.03883ZM17.642 11.218C15.9128 11.218 14.6154 11.0365 13.9027 10.1514C13.2832 9.38323 13.2832 8.33508 13.2832 6.88489L14.0524 6.85925H13.2832C13.2832 3.91581 13.4688 2.50049 17.642 2.50049C21.8151 2.50049 22.0007 3.91581 22.0007 6.85925C22.0007 8.33405 22.0007 9.38323 21.3813 10.1514C20.6685 11.0365 19.3711 11.218 17.642 11.218Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35821 15.3203C3.53783 15.3203 3.53783 15.5336 3.53783 18.1407V18.1663C3.53783 19.3017 3.53783 20.1221 3.81576 20.4688C4.08755 20.8052 4.89469 20.9611 6.35821 20.9611C7.82173 20.9611 8.62887 20.8042 8.90065 20.4678C9.17859 20.1221 9.17858 19.3017 9.17858 18.1653C9.17858 15.5336 9.17858 15.3203 6.35821 15.3203ZM6.35877 22.4995C4.62962 22.4995 3.33224 22.318 2.61946 21.4329C2 20.6647 2 19.6166 2 18.1664L2.76919 18.1407H2C2 15.1973 2.18563 13.782 6.35877 13.782C10.5319 13.782 10.7175 15.1973 10.7175 18.1407C10.7175 19.6155 10.7175 20.6647 10.0981 21.4329C9.38529 22.318 8.08791 22.4995 6.35877 22.4995Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6411 15.3203C14.8207 15.3203 14.8207 15.5336 14.8207 18.1407V18.1663C14.8207 19.3017 14.8207 20.1221 15.0986 20.4688C15.3704 20.8052 16.1776 20.9611 17.6411 20.9611C19.1046 20.9611 19.9117 20.8042 20.1835 20.4678C20.4615 20.1221 20.4615 19.3017 20.4615 18.1653C20.4615 15.5336 20.4615 15.3203 17.6411 15.3203ZM17.642 22.4995C15.9128 22.4995 14.6154 22.318 13.9027 21.4329C13.2832 20.6647 13.2832 19.6166 13.2832 18.1664L14.0524 18.1407H13.2832C13.2832 15.1973 13.4688 13.782 17.642 13.782C21.8151 13.782 22.0007 15.1973 22.0007 18.1407C22.0007 19.6155 22.0007 20.6647 21.3813 21.4329C20.6685 22.318 19.3711 22.4995 17.642 22.4995Z"
        fill="white"
      />
      <rect
        x="15.9902"
        y="16.4897"
        width="3.30209"
        height="3.30209"
        rx="1"
        fill="#292D34"
      />
    </svg>
  );
};
export default IconServiceWhite;
