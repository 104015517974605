import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { ROUTE_URL } from "../../../constants/routeUrl";
import IconDiagonalArrow from "../../../components/svg/iconDiagonalArrow";
import "./DashBoard.scss";
import Revenue from "./revenue";
import Indicators from "./Indicators";

const DashBoardPackage = (props) => {
  const {
    expiredPackagesCount,
    expireSoonPackagesCount,
    expiredServicesCount,
    totalRevenue,
    totalMonthRevenue,
    totalExpiredService
  } = props;

  let boxSource = [
    {
      id: "expiredPkg",
      url: ROUTE_URL.ADMIN.EXPIRED_PACKAGE,
      title: "Gói hết hạn",
      total: expiredPackagesCount || 0,
    },
    {
      id: "expireSoonPkg",
      url: ROUTE_URL.ADMIN.EXPIRE_SOON_PACKAGE,
      title: "Gói sắp hết hạn",
      total: expireSoonPackagesCount || 0,
    },
    {
      id: "change-sub",
      url: ROUTE_URL.ADMIN.EXPIRED_SERVICE,
      title: "Dịch vụ hết hạn",
      total: totalExpiredService.expiredCount || 0,
    },
  ];
  return (
    <>
      {boxSource.map((item) => {
        return (
          <Link className={"box-required"} key={`box-${item.id}`} to={item.url}>
            <div className="card-dashboard-pkg">
              <span>{item.title}</span>
              <div className="content-dashboard">
                <span>{item.total}</span>
                <IconDiagonalArrow color={"grey"} />
              </div>
            </div>
          </Link>
        );
      })}
      <Revenue totalRevenue={totalRevenue} totalMonthRevenue={totalMonthRevenue} />
      <Indicators />
    </>
  );
};

export default DashBoardPackage;
