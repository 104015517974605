const IconUserBlack = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="12.042"
        cy="8.70922"
        rx="3.92382"
        ry="3.92382"
        fill="#2DC56A"
      />
      <path
        d="M18.1502 8.50589C18.1502 11.9029 15.3963 14.6568 11.9993 14.6568C8.60227 14.6568 5.84844 11.9029 5.84844 8.50589C5.84844 5.10887 8.60227 2.35503 11.9993 2.35503C15.3963 2.35503 18.1502 5.10887 18.1502 8.50589Z"
        stroke="#292D34"
        stroke-width="1.4"
      />
      <path
        d="M20.298 20.5006C19.0372 17.1436 15.7976 14.7546 12.0001 14.7546C8.20255 14.7546 4.96296 17.1436 3.70215 20.5006"
        stroke="#292D34"
        stroke-width="1.4"
        stroke-linecap="round"
      />
      <path
        d="M11.9992 16.7637C8.92902 16.7637 6.33054 18.7039 5.4598 21.3776C5.11777 22.4279 6.04387 23.3451 7.14844 23.3451H16.85C17.9545 23.3451 18.8806 22.4279 18.5386 21.3776C17.6679 18.7039 15.0694 16.7637 11.9992 16.7637Z"
        fill="#2DC56A"
      />
    </svg>
  );
};
export default IconUserBlack;
