import { createAsyncThunk } from "@reduxjs/toolkit";
import {del, post, put, get, uploadFile} from "../../api/axios";
import {
    API_CREATE_TENANT,
    API_LIST_REQUEST,
    API_REQUEST_PACKAGE,
    API_CREATE_PACKAGE,
    API_LIST_PACKAGE,
    API_UPDATE_PACKAGE,
    API_EXTEND_PACKAGE,
    API_CHANGE_PACKAGE,
    API_LIST_BUY_MORE,
    API_DELETE_PACKAGE,
    API_BUY_MORE,
    API_LIST_REQUEST_BUY_MORE,
    API_CHANGE_REQUEST_BUY_MORE,
    API_BUY_MORE_REGISTERED,
    API_USER_CREATE,
    API_CHECK_EXISTED_DOMAIN,
    API_USER_LOCK,
    API_POST_USER_INFO,
    API_ALL_BUY_MORE_REGISTERED,
    API_USER_TRANSACTION_LIST,
    API_USER_LIST_SERVICE,
    API_DELETE_USER,
    API_UPDATE_INFO_COMPANY,
    API_UPDATE_INFO_USER,
    API_GET_REVENUE,
    API_GET_INDICATORS,
    API_EXPIRED_SERVICE,
    API_DETAIL_EXPIRED_SERVICE,
    API_TOTAL_REGISTER_PACKAGE,
    API_TOTAL_EXTEND_PACKAGE,
    API_TOTAL_CHANGE_PACKAGE,
    API_TOTAL_BUY_MORE_SERVICE,
    API_UPLOAD_FILE,
    API_CREATE_GROUP_OBJECT,
    API_UPDATE_GROUP_OBJECT,
    API_DELETE_GROUP_OBJECT, API_LIST_GROUP_OBJECT
} from "./apisUrl";
import {API_UPDATE_USER_CONFIG} from "../../../constants/apiUrls";

export const changeStatusUserRequestApi = createAsyncThunk(
  "admin/changeStatusUserRequestApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_REQUEST_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllUserRequestApi = createAsyncThunk(
  "admin/getAllUserRequestApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_LIST_REQUEST, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updatePkgSettingTenant = createAsyncThunk(
    "admin/updatePkgSettingTenant",
    async (payload, thunkAPI) => {
        try {
            let response = await put(API_UPDATE_USER_CONFIG, payload);
            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const createTenantApi = createAsyncThunk(
  "admin/createTenant",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_CREATE_TENANT, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllPackageApi = createAsyncThunk(
  "admin/getAllPackage",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_LIST_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createPackageApi = createAsyncThunk(
  "admin/createPackage",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_CREATE_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updatePackageApi = createAsyncThunk(
  "admin/updatePackage",
  async (payload, thunkAPI) => {
    try {
      let response = await put(API_UPDATE_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deletePackageApi = createAsyncThunk(
  "admin/deletePackage",
  async (payload, thunkAPI) => {
    try {
      let response = await del(`${API_DELETE_PACKAGE}?id=${payload}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getExtendPackage = createAsyncThunk(
  "admin/getExtendPackage",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_EXTEND_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const pushChangePackage = createAsyncThunk(
  "admin/pushChangePackage",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_CHANGE_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllRequestBuyMoreApi = createAsyncThunk(
  "admin/getAllRequestBuyMoreApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_LIST_REQUEST_BUY_MORE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const changeRequestBuyMoreApi = createAsyncThunk(
  "admin/changeRequestBuyMoreApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_CHANGE_REQUEST_BUY_MORE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllListBuyMoreApi = createAsyncThunk(
  "admin/getAllListBuyMoreApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_LIST_BUY_MORE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createBuyMoreServiceApi = createAsyncThunk(
  "admin/createBuyMoreService",
  async (payload, thunkAPI) => {
    try {
        let  {onSuccess,params} = payload;
      let response = await post(API_BUY_MORE,params);
      if(onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const updateBuyMoreServiceApi = createAsyncThunk(
  "admin/updateBuyMoreServiceApi",
  async (payload, thunkAPI) => {
    try {
        let  {onSuccess,params} = payload;

        let response = await put(API_BUY_MORE, params);
        if(onSuccess) onSuccess(response);

        return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteBuyMoreServiceApi = createAsyncThunk(
  "admin/deleteBuyMoreServiceApi",
  async (payload, thunkAPI) => {
    try {
      let response = await del(`${API_BUY_MORE}?id=${payload}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestBuyMoreRegisteredApi = createAsyncThunk(
  "admin/getRequestBuyMoreRegisteredApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_BUY_MORE_REGISTERED, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getAllStatusBuyMoreUser = createAsyncThunk(
    "admin/getAllStatusBuyMoreUserApi",
    async (payload, thunkAPI) => {
        try {
            let response = await get(API_ALL_BUY_MORE_REGISTERED, payload);
            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const checkRequestDomainApi = createAsyncThunk(
  "admin/checkRequestDomainApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_CHECK_EXISTED_DOMAIN, payload);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);


export const pushRequestUserCreateApi = createAsyncThunk(
  "admin/pushRequestUserCreateApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_USER_CREATE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const lockRequestUserApi = createAsyncThunk(
  "admin/lockRequestUserApi",
  async (payload, thunkAPI) => {
    try {
      let response = await put(API_USER_LOCK, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestUserInfoApi = createAsyncThunk(
  "admin/getRequestUserInfoApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_POST_USER_INFO, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestUserTransactionListApi = createAsyncThunk(
  "admin/getRequestUserTransactionListApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_USER_TRANSACTION_LIST, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestUserListServiceApi = createAsyncThunk(
  "admin/getRequestUserListServiceApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_USER_LIST_SERVICE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteRequestUserApi = createAsyncThunk(
  "admin/deleteRequestUserApi",
  async (payload, thunkAPI) => {
    try {
      let response = await del(`${API_DELETE_USER}?userId=${payload.userId}`, {});
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateRequestInfoCompanyApi = createAsyncThunk(
  "admin/updateRequestInfoCompanyApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_UPDATE_INFO_COMPANY, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const uploadFileApi = createAsyncThunk(
  "fetchUploadFile",
  async (formData:FormData, thunkAPI) => {
    try {
      let response = await uploadFile(API_UPLOAD_FILE, formData);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateRequestInfoUserApi = createAsyncThunk(
  "admin/updateRequestInfoUserApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_UPDATE_INFO_USER, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestRevenueApi = createAsyncThunk(
  "admin/getRequestRevenueApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_GET_REVENUE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);


export const getRequestIndicatorsApi = createAsyncThunk(
  "admin/getRequestIndicatorsApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_GET_INDICATORS, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestExpiredServiceApi = createAsyncThunk(
  "admin/getRequestExpiredServiceApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_EXPIRED_SERVICE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestDeleteExpiredServiceApi = createAsyncThunk(
  "admin/getRequestDeleteExpiredServiceApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_DETAIL_EXPIRED_SERVICE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestTotalRegisterPackageApi = createAsyncThunk(
  "admin/getRequestTotalRegisterPackageApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_TOTAL_REGISTER_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestTotalExtendPackageApi = createAsyncThunk(
  "admin/getRequestTotalExtendPackageApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_TOTAL_EXTEND_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestTotalChangePackageApi = createAsyncThunk(
  "admin/getRequestTotalChangePackageApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_TOTAL_CHANGE_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getRequestTotalBuyMoreServiceApi = createAsyncThunk(
  "admin/getRequestTotalBuyMoreServiceApi",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_TOTAL_BUY_MORE_SERVICE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

//Group Object
export const createGroupObject = createAsyncThunk(
    "admin/createGroupObject",
    async (payload, thunkAPI) => {
        const {params} = payload;
        try {
            let response = await post(API_CREATE_GROUP_OBJECT, params);
            return response ;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const updateGroupObject = createAsyncThunk(
    "admin/updateGroupObject",
    async (payload, thunkAPI) => {
        const {params} = payload;
        try {
            let response = await post(API_UPDATE_GROUP_OBJECT, params);
            return response ;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const deleteGroupObject = createAsyncThunk(
    "admin/deleteGroupObject",
    async (payload, thunkAPI) => {
        const {params} = payload;
        try {
            let response = await post(API_DELETE_GROUP_OBJECT, params);
            return response ;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const listGroupObject = createAsyncThunk(
    "admin/listGroupObject",
    async (payload, thunkAPI) => {
        const {params} = payload;
        try {
            let response = await post(API_LIST_GROUP_OBJECT, params);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

