import {optionFilterSortDateType} from "./enum";

export const API_BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api/`;
export const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;
export const API_URL = ``;
export const ACCESS_TOKEN_NAME = 'access_token';
export const KEY_USER_LOGIN = 'account_user';
export const EXP = 'exp';
export const REFRESH_ACCESS_TOKEN = 'refresh_token';


export const MAX_RESULTS = 5;
export const DEFAULT_PAGE = 1;

export const DATE_FORMAT_CREATE = "DD/MM/YYYY_HH:mm";


export const optionFilterSortDate = () => {
    return [
        {
            value: optionFilterSortDateType.DAYNOW,
            label: optionFilterSortDateType.DAYNOW,
        },
        {
            value: optionFilterSortDateType.DAYAGO,
            label: optionFilterSortDateType.DAYAGO,
        },
        {
            value: optionFilterSortDateType.WEEKNOW,
            label: optionFilterSortDateType.WEEKNOW,
        },
        {
            value: optionFilterSortDateType.WEEKAGO,
            label: optionFilterSortDateType.WEEKAGO,
        },
        {
            value: optionFilterSortDateType.MONTHNOW,
            label: optionFilterSortDateType.MONTHNOW,
        },
        {
            value: optionFilterSortDateType.MONTHAGO,
            label: optionFilterSortDateType.MONTHAGO,
        },
        {
            value: optionFilterSortDateType.OPTIONVALUE,
            label: optionFilterSortDateType.OPTIONLABEL,
        },
    ];
};
