import React, { useMemo, useState } from "react";
import { notification, Radio } from "antd";

import Vector from "../../../../components/svg/Vector.svg";
import {
  DATE_FORMAT_CREATE,
  KEY_USER_LOGIN,
} from "../../../../constants/apiConstants";
import Modal from "../../../../components/modal/modal-card";
import { MODAL_FORM_TYPE } from "../../../constants";
import IconSuccess from "../../../../components/svg/icon-success";
import { APP_CONST, STATUS_REQUEST } from "../../../../constants/enum";
import {
  AlertType,
  openNotificationWithIcon,
} from "../../../../utils/AlertMessage";
import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import { UserUseCase } from "../../../../observer/ueser/UserObserver";
import "../../package-crm/index.scss";
import dayjs from "dayjs";
import { formatInputMoney } from "../../../../utils/helper";

const PackageUserCommon = ({ packageUser, apiPackage, items }) => {
  console.log("packageUser", packageUser);
  const [modalForm, setModalForm] = useState({
    isOpen: false,
    title: "",
    type: MODAL_FORM_TYPE.EXTEND,
    successTitle: "",
    successContent:
      "Bộ phận chăm sóc khách hàng sẽ liên hệ với bạn sau khi đã xác nhận thông tin.\n" +
      "Thời gian xác nhận tối thiểu  từ 2 - 4 tiếng  trong giờ hành chính từ  Thứ2 - Thứ 7 hàng tuần (trừ ngày nghỉ, lễ tết...)",
  });
  const initialPkgPriceId = packageUser?.service?.pkgListAll[0]?.id || null;
  const initialPrice = packageUser?.service?.pkgListAll[0]?.price || null;
  const initialTime = packageUser?.service?.pkgListAll[0]?.duration || null;
  const [pkgPriceId, setPkgPriceId] = useState(initialPkgPriceId);
  const [packageSelected, setPackageSelected] = useState(null);
  const [priceExtend, setPriceExtend] = useState(initialPrice);
  const [timeExtend, setTimeExtend] = useState(initialTime);
  const [priceChange, setPriceChange] = useState(null);
  const [timeChange, setTimeChange] = useState(null);

  let user = useMemo(() => {
    return localStorage.getItem(KEY_USER_LOGIN)
      ? JSON.parse(localStorage.getItem(KEY_USER_LOGIN))
      : null;
  }, []);

  const isAdmin = user && user.is_admin;

  const usecase = UserUseCase();
  let days = "",
    startDay = "",
    exitDay = "";
  if (packageUser) {
    startDay = packageUser?.startAt
      ? dayjs(packageUser.startAt).format(DATE_FORMAT_CREATE)
      : "";
    if (!packageUser.service?.packageList) {
      days = APP_CONST.NO_LIMIT_TIME;
      exitDay = APP_CONST.NO_LIMIT_TIME;
    } else {
      exitDay = packageUser?.expiredAt
        ? dayjs(packageUser.expiredAt).format(DATE_FORMAT_CREATE)
        : "";
      // days = packageUser.expiredAt
      //     ? `${Math.round(
      //         moment
      //             .duration(moment(packageUser.expiredAt) - moment().startOf("day"))
      //             .asDays())} Ngày`
      //     : "";
      days = packageUser.expiredAt
        ? `${Math.max(
            0,
            dayjs(packageUser.expiredAt).diff(dayjs(), "day")
          )} Ngày`
        : "";
    }
  }
  const getNewExpirationDate = () => {
    if (!timeExtend || !packageUser?.expiredAt) return exitDay;
    // Tính toán ngày mới bằng cách cộng timeChange vào expiredAt
    const newExpirationDate = dayjs(packageUser.expiredAt).add(
      timeExtend,
      "day"
    );
    return newExpirationDate.format(DATE_FORMAT_CREATE);
  };

  const getNewTimeChange = () => {
    if (!timeChange || !packageUser?.expiredAt) return exitDay;
    // Tính toán ngày mới bằng cách cộng timeChange vào expiredAt
    const newExpirationDate = dayjs(packageUser.expiredAt).add(
      timeChange,
      "day"
    );
    return newExpirationDate.format(DATE_FORMAT_CREATE);
  };

  const newTimeExtend = getNewExpirationDate();
  const newTimeChange = getNewTimeChange();

  console.log("newTimeExtend", newTimeExtend);

  let contents = [
    {
      item: "Tên gói:",
      content_modal: apiPackage ? apiPackage.name : "",
    },
    {
      item: "Thời gian còn lại:",
      content_modal: <>{packageUser.remainDay} ngày</>,
    },
    {
      item: "Ngày đăng ký:",
      content_modal: startDay,
    },
    {
      item: "Ngày hết hạn:",
      content_modal: exitDay,
    },
    {
      item: "Giá gói:",
      content_modal: (
        <>{formatInputMoney(apiPackage?.packageList?.price || "")} đ</>
      ),
    },
  ];

  let extend_items = [
    {
      content: "Thời gian gia hạn:",
      extend_pkg: <>{timeExtend} ngày</>,
    },
    {
      content: "Giá gói:",
      extend_pkg: <>{formatInputMoney(priceExtend)} đ</>,
    },
    {
      content: "Ngày hết hạn mới:",
      extend_pkg: <>{newTimeExtend} </>,
    },
  ];

  let change_items = [
    {
      content: "Thời gian gia hạn:",
      extend_pkg: <>{timeChange} ngày</>,
    },
    {
      content: "Giá gói:",
      extend_pkg: <>{formatInputMoney(priceChange)} đ</>,
    },
    {
      content: "Ngày hết hạn mới:",
      extend_pkg: <>{newTimeChange} </>,
    },
  ];

  const onChange = (e) => {
    const selectedId = e.target.value;
    setPkgPriceId(selectedId);

    const selectedPackage = packageUser?.service?.pkgListAll.find(
      (item) => item.id === selectedId
    );

    const timeExtendPackage = packageUser?.service?.pkgListAll?.find(
      (item) => item.id === selectedId
    );

    const priceChangePackage = packageSelected?.packageList?.find(
      (item) => item.id === selectedId
    );

    const timeExtendPackageChange = packageSelected?.packageList?.find(
      (item) => item.id === selectedId
    );

    if (selectedPackage) {
      setPriceExtend(selectedPackage?.price);
    }
    if (timeExtendPackage) {
      setTimeExtend(timeExtendPackage?.duration);
    }
    if (priceChangePackage) {
      setPriceChange(priceChangePackage?.price);
    }
    if (timeExtendPackageChange) {
      setTimeChange(timeExtendPackageChange?.duration);
    }
  };

  const changePackageModal = () => {
    setModalForm((prev) => ({
      ...prev,
      isOpen: true,
      title: "",
      type: MODAL_FORM_TYPE.CHANGE,
      btn_container: "Thoát",
      btn_success: "Xác nhận, Thay đổi",
    }));
    setPkgPriceId(null);
  };

  const handleCancel = () => {
    setModalForm((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setPackageSelected(null);
    setPriceChange(null);
  };

  const onExtend = () => {
    setModalForm((prev) => ({
      ...prev,
      isOpen: true,
      title: "",
      type: MODAL_FORM_TYPE.EXTEND,
      btn_success: "Xác nhận, Gia hạn thêm",
    }));
    setPkgPriceId(null);
  };

  const onPackageSelected = (packageItem) => () => {
    setPackageSelected(packageItem);
    setPriceChange(packageItem.packageList?.[0]?.price || null);
    setTimeChange(packageItem.packageList?.[0]?.duration || null);
    setPkgPriceId(packageItem.packageList?.[0]?.id || null);
  };

  const renderModal = () => {
    return (
      <>
        {modalForm.type === MODAL_FORM_TYPE.EXTEND && (
          <>
            <div className="title-package">Gia hạn gói CRM360</div>
            <div className="title-use">Gói đang sử dụng</div>
            <div className="body-use">
              {contents.map((content) => (
                <div key={content} className="modal-box">
                  <span className="item-use">{content.item}</span>
                  <span className="content-use">{content.content_modal}</span>
                </div>
              ))}
            </div>
            {apiPackage?.pkgListAll?.length > 0 && (
              <>
                <div className="title-use">Thời hạn gia hạn thêm</div>
                <div className="box-radio">
                  <Radio.Group
                    onChange={onChange}
                    value={pkgPriceId || initialPkgPriceId}
                  >
                    {packageUser?.service?.pkgListAll?.map((itemPrice) => (
                      <Radio key={itemPrice.id} value={itemPrice.id}>
                        {itemPrice.duration} ngày
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
                {priceExtend !== null && (
                  <div className="body-use">
                    {extend_items.map((item) => (
                      <div key={item} className="modal-box">
                        <span className="item-use">{item.content}</span>
                        <span className="content-use">{item.extend_pkg}</span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            <div className="content-body">
              Sau khi bạn xác nhận đăng ký gói Crm360, bộ phận chăm sóc khách
              hàng sẽ liên hệ với bạn để xác minh và trao đổi về quy trình, thủ
              tục và làm hợp đồng.
            </div>
          </>
        )}

        {modalForm.type === MODAL_FORM_TYPE.CHANGE && (
          <>
            <div className="title-package">Thay đổi gói CRM360</div>
            <div className="title-use">Gói đang sử dụng</div>
            <div className="body-use">
              {contents.map((content) => (
                <div className="modal-box">
                  <span className="item-use">{content.item}</span>
                  <span className="content-use">{content.content_modal}</span>
                </div>
              ))}
            </div>
            <div className="title-use">Lựa chọn gói để thay đổi</div>
            <div className="box-option">
              {items?.map((itemSub) => (
                <button
                  onClick={onPackageSelected(itemSub)}
                  disabled={itemSub.id === packageUser?.serviceId}
                  className={`btn-option ${
                    itemSub.id === packageUser?.serviceId
                      ? "btn-option-disable"
                      : ""
                  } ${
                    itemSub.id === packageSelected?.id
                      ? "btn-option-selected"
                      : ""
                  }`}
                >
                  {itemSub.name}
                </button>
              ))}
            </div>
            <div className="title-use">Thời hạn sử dụng</div>
            <div className="box-radio ">
              <Radio.Group onChange={onChange} value={pkgPriceId}>
                {packageSelected?.packageList?.map((itemPrice) => (
                  <Radio value={itemPrice.id}>{itemPrice.duration} ngày</Radio>
                ))}
              </Radio.Group>
            </div>
            {priceChange !== null && (
              <div className="body-use">
                {change_items.map((item) => (
                  <div key={item} className="modal-box">
                    <span className="item-use">{item.content}</span>
                    <span className="content-use">{item.extend_pkg}</span>
                  </div>
                ))}
              </div>
            )}
            <div className="content-body">
              Sau khi bạn xác nhận thay đổi gói Crm360, bộ phận chăm sóc khách
              hàng sẽ liên hệ với bạn để xác minh và trao đổi về quy trình, thủ
              tục và làm hợp đồng.
            </div>
          </>
        )}

        {modalForm.type === MODAL_FORM_TYPE.SUCCESS && (
          <div className="container-success">
            <IconSuccess />
            <div className="item-success">{modalForm.successTitle}</div>
            <div className="content-body">{modalForm.successContent}</div>
          </div>
        )}
      </>
    );
  };

  const renderFooter = () => {
    const onExit = () => {
      setModalForm((prev) => ({
        ...prev,
        isOpen: false,
      }));
      setPackageSelected(null);
      setPriceChange(null);
    };

    const onConfirmForm = async () => {
      let userLocal = JSON.parse(localStorage.getItem(KEY_USER_LOGIN));

      let params = {
        userId: userLocal.id,
        serviceId: packageSelected?.id,
        pkgPriceId: pkgPriceId,
        status: STATUS_REQUEST.WAIT_REGIS_NEW,
      };
      let successTitle = "Thao tác đăng ký gói thành công";

      if (modalForm.type === MODAL_FORM_TYPE.CHANGE) {
        params.status = STATUS_REQUEST.WAIT_CHANGE;
        successTitle = "Thao tác yêu cầu thay đổi gói thành công";
      } else if (modalForm.type === MODAL_FORM_TYPE.EXTEND) {
        params.serviceId = packageUser.serviceId;
        if (!pkgPriceId) {
          params.pkgPriceId = packageUser.service?.packageList?.id || "";
        }
        params.status = STATUS_REQUEST.WAIT_EXTEND;
        successTitle = "Thao tác yêu cầu gia hạn gói thành công";
      }

      if (!params.serviceId) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          "Vui lòng lựa chọn gói"
        );
        return;
      }

      const resData = await usecase.requestAddService(params);
      if (resData.isSuccess) {
        setModalForm((prev) => ({
          ...prev,
          type: MODAL_FORM_TYPE.SUCCESS,
          title: "Thông báo",
          btn_hidden: false,
          btn_success: "Hoàn thành",
          successTitle: successTitle,
        }));
      }
    };

    if (modalForm.type === MODAL_FORM_TYPE.EXTEND) {
      return (
        <>
          <ButtonCustomize
            title={"Thoát"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={onExit}
          />
          <ButtonCustomize
            title={"Xác nhận, gia hạn gói"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={onConfirmForm}
          />
        </>
      );
    }

    if (modalForm.type === MODAL_FORM_TYPE.CHANGE) {
      return (
        <>
          <ButtonCustomize
            title={"Thoát"}
            btnType={BtnTypeEnum.OUTLINE}
            btnSize={44}
            onPressHandle={onExit}
          />
          <ButtonCustomize
            title={"Xác nhận, Thay đổi"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={onConfirmForm}
          />
        </>
      );
    }

    if (modalForm.type === MODAL_FORM_TYPE.SUCCESS) {
      return (
        <>
          <ButtonCustomize
            title={"Hoàn thành"}
            btnType={BtnTypeEnum.GREEN}
            btnSize={44}
            onPressHandle={handleCancel}
          />
        </>
      );
    }
  };

  const statusPkg = () => {
    let statusName = "";
    let classNamePkg = "";
    const pkgFree = packageUser.remainDay === null;
    if (packageUser.status === 2) {
      statusName = "đã hết hạn";
      classNamePkg = "status-pkg-expired";
    }
    if (packageUser.isExpireSoon === true && packageUser.status === 1) {
      statusName = "sắp hết hạn";
      classNamePkg = "status-pkg-expireSoon";
    }
    return (
      <>{!pkgFree && <span className={classNamePkg}>{statusName}</span>}</>
    );
  };

  const pkgFree = packageUser.remainDay === null;

  return (
    <>
      <div className="box-package">
        <div className="item-package">
          Gói {apiPackage?.name} {statusPkg()}
        </div>
        <div className="body-package">
          {!pkgFree && (
            <div className="content-package">
              <span className="item">Ngày còn lại:</span>
              <span className="package-item">{packageUser.remainDay} ngày</span>
            </div>
          )}

          <div className="content-package">
            <span className="item">Ngày đăng ký:</span>
            <span className="package-item">{startDay}</span>
          </div>
          <div className="content-package">
            <span className="item">Ngày hết hạn:</span>
            <span className="package-item">{exitDay}</span>
          </div>
          <div className="content-package">
            <span className="item">Số lượng user:</span>
            <span className="package-item">
              {packageUser?.activeMembersActual || 0}
            </span>
          </div>
        </div>
        <div className="vector">
          <img className="img-vector" src={Vector} alt={""} />
        </div>
        {!isAdmin && (
          <div className="container-btn">
            {packageUser.service?.packageList && (
              <button className="package-btn" onClick={onExtend}>
                Gia hạn gói
              </button>
            )}
            <button className="package-btn" onClick={changePackageModal}>
              Thay đổi gói
            </button>
          </div>
        )}
      </div>

      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        onOpenSuccess={modalForm.onOpenSuccess}
        footerNode={renderFooter}
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default PackageUserCommon;
