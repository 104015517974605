import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../api/axios";
import {
  API_DASHBOARD_HOME,
  API_FIND_SERVICE_USER,
  API_LIST_PACKAGE,
  API_FORGOT_PASSWORD,
  API_RESET_PASSWORD,
  API_DURATION_TOKEN,
  API_CHANGE_PASSWORD,
  API_GET_USER_NAME,
} from "./apisUrl";
import {API_BUY_MORE_REGISTERED, API_SEND_USER_REQUEST_BUY_MORE} from "../admin/apisUrl";
import { API_LIST_WITH_SERVICE_USER } from "../../../constants/apiUrls";

export const getListUsers = createAsyncThunk(
  "user/getListUsers",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_LIST_WITH_SERVICE_USER, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getDashboardHome = createAsyncThunk(
  "user/getDashboardHome",
  async (payload, thunkAPI) => {
    try {
      let response = await get(API_DASHBOARD_HOME, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getUserPackageUsing = createAsyncThunk(
  "user/packageUsing",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_FIND_SERVICE_USER, payload);
      // thunkAPI.dispatch(setAuthInfo(data));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getBuyMoreOfUserApi = createAsyncThunk(
    "user/servicesBuyMoreOfUser",
    async (payload, thunkAPI) => {
        try {
            let response = await get(API_BUY_MORE_REGISTERED, payload);
            return response.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);
export const getListPackageApi = createAsyncThunk(
  "user/getListPackage",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_LIST_PACKAGE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const sendRequestBuyMoreApi = createAsyncThunk(
  "user/sendRequestBuyMoreApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_SEND_USER_REQUEST_BUY_MORE, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const upForgotPasswordApi = createAsyncThunk(
  "user/upForgotPasswordApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_FORGOT_PASSWORD, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const postResetPasswordApi = createAsyncThunk(
  "user/postResetPasswordApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_RESET_PASSWORD, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const postDurationTokenApi = createAsyncThunk(
  "user/getDurationTokenApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_DURATION_TOKEN, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const postChangePasswordApi = createAsyncThunk(
  "user/postChangePasswordApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_CHANGE_PASSWORD, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getUserNameApi = createAsyncThunk(
  "user/getUserNameApi",
  async (payload, thunkAPI) => {
    try {
      let response = await post(API_GET_USER_NAME, payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

