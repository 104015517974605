import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import IconDiagonalArrow from "../../../components/svg/iconDiagonalArrow";
import { ROUTE_URL } from "../../../constants/routeUrl";
import { useDashboard } from "../../useDashboard";
import "./home.scss";
import { dashboardInfoSelector } from "../../../redux/slice/user/userSelector";
import DashBoardPackage from "../dashboard";

function AdminHome(props) {
  const { handleGetDashboardInfo, getRequestRevenue, getExpiredService } = useDashboard();
  const dashboardInfo = useSelector(dashboardInfoSelector);
  const [totalDayRevenue, setTotalDayRevenue] = useState(0);
  const [totalMonthRevenue, setTotalMonthRevenue] = useState(0);
  const [totalExpiredService, setTotalExpiredService] = useState(0);

  const boxSource = useMemo(() => {
    return [
      {
        id: "required-sub",
        url: ROUTE_URL.ADMIN.REG_SUB,
        title: "Đăng ký gói",
        total: dashboardInfo?.totalPkgRegNew || 0,
      },
      {
        id: "extend-sub",
        url: ROUTE_URL.ADMIN.EXTEND_SUB,
        title: "Gia hạn gói",
        total: dashboardInfo?.totalPkgExtend || 0,
      },
      {
        id: "change-sub",
        url: ROUTE_URL.ADMIN.CHANGE_SUB,
        title: "Thay đổi gói",
        total: dashboardInfo?.totalPkgChange || 0,
      },
      {
        id: "reg-service",
        url: ROUTE_URL.ADMIN.REG_SERVICE,
        title: "Đăng ký dịch vụ",
        total: dashboardInfo?.totalServiceRegNew || 0,
      },
      // {
      //   id: "extend-service",
      //   url: ROUTE_URL.ADMIN.EXTEND_SERVICE,
      //   title: "Gia hạn dịch vụ",
      //   total: dashboardInfo?.totalServiceExtend || 0,
      // },
    ];
  }, [dashboardInfo]);

  useEffect(() => {
    handleGetDashboardInfo();
    getExpiredService().then((res) => {
      setTotalExpiredService(res.data);
    });
    // getRequestIndicators();
    getRequestRevenue().then((res) => {
      setTotalDayRevenue(res.data);
      setTotalMonthRevenue(res.data);
    })
  }, []);

  const renderBox = () => {
    return (
      <>
        {boxSource.map((item) => {
          return (
            <Link
              className={"box-required"}
              key={`box-${item.id}`}
              to={item.url}
            >
              <div className="card-dashboard">
                <span>Yêu cầu</span>
                <br />
                <span>{item.title}</span>
                <div>
                  <span>{item.total}</span>
                  <IconDiagonalArrow color={"white"} />
                </div>
              </div>
            </Link>
          );
        })}
      </>
    );
  };

  return (
    <div className={"dashboard-home"}>
      {/*<AdminLayout notification={props.notification}>*/}

      <h2>Dashboard</h2>
      {renderBox()}
      <br />
      <DashBoardPackage
        expiredPackagesCount={dashboardInfo?.expiredPackagesCount}
        expireSoonPackagesCount={dashboardInfo?.expireSoonPackagesCount}
        expiredServicesCount={dashboardInfo?.expiredServicesCount}
        totalRevenue={totalDayRevenue.dailyRevenueRatio || 0}
        totalMonthRevenue={totalMonthRevenue.monthlyRevenueRatio || 0}
        totalExpiredService={totalExpiredService}
      />
    </div>
  );
}

export default AdminHome;
