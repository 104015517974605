export const ROUTE_URL = {
    ADMIN: {
        HOME: "/admin/home",
        REG_SUB: "/admin/reg-sub",
        EXTEND_SUB: "/admin/extend-sub",
        CHANGE_SUB: "/admin/change-sub",
        REG_SERVICE: "/admin/reg-service",
        EXTEND_SERVICE: "/admin/extend-service",
        EXPIRED_PACKAGE: "/admin/expired-package",
        EXPIRE_SOON_PACKAGE: "/admin/expire-soon-package",
        EXPIRED_SERVICE: "/admin/expired-service",
    }
}