import React, {useEffect, useState} from "react";
import {Input, InputNumber} from "antd";
const {TextArea} = Input;

const InputState = ({isNumber = false, isTextArea = false, fieldText, fieldValue, onChanged}) => {
    const [inputVal, setInputVal] = useState(null);

    useEffect(() => {
        setInputVal(fieldValue);
    }, [fieldValue]);


    const onChange = (isNumber = false) => (e) => {
        const valData = isNumber ? e : e.target.value;
        setInputVal(valData);
        onChanged && onChanged(fieldText, valData);
    }

    if (isNumber) {
        return <InputNumber placeholder="Nhập vào" value={inputVal || 0} style={{minWidth: 300}}
                            onChange={onChange(true)}/>

    }

    if (isTextArea) {
        return <TextArea placeholder="Nhập vào" value={inputVal || ""}
                         showCount
                         rows={4}
                         onChange={onChange()}/>

    }

    return <Input placeholder="Nhập vào" value={inputVal || ""} style={{minWidth: 300}} onChange={onChange()}/>
}

export default InputState;