import React, { useEffect, useState } from "react";
import { Tabs, Input } from "antd";
import TableComponent from "../../components/table";
import { UserUseCase } from "../../../../observer/ueser/UserObserver";
import {
  getStatusText,
  statusEnum,
  StatusSelect,
} from "../../../../constants/enum";
import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import Modal from "../../../../components/modal/modal-card";
import IconSuccess from "../../../../components/svg/icon-success";
import IconWarning from "../../../../components/svg/icon-warning";
import "./extendService.scss";
const { TextArea } = Input;

const ExtendServiceTab = () => {
  const [tabSource] = useState([
    {
      id: "tab-extend-sub",
      title: "Yêu cầu gia hạn dịch vụ",
      children: <ExtendServiceComp />,
    },
    {
      id: "tab-canceled",
      title: "Yêu cầu bị từ chối",
      children: <ExtServiceRefuse />,
    },
  ]);

  return (
    <>
      <div className="reg-register">Yêu cầu gia hạn dịch vụ</div>
      <div className={"reg-sub-root"}>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"top"}
          items={tabSource.map((item) => {
            return {
              key: `${item.id}`,
              label: `${item.title}`,
              // disabled: i === 28,
              children: item.children,
            };
          })}
        />
      </div>
    </>
  );
};

const ExtendServiceComp = () => {
  const user = UserUseCase();

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    // setLoading(true)
    user
      .listUserWithService({
        // status: statusEnum.WAIT_APPROVAL,
        pageNumber: 1,
        pageSize: 10,
      })
      .then((res) => {
        setDataSource(res.items);
      });
  }, []);

  const [modalForm, setModalForm] = useState({
    isOpen: false,
    isClose: true,
    title: "",
    type: 0, // 0: Gia han, 1: Thay doi
    btn_hidden: false,
    btn_container: "",
    btn_success: "",
  });

  const showModal = () => {
    setModalForm({
      isOpen: true,
      title: "Yêu cầu gia hạn dịch vụ",
      btn_container: "Thoát",
      type: 1,
    });
  };

  const showSuccessPackage = () => {
    setModalForm({
      isOpen: true,
      title: "Thông báo",
      btn_success: "Hoàn thành",
      type: 2,
    });
  };

  const showRefusePackage = () => {
    setModalForm({
      isOpen: true,
      title: "Thông báo",
      btn_success: "Xác nhận từ chối",
      btn_container: "Hủy bỏ",
      type: 3,
    });
  };

  const handleCancel = () => {
    setModalForm({
      isClose: false,
    });
  };

  function Button1() {
    return (
      <button className="button1" onClick={handleCancel}>
        Thoát
      </button>
    );
  }

  function Button2() {
    return (
      <button className="button2" onClick={showRefusePackage}>
        Từ chối
      </button>
    );
  }

  function Button3() {
    return (
      <button className="button3" onClick={showSuccessPackage}>
        Xác nhận, gia hạn dịch vụ
      </button>
    );
  }

  return (
    <>
      <div className="layout-package">
        <p1>Đã chọn</p1>
        <p2>02</p2>
        <button>Từ chối gia hạn dịch vụ</button>
        <button>Gia hạn dịch vụ</button>
      </div>
      <TableComponent
        dataSource={dataSource}
        columns={[
          {
            title: "Khách hàng",
            width: 100,
            dataIndex: "fullName",
            key: "fullName",
            // fixed: 'left',
          },
          {
            title: "Trạng thái TK",
            className: "account-status",
            width: 100,
            dataIndex: "status",
            key: "status",
            // fixed: 'left',
            render: (val) => {
              return <span>{getStatusText(val)}</span>;
            },
          },
          {
            title: "Gói đăng ký",
            width: 100,
            dataIndex: "serviceName",
            key: "serviceName",
            // fixed: 'left',
          },
          {
            title: "Trạng thái gói",
            width: 100,
            dataIndex: "fullName",
            key: "fullName",
            // fixed: 'left',
            render: (dataIndex) => {
              return <span className="sub-package">{dataIndex}</span>;
            },
          },
          {
            title: "Hành động",
            width: 50,
            dataIndex: "fullName",
            key: "fullName",
            // fixed: 'left',
            render: () => {
              return (
                <ButtonCustomize
                  title={"Kiểm duyệt"}
                  btnType={BtnTypeEnum.GREEN}
                  btnSize={32}
                  onClick={showModal}
                />
              );
            },
          },
        ]}
      />
      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        footerNode={true}
        btn_content={modalForm.btn_container}
        btn_item={modalForm.btn_success}
        footer_container={(modalForm.type === 1) === false}
        footer_exit={(modalForm.type === 2) === false}
        footer_content={true}
        footer_refuse={(modalForm.type === 3) === true}
        footer_item={true}
      >
        {/* Yêu cầu đăng ký gói */}
        {modalForm.type === 1 && (
          <>
            <div className="item-modal">
              <ul>
                <p1>Trạng thái</p1>
                <p2>Chờ kích hoạt</p2>
              </ul>
              <ul>
                <p1>Tên dịch vụ gia hạn</p1>
                <p3>User sử dụng gói</p3>
              </ul>
              <ul>
                <p1>Số lượng user gia hạn</p1>
                <p3>10 user</p3>
              </ul>
              <ul>
                <p1>Ngày yêu cầu đăng ký</p1>
                <p3>09:23_23.05.2024</p3>
              </ul>
            </div>
            <footer className="footer-modal">
              <Button1 />
              <Button2 />
              <Button3 />
            </footer>
          </>
        )}

        {/* Kích hoạt thành công */}
        {modalForm.type === 2 && (
          <>
            <div className="modal-success">
              <IconSuccess />
              <span>Gia hạn dịch vụ thành công</span>
            </div>
          </>
        )}

        {/* Từ chối đăng ký gói */}
        {modalForm.type === 3 && (
          <>
            <div className="modal-refuse">
              <p1>
                <IconWarning />
                <span>Từ chối gia hạn gói</span>
              </p1>
              <p2>
                <span>Nhập nội dung lý do từ chối</span>
                <TextArea
                  placeholder="Nhập vào"
                  autoSize={{ minRows: "100px", maxRows: 5 }}
                />
              </p2>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

const ExtServiceRefuse = () => {
  const user = UserUseCase();

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    // setLoading(true)
    user
      .listUserWithService({
        // status: statusEnum.WAIT_APPROVAL,
        pageNumber: 1,
        pageSize: 10,
      })
      .then((res) => {
        setDataSource(res.items);
      });
  }, []);

  const [modalForm, setModalForm] = useState({
    isOpen: false,
    isClose: true,
    title: "",
    type: 0, // 0: Gia han, 1: Thay doi
    btn_hidden: false,
    btn_container: "",
    btn_success: "",
    btn_text: "",
  });

  const showModal = () => {
    setModalForm({
      isOpen: true,
      title: "Lý do từ chối",
      btn_container: "Chuyển về trạng thái yêu cầu",
      btn_text: "Thoát",
      type: 1,
    });
  };

  const handleCancel = () => {
    setModalForm({
      isClose: false,
    });
  };

  return (
    <>
      <div className="layout-package">
        {/* <p1>Đã chọn</p1>
        <p2>02</p2>
        <button>Chuyển về trạng thái yêu cầu</button> */}
      </div>
      <TableComponent
        dataSource={dataSource}
        columns={[
          {
            title: "Khách hàng",
            width: 100,
            dataIndex: "fullName",
            key: "fullName",
            // fixed: 'left',
          },
          {
            title: "Trạng thái TK",
            className: "account-status",
            width: 100,
            dataIndex: "status",
            key: "status",
            // fixed: 'left',
            render: (val) => {
              return <span>{getStatusText(val)}</span>;
            },
          },
          {
            title: "Gói đăng ký",
            width: 100,
            dataIndex: "serviceName",
            key: "serviceName",
            // fixed: 'left',
          },
          {
            title: "Trạng thái gói",
            width: 100,
            dataIndex: "fullName",
            key: "fullName",
            // fixed: 'left',
            render: (dataIndex) => {
              return <span className="package-status">{dataIndex}</span>;
            },
          },
          {
            title: "Hành động",
            width: 50,
            dataIndex: "fullName",
            key: "fullName",
            // fixed: 'left',
            render: () => {
              return (
                <ButtonCustomize
                  title={"Xem lý do"}
                  btnType={BtnTypeEnum.GREY}
                  btnSize={32}
                  onClick={showModal}
                />
              );
            },
          },
        ]}
      />
      <Modal
        showModal={modalForm.isOpen}
        item={modalForm.title}
        onClose={handleCancel}
        footerNode={true}
        btn_content={modalForm.btn_container}
        btn_item={modalForm.btn_success}
        footer_container={true}
        footer_refuse={false}
        footer_item={false}
        btn_text={modalForm.btn_text}
      >
        {/*Modal lý do từ chối */}
        {modalForm.type === 1 && (
          <>
            <div className="reason-refuse">
              <ul>
                <li>Khách hàng thay đổi ý định </li>
                <li>Khách hàng chưa thanh toán tiền</li>
                <li>Khách hàng không ký hợp đồng.</li>
              </ul>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ExtendServiceTab;
