import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import createSagaMiddleware from '@redux-saga/core';
import rootReducer from './rootReducer';
// import { axiosMiddleware } from '@/redux/middleware/notification';
// import tokenHandle from '../middleware/tokenHandler';
// import rootSagas from './rootSagas';

// const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(axiosMiddleware),
});

// sagaMiddleware.run(rootSagas);

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;
// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;
