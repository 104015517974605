import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
 import {
    packageListSelector,
    packageUsingSelector,
     servicesBuyMoreOfUserSelector
 } from "../../../redux/slice/user/userSelector";
import {useBuyMore} from "../../admin/buyMore/useBuyMore";
import {useUser} from "../useUser";
import CardItem from "../../admin/buyMore/ServiceItem";
import ButtonCustomize from "../../../components/button";
import {BtnTypeEnum} from "../../../components/button/const";
import Modal from "../../../components/modal/modal-card";
import RegNewService from './RegNew';

import "../../../styles/client/extebsion.css";
import dayjs from "dayjs";

const ServiceByPkg = () => {
    const {getUserPackageUsingHandle, getListPackage, sendRequestBuyMore,getBuyMoreOfUser} = useUser();
    const {getAllListBuyMore} = useBuyMore();

    const pkgUser = useSelector(packageUsingSelector);
    const packageList = useSelector(packageListSelector);
    const servicesBuyMoreOfUser = useSelector(servicesBuyMoreOfUserSelector);
     const [pkgSelected, setPkgSelected] = useState(null);
    const [servicesPkg, setServicesPkg] = useState([]);
    const [serviceBuyMoreUsers, setServiceBuyMoreUsers] = useState([]);
    const [modalForm, setModalForm] = useState({
        isOpen: false,
        title: "",
        type: 0 // 0: ticket , 1: system, 2: success
    });
    const [totalDays, setTotalDays] = useState(0);
    const formRegRef = useRef(null);

    useEffect(() => {
        getListPackage();
        getUserPackageUsingHandle();
        getBuyMoreOfUser();
    }, []);
    useEffect(() => {
        setServiceBuyMoreUsers(servicesBuyMoreOfUser?.filter((serviceBuyMore)=>{
             return serviceBuyMore.packageId === pkgSelected?.id
        }))
    }, [servicesBuyMoreOfUser,pkgSelected]);

    useEffect(() => {
        if(!pkgSelected && packageList?.length > 0 && pkgUser)
        {
            const pkgId = pkgUser.service?.id;
            if(pkgId) {
                const foundPkg = packageList.find(x => x.id === pkgId);
                if (foundPkg) {
                    getListBuyMore(foundPkg)();
                }
            }
            const days = Math.ceil(Math.max(0, dayjs(pkgUser.expiredAt).diff(dayjs(), "month", true)));
            setTotalDays(days);
        }
    }, [pkgUser,packageList, pkgSelected]);

    const getListBuyMore = (pkgItem) => () => {
        setPkgSelected(pkgItem)
        getAllListBuyMore({
            packageId: pkgItem.id,
        }).then((res) => {
            setServicesPkg(res.data);
        });
    };



    const onRegNewFinish = async (onDoneCb) => {
        const payload = formRegRef.current?.getDataForm();
        const resData = await sendRequestBuyMore(payload);
        onDoneCb && onDoneCb();
        if(resData.isSuccess) {
            formRegRef.current?.clearForm();
            setModalForm(prev => ({
                ...prev,
                title: "Thông báo",
                type: 2
            }));
        }
    }

    const handleCancel = () => {
        formRegRef.current?.clearForm();
        setModalForm({isOpen: false});
    }

    const renderFooterModal = () => {
        if (modalForm.type === 2)
            return <ButtonCustomize
                title="Hoàn thành"
                btnType={BtnTypeEnum.GREEN}
                btnSize={44}
                onPressHandle={handleCancel}
            />;

        return (
            <>
                <ButtonCustomize
                    title="Thoát"
                    btnType={BtnTypeEnum.OUTLINE}
                    btnSize={44}
                    onPressHandle={handleCancel}
                />
                <ButtonCustomize
                    title="Xác nhận, Đăng ký"
                    btnType={BtnTypeEnum.GREEN}
                    btnSize={44}
                    showLoading={true}
                    onPressHandle={onRegNewFinish}
                />
            </>
        );
    }

    const renderBodyModal = () => {
        return (
            <RegNewService
                ref={formRegRef}
                titleForm={modalForm.title}
                formType={modalForm.type}
                totalDays={totalDays}
            />
        );
    }

    return <>
        <div className="container-service">
            <div className="add-service">
                <span className={"service-user"}>Dịch vụ mua thêm theo gói</span>
            </div>
            <div className="box-service">
                {packageList.map((item) => (
                    <ButtonCustomize
                        key={item.id}
                        title={<>{item.name}</>}
                        btnType={item.id === pkgSelected?.id ? BtnTypeEnum.GREEN : BtnTypeEnum.GREY}
                        btnSize={44}
                        onPressHandle={getListBuyMore(item)}
                    />
                ))}
            </div>
            <div className="list-plugin">
                {servicesPkg.map((e: any, index: any) => {
                   let isBuy = serviceBuyMoreUsers.find((serviceBuyMore)=>{

                       return e.id === serviceBuyMore.serviceId
                   })
                    return <CardItem isSetting={false}  isBuy={isBuy} item={e} formRegRef={formRegRef} setModalForm={setModalForm}    key={index}/>
                })}
            </div>
            {/*{servicesPkg?.map(item => {*/}
            {/*    const isSameUserPkg = pkgSelected?.id === pkgUser?.service?.id;*/}
            {/*    return <ServiceItem key={item.id} isSameUserPkg={isSameUserPkg} isSetting={false} item={item}*/}
            {/*                        onUserReg={onUserReg}/>*/}
            {/*})}*/}
        </div>

        <Modal
            showModal={modalForm.isOpen}
            item={modalForm.title}
            onClose={handleCancel}
            footerNode={renderFooterModal}
        >
            {renderBodyModal()}
        </Modal>
    </>
}

export default ServiceByPkg;