export const BtnTypeEnum = {
    GREEN: 0,
    BLACK: 1,
    GREY: 2,
    OUTLINE: 3,
    NO_BORDER: 4,
    WHITE: 5,
    RED: 6,
};

export const BtnSizeEnum = {
    S_44: 44,
    S_32: 32,
};