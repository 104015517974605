import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Upload} from 'antd';

import React from 'react';


export const UploadFileCustom = ({
                                     fileListState,
                                     fileListShow,
                                     maxCount,
                                     onRemove,
                                     disabled,
                                     accept,
                                     onChange,
                                     beforeUpload,
                                     titleButton,
                                     showRemoveIcon = true,
                                     showDownloadIcon = true,
                                     multiple = true,
                                 }) => {
    const customBeforeUpload = (file, FileList) => {

        try {
let isLt10M = true
            if (beforeUpload) {
                beforeUpload(file, FileList)
            }
             return file || Upload.LIST_IGNORE;

        } catch (error) {
            console.log(error);
        }
    }
    let customProps = {}
    if (maxCount) {
        customProps.maxCount = maxCount;
    }
    return (
        <>
            <Upload
                listType="picture-card"
                beforeUpload={customBeforeUpload}
                fileList={fileListShow}
                accept={accept}
                customRequest={({ onError, onSuccess, file }) => {
                    onSuccess(file);
                }}
                onRemove={onRemove}
                multiple={multiple}
                onChange={onChange}
                showUploadList={{
                    showRemoveIcon: !disabled ? showRemoveIcon : false,
                    showDownloadIcon: showDownloadIcon,
                    showPreviewIcon: false,
                }}
                defaultFileList={fileListState}

                className="upload-list-inline"
                {...customProps}
            >
                <button disabled={disabled} style={{border: 0, background: 'none'}} type="button">
                    <PlusOutlined/>
                    <div style={{marginTop: 8}}>{titleButton ? titleButton : "Tải lên"}</div>
                </button>
            </Upload>
        </>
    );
}