import axios from "axios";

import {API_REFRESH_TOKEN} from "../../constants/apiUrls";
import {ACCESS_TOKEN_NAME, EXP, REFRESH_ACCESS_TOKEN} from "../../constants/apiConstants";


export const refreshaccesstoken = async () => {
    let refreshToken = localStorage.getItem(REFRESH_ACCESS_TOKEN) || "";
    axios.post(API_REFRESH_TOKEN, {}, {
        headers: {
            Authorization: `Bearer ${refreshToken}`,
        }
    })
        .then(function (response) {
            if (response.status === 200) {
                localStorage.setItem(REFRESH_ACCESS_TOKEN, response.data.data.refreshToken);
                localStorage.setItem(EXP, response.data.data.exp);
                localStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.token);
                return response.data.data.token
            }
            return ""
        })
        .catch(function (error) {
            return ""
        });
};