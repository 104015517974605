import React, { useRef, useState } from "react";
import { Tabs } from "antd";

import "./regService.scss";
import RegRequest from "./Request";
import RegRequestReject from "./Reject";
import ListAllUser from "./ListAllUser";

const RegSubTab = () => {
  const regRequestRef = useRef(null);
  const regRequestRejectRef = useRef(null);
  const listAllUserRef = useRef(null);

  const [tabSource] = useState([
    {
      id: "tab-reg-sub",
      title: "Yêu cầu đăng ký dịch vụ",
      children: (
        <RegRequest
          ref={regRequestRef}
          regRequestRejectRef={regRequestRejectRef}
          listAllUserRef={listAllUserRef}
        />
      ),
    },
    {
      id: "tab-canceled",
      title: "Yêu cầu bị từ chối",
      children: (
        <RegRequestReject
          ref={regRequestRejectRef}
          regRequestRef={regRequestRef}
          listAllUserRef={listAllUserRef}
        />
      ),
    },
    {
      id: "tab-all",
      title: "Tất cả",
      children: (
        <ListAllUser
          ref={listAllUserRef}
          regRequestRef={regRequestRef}
          regRequestRejectRef={regRequestRejectRef}
        />
      ),
    },
  ]);

  return (
    <>
      <div className="reg-register">Yêu cầu đăng ký dịch vụ</div>
      <div className={"reg-sub-root"}>
        <Tabs
          defaultActiveKey="1"
          tabPosition={"top"}
          items={tabSource.map((item) => {
            return {
              key: `${item.id}`,
              label: `${item.title}`,
              children: item.children,
            };
          })}
        />
      </div>
    </>
  );
};

export default RegSubTab;
