import axios from "axios";
import moment from "moment";
import {notification} from "antd";

import {refreshaccesstoken} from "./auth";
import {ACCESS_TOKEN_NAME, EXP} from "../../constants/apiConstants";
import {AlertType, openNotificationWithIcon} from "../../utils/AlertMessage";

const axiosProtectedDefault = (type: any = "json") => {
    let config: any = {
        timeout: 600 * 1000,
    };
    const refreshTokenFn = async () => {
        try {
            const accessToken = await refreshaccesstoken();

            return {accessToken};
        } catch (error) {
            throw error;
        }
    };
    switch (type) {
        case "file":
            config.headers = {
                'Content-Type': 'multipart/form-data',
            }
            break;
        default:
            config.headers = {
                "Content-Type": "application/json",
            }
            break;
    }
    let axiosProtected: any = axios.create(config);

    axiosProtected.interceptors.request.use(
        async (config: any) => {
            const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
            if (accessToken) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken}`,
                };
            }

            return config;
        },
        (err: any) => {
            return Promise.reject(err);
        }
    );

    axiosProtected.interceptors.response.use(
        (response: any) => {
            return response;
        },
        async (err: any) => {
            const currentTimestamp: any = moment().unix();
            let expires = localStorage.getItem(EXP) && Number(localStorage.getItem(EXP));
            let tokenExpired = currentTimestamp >= expires;

            // config đại diện cho cấu hình của yêu cầu ban đầu đã gây ra lỗi
            const config = err.config;
            if (
                tokenExpired &&
                err.response?.status === 406 &&
                !config.sent
            ) {
                // kiểm tra xem req gây lỗi này đã được gửi chưa, nếu chưa được gửi thì sẽ gửi => tránh việc gửi lại liên tục
                config.sent = true;
                try {
                    const {accessToken} = await refreshTokenFn();
                    if (accessToken) {
                        config.headers = {
                            ...config.headers,
                            Authorization: `Bearer ${accessToken}`,
                        };
                    }
                    // Gửi lại request lỗi
                    return await axiosProtected(config);
                } catch (error) {
                    throw error
                }
            } else {
                if (err.response?.status === 406) {
                    localStorage.clear();
                    openNotificationWithIcon(notification, AlertType.error, "Vui lòng đăng nhập lại")
                    setTimeout(()=>{
                        window.location.reload();
                    },2000)
                }
            }

            throw err;
        }
    );
    return axiosProtected;
}


const axiosProtected = axiosProtectedDefault();


export const get = async (url: any, params: any) => {
    return axiosProtected.get(url, {
        ...{params: params},
    }).then((response: any) => {
        return response.data;
    })
        .catch((error: any) => {
            throw error;
        });
};
export const post = async (url: string, data: any | null) => {
    return axiosProtected.post(url, data).then((response: any) => {
        return response.data;
    })
        .catch((error: any) => {
            throw error
        });
};
export const uploadFile  = async (url: any, formData: FormData) => {
    let axiosProtected = axiosProtectedDefault("file")
    try {
        return await axiosProtected.post(url, formData);
    } catch (error) {
        throw error;
    }
}
export const put = async (url: string, data: any) => {
    return axiosProtected.put(url, data).then((response: any) => {
        return response.data;
    })
        .catch((error: any) => {
            throw error;
        });
};

export const del = async (url: string, data: any) => {
    return axiosProtected.delete(url, {data: data}).then((response: any) => {
        return response.data;
    })
        .catch((error: any) => {
            throw error;
        });
};