import { createSlice } from '@reduxjs/toolkit';
import {getBuyMoreOfUserApi, getDashboardHome, getListPackageApi, getUserPackageUsing} from "./userApis";

const initialState = {
  packageUsing: null,
  packageList: [],
  dashboardHome: null,
  servicesBuyMoreOfUser: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearStateUser: (state) => {
      state.packageUsing = null;
      state.packageList = [];
      state.dashboardHome = null;
      state.servicesBuyMoreOfUser = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPackageUsing.fulfilled, (state, action) => {
        state.packageUsing = action.payload;
      })
      .addCase(getListPackageApi.fulfilled, (state, action) => {
          state.packageList = action.payload?.items || [];
      }).addCase(getDashboardHome.fulfilled, (state, action) => {
      state.dashboardHome = action.payload;
    }).addCase(getBuyMoreOfUserApi.fulfilled, (state, action) => {
      state.servicesBuyMoreOfUser = action.payload;
    });
  },
});

export const { clearStateUser} = userSlice.actions;

export default userSlice.reducer;
