import IconSuccess from "../../../../components/svg/icon-success";

const AddSuccess = ({ data }) => {
    console.log("data: ", data)
  return (
    <>
      <div className="modal-add">
        <div className="body-add">
          <IconSuccess />
          <span>Thêm khách mới thành công!</span>
        </div>
        <div className="card-body-modal">
          <span>Thông tin cá nhân</span>
          <ul>
            <li>Đường dẫn truy cập:</li>
            <li>{data?.tenantId}</li>
          </ul>
          <ul>
            <li>Tài khoản đăng nhập:</li>
            <li>{data?.username}</li>
          </ul>
          <ul>
            <li>Mật khẩu đăng nhập:</li>
            <li>{data?.password}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AddSuccess;
