import { del, post, put } from "../../redux/api/axios";
import {
  API_CREATE_SERVICE,
  API_DEL_SERVICE,
  API_LIST_SERVICE,
  API_UPDATE_SERVICE,
  API_FIND_SERVICE_USER,
} from "../../constants/apiUrls";
import { AlertType, openNotificationWithIcon } from "../../utils/AlertMessage";
import { notification } from "antd";

export function PackageUseCase() {
  const createService = async (params) => {
    try {
      return await post(API_CREATE_SERVICE, {
        name: params.name || "",
        createdMembers: params.createdMembers || 1,
        activeMembers: params.activeMembers || 1,
        duration: params.duration || 1,
        canTryFree: params.canTryFree || false,
        paymentType: params.paymentType || 1,
        isPopular: params.isPopular || false,
        unitPrice: params.unitPrice || 0,
        description: params.description || "",
      });
    } catch (error) {
      throw error;
    }
  };
  const updateService = async (params, item) => {
    try {
      return await put(API_UPDATE_SERVICE, {
        id: item.id,
        name: params.name || "",
        status: item.status,
        createdMembers: params.createdMembers || 1,
        activeMembers: params.activeMembers || 1,
        duration: params.duration || 1,
        canTryFree: params.canTryFree || false,
        paymentType: params.paymentType || 1,
        isPopular: params.isPopular || false,
        unitPrice: params.unitPrice || 0,
        description: params.description || "",
      });
    } catch (error) {
      throw error;
    }
  };
  const deleteService = async (id) => {
    try {
      return await del(API_DEL_SERVICE, { id: id });
    } catch (error) {
      throw error;
    }
  };
  const listServices = async (searchParams, setLoading) => {
    try {
      let response = await post(API_LIST_SERVICE, searchParams);
      if (response.isSuccess) {
        return response.data;
      }
    } catch (error) {
      if (error.response?.status !== 406) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          error?.response?.data?.message || "Lỗi hệ thống"
        );
      }
      return {};
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const findUserPackage = async (searchParams, setLoading) => {
    try {
      let response = await post(API_FIND_SERVICE_USER, searchParams);
      if (response.isSuccess) {
        return response.data;
      }
    } catch (error) {
      if (error.response?.status !== 406) {
        openNotificationWithIcon(
          notification,
          AlertType.error,
          error?.response?.data?.message || "Lỗi hệ thống"
        );
      }
      return {};
    } finally {
      if (setLoading) {
        setLoading(false);
      }
    }
  };
  return {
    createService,
    updateService,
    deleteService,
    listServices,
    findUserPackage,
  };
}

export function ServicesObserver(inject) {
  const { setLoading } = inject;
  let useCase = PackageUseCase();
  const getListServices = async (params) => {
    return await useCase.listServices(params, setLoading);
  };
  const getUserPackage = async (params) => {
    return await useCase.findUserPackage(params, setLoading);
  };
  return {
    getListServices,
    getUserPackage,
  };
}
