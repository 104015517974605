import React  from 'react';
import {withRouter} from 'react-router-dom';

// import ClientLayout from "../layout/client/ClientLayout";
import Profile from "../../pages/client/profile";

function Home(props) {
 
    return (
        <>
        {/*<ClientLayout>*/}
            <Profile/>
        {/*</ClientLayout>*/}
        </>
    )
}

export default withRouter(Home);