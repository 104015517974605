import React, { useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Divider, Form, Input, Modal, Select, Space} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import "../../styles/client/SelectedData.css";
const {Option} = Select;



export const SelectedData  = ({
                                                              typeValue,
                                                              onChange,
                                                              options,
                                                              addItemCustom,
                                                              reloadCustom,
                                                              canEdit = false,
                                                              allowClear = false,
                                                              updateItemCustom,
                                                              delItemCustom,
                                                              ...props
                                                          }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalEditedLabel, setModalEditedLabel] = useState('');
    const [modalEditedValue, setModalEditedValue] = useState('');

    const reloadData = () => {
        reloadCustom()
    };

    let filteredItems = [];
    let items = [];
    if (typeValue) {
        filteredItems = options?.filter((item) => item.type === typeValue);
        items = filteredItems?.map((item) => ({
            value: item.id,
            label: item.name,
        }));
    } else {
        items = options
    }


    const onNameChange = (e) => {
        setName(e.target.value);
    };

    const addItem =  () => {
        if (name) {
            addItemCustom(name, reloadData)
        }
    }

    const handleSelectOpenChange = (open) => {
        setIsSelectOpen(open);
    };

    const showModal = (item, e) => {
        e.preventDefault();
        e.stopPropagation();
        setModalEditedValue(item.value);
        setModalEditedLabel(item.label);
        setIsModalVisible(true);
    };

    const handleModalOk =  () => {
        updateItemCustom(modalEditedValue, modalEditedLabel, reloadData)
        handleModalCancel();
    }
    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const handleDelete = (item, e) => {
        e.preventDefault();
        e.stopPropagation();
        delItemCustom(item.value, reloadData);
    }

    const showModalAndDisableDropdown = (item, e) => {
        e.preventDefault();
        e.stopPropagation();
        showModal(item, e);
    };

    const handleDeleteAndDisableDropdown = (item, e) => {
        e.preventDefault();
        e.stopPropagation();
        handleDelete(item, e);
    };

    return (
        <>
            <Select
                onDropdownVisibleChange={handleSelectOpenChange}
                showSearch

                allowClear={allowClear}
                onChange={(newValue, option) => onChange(newValue, option)}
                style={{
                    width: '100%',
                }}
                filterOption={(input, option) => {
                    let data = [];

                    return option.props.children.props.children?.[0].props.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
                }}
                placeholder='Chọn dữ liệu'
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        {
                            canEdit && (
                                <>
                                    <Divider
                                        style={{
                                            margin: '8px 0',
                                        }}
                                    />

                                    <div className={"input-create"}>
                                        <Input
                                            placeholder='Nhập dữ liệu'
                                            value={name}
                                            onChange={onNameChange}
                                            style={{width: "100%"}}
                                        />
                                        <Button icon={<PlusOutlined/>} onClick={addItem}>
                                            Thêm
                                        </Button>
                                    </div>

                                </>
                            )
                        }

                    </>
                )}
                {...props}
            >
                {items?.map((item) => (
                    <Option key={`option-${item.value}`} item={item} value={item.value}>
                        <div className='select-data-actions'>
                            <span className={"truncate max-width-[150px]"}>{item.label}</span>
                            {isSelectOpen && !item?.isSystem && canEdit && (
                                <div className=' select-data-action-butons'>
                                    <Button
                                        className='float-right'
                                        type='text'
                                        icon={<EditOutlined className="text-blue-600"/>}
                                        onClick={(e) => showModalAndDisableDropdown(item, e)}
                                    />
                                    <Button
                                        className='float-right'
                                        type='text'
                                        icon={<DeleteOutlined className="text-red-600"/>}
                                        onClick={(e: any) => handleDeleteAndDisableDropdown(item, e)}
                                    />
                                </div>
                            )}
                        </div>
                    </Option>
                ))}
            </Select>
            <Modal
                title='Sửa dữ liệu'
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Xác nhận"
                cancelText="Hủy"
                className={"Modal-custom"}
            >
            <div className={"Modal-custom-content"}>
                <Form.Item label='Tên'>
                    <Input value={modalEditedLabel} onChange={(e) => setModalEditedLabel(e.target.value)}/>
                </Form.Item>
            </div>
            </Modal>
        </>
    );
};

