const Line = () => {
  return (
    <svg
      width="1"
      height="14"
      viewBox="0 0 1 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="0.5" y1="2.18557e-08" x2="0.499999" y2="14" stroke="#E7E7ED" />
    </svg>
  );
};

export default Line;
