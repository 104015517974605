import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal/modal-card";
import { MODAL_FORM_TYPE } from "../../../constants";
import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import "./modal.scss";
import { Input, Popconfirm, Table, Tooltip, Button } from "antd";
import IconDelete from "../../../../components/svg/icon-delete";
import IconAdd from "../../../../components/svg/icon-add";

export default function ModalSetting({ regRequestRef }, ref) {
  const [modalForm, setModalForm] = useState({
    isOpen: false,
    title: "",
    type: MODAL_FORM_TYPE.EXTEND, // 0: Gia han, 1: Thay doi, 2: "Đăng ký gói", 3: "Cập nhật thông tin DN", 4: "Success"
    btn_hidden: false,
    btn_container: "",
    btn_success: "",
    successTitle: "",
    successContent:
      "Bộ phận chăm sóc khách hàng sẽ liên hệ với bạn sau khi đã xác nhận thông tin.\n" +
      "Thời gian xác nhận tối thiểu  từ 2 - 4 tiếng  trong giờ hành chính từ  Thứ2 - Thứ 7 hàng tuần (trừ ngày nghỉ, lễ tết...)",
  });

  const showModal = () => {
    setModalForm({
      isOpen: true,
      title: "Lý do từ chối",
      type: 0,
    });
  };

  const handleCancel = () => {
    setModalForm((prev) => ({
      ...prev,
      isOpen: false,
    }));
    // setPackageSelected(null);
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
      original_price: "0đ",
      price: "0đ",
      discount: "0%",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
      original_price: "0đ",
      price: "0đ",
      discount: "0%",
    },
  ];

  const columns = [
    {
      title: <div>Số ngày</div>,
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <Tooltip title={text} placement="top">
          <div className="display-text">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: <div>Giá gốc</div>,
      dataIndex: "original_price",
      key: "original_price",
      render: (text: string) => (
        <Tooltip title={text} placement="top">
          <div className="display-text">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: <div>Giá bán</div>,
      dataIndex: "price",
      key: "price",
      render: (text: string) => (
        <Tooltip title={text} placement="top">
          <div className="display-text">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: <div>% Giảm</div>,
      dataIndex: "discount",
      key: "discount",
      render: (text: string) => (
        <Tooltip title={text} placement="top">
          <div className="display-text">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: <div className="text-right"> Thao tác</div>,
      dataIndex: "action",
      render: () => {
        return (
          <div style={{ display: "flex", gap: 10, float: "right" }}>
            <Popconfirm
              placement="topRight"
              title="Xóa"
              //   onConfirm={cancel}
              //   onOpenChange={() => setEditItem(record)}
            >
              <Tooltip title="Xóa" placement="top">
                <IconDelete color="#1D193E" />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="package-modal">
        <div className="package-body">
          <ul>
            <li>Tên gói</li>
            <Input />
          </ul>
          <ul>
            <li>Số user khởi tạo</li>
            <Input />
          </ul>
        </div>
        <div className="package-box">
          <span>Giá bán</span>
          <Table
            // components={{
            //   body: {
            //     wrapper: DraggableContainer,
            //     row: DraggableBodyRow,
            //   },
            // }}
            // scroll={{ x: 1500 }}
            dataSource={dataSource}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}
