const IconServiceBlack = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35821 3.53871C3.53783 3.53871 3.53783 3.75203 3.53783 6.35909V6.38473C3.53783 7.52006 3.53783 8.34053 3.81576 8.68718C4.08755 9.02357 4.89469 9.17946 6.35821 9.17946C7.82173 9.17946 8.62887 9.02255 8.90065 8.68615C9.17859 8.34053 9.17858 7.52006 9.17858 6.3837C9.17858 3.75203 9.17858 3.53871 6.35821 3.53871ZM6.35877 10.7179C4.62962 10.7179 3.33224 10.5364 2.61946 9.65128C2 8.88311 2 7.83496 2 6.38477L2.76919 6.35913H2C2 3.41568 2.18563 2.00037 6.35877 2.00037C10.5319 2.00037 10.7175 3.41568 10.7175 6.35913C10.7175 7.83393 10.7175 8.88311 10.0981 9.65128C9.38529 10.5364 8.08791 10.7179 6.35877 10.7179Z"
        fill="#292D34"
      />
      <rect
        x="4.70801"
        y="4.70813"
        width="3.30209"
        height="3.30209"
        rx="1"
        fill="#2DC56A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6411 3.53871C14.8207 3.53871 14.8207 3.75203 14.8207 6.35909V6.38473C14.8207 7.52006 14.8207 8.34053 15.0986 8.68718C15.3704 9.02357 16.1776 9.17946 17.6411 9.17946C19.1046 9.17946 19.9117 9.02255 20.1835 8.68615C20.4615 8.34053 20.4615 7.52006 20.4615 6.3837C20.4615 3.75203 20.4615 3.53871 17.6411 3.53871ZM17.642 10.7179C15.9128 10.7179 14.6154 10.5364 13.9027 9.65128C13.2832 8.88311 13.2832 7.83496 13.2832 6.38477L14.0524 6.35913H13.2832C13.2832 3.41568 13.4688 2.00037 17.642 2.00037C21.8151 2.00037 22.0007 3.41568 22.0007 6.35913C22.0007 7.83393 22.0007 8.88311 21.3813 9.65128C20.6685 10.5364 19.3711 10.7179 17.642 10.7179Z"
        fill="#292D34"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.35821 14.8203C3.53783 14.8203 3.53783 15.0336 3.53783 17.6407V17.6663C3.53783 18.8017 3.53783 19.6221 3.81576 19.9688C4.08755 20.3052 4.89469 20.4611 6.35821 20.4611C7.82173 20.4611 8.62887 20.3042 8.90065 19.9678C9.17859 19.6221 9.17858 18.8017 9.17858 17.6653C9.17858 15.0336 9.17858 14.8203 6.35821 14.8203ZM6.35877 21.9995C4.62962 21.9995 3.33224 21.818 2.61946 20.9329C2 20.1647 2 19.1166 2 17.6664L2.76919 17.6407H2C2 14.6973 2.18563 13.282 6.35877 13.282C10.5319 13.282 10.7175 14.6973 10.7175 17.6407C10.7175 19.1155 10.7175 20.1647 10.0981 20.9329C9.38529 21.818 8.08791 21.9995 6.35877 21.9995Z"
        fill="#292D34"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6411 14.8203C14.8207 14.8203 14.8207 15.0336 14.8207 17.6407V17.6663C14.8207 18.8017 14.8207 19.6221 15.0986 19.9688C15.3704 20.3052 16.1776 20.4611 17.6411 20.4611C19.1046 20.4611 19.9117 20.3042 20.1835 19.9678C20.4615 19.6221 20.4615 18.8017 20.4615 17.6653C20.4615 15.0336 20.4615 14.8203 17.6411 14.8203ZM17.642 21.9995C15.9128 21.9995 14.6154 21.818 13.9027 20.9329C13.2832 20.1647 13.2832 19.1166 13.2832 17.6664L14.0524 17.6407H13.2832C13.2832 14.6973 13.4688 13.282 17.642 13.282C21.8151 13.282 22.0007 14.6973 22.0007 17.6407C22.0007 19.1155 22.0007 20.1647 21.3813 20.9329C20.6685 21.818 19.3711 21.9995 17.642 21.9995Z"
        fill="#292D34"
      />
      <rect
        x="15.9902"
        y="15.9897"
        width="3.30209"
        height="3.30209"
        rx="1"
        fill="#2DC56A"
      />
    </svg>
  );
};

export default IconServiceBlack;