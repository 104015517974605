const IconHomeBlack = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12.7039C2 10.4155 2 9.27128 2.5192 8.32274C3.0384 7.37421 3.98695 6.78551 5.88403 5.60813L7.88403 4.36687C9.88939 3.12229 10.8921 2.5 12 2.5C13.1079 2.5 14.1106 3.12229 16.116 4.36687L18.116 5.60812C20.0131 6.78551 20.9616 7.37421 21.4808 8.32274C22 9.27128 22 10.4155 22 12.7039V14.225C22 18.1258 22 20.0763 20.8284 21.2881C19.6569 22.5 17.7712 22.5 14 22.5H10C6.22876 22.5 4.34315 22.5 3.17157 21.2881C2 20.0763 2 18.1258 2 14.225V12.7039Z"
        stroke="white"
        stroke-width="1.4"
      />
      <path
        d="M4.73828 12.9195C4.73828 11.2579 4.73828 10.427 5.11528 9.73828C5.49229 9.04953 6.18105 8.62206 7.55856 7.76714L9.01081 6.86583C10.4669 5.96211 11.195 5.51025 11.9995 5.51025C12.804 5.51025 13.5321 5.96211 14.9882 6.86583L16.4405 7.76713C17.818 8.62206 18.5067 9.04953 18.8837 9.73828C19.2607 10.427 19.2607 11.2579 19.2607 12.9195V14.024C19.2607 16.8565 19.2607 18.2728 18.41 19.1527C17.5594 20.0327 16.1901 20.0327 13.4518 20.0327H10.5473C7.80888 20.0327 6.4397 20.0327 5.58899 19.1527C4.73828 18.2728 4.73828 16.8565 4.73828 14.024V12.9195Z"
        fill="#292D34"
      />
      <rect
        x="9.51074"
        y="13.1194"
        width="4.76562"
        height="5.53555"
        rx="1"
        fill="white"
      />
    </svg>
  );
};

export default IconHomeBlack;
