import React from "react";
import { Steps } from "antd";

import InfoUser from "../body-modal/info-user";

import ConfirmInfo from "../body-modal/confirm-info";
import "./styles.scss";
import InfoEnterprise from "../../../../components/info-enterprise/info-enterprise";
const AddNewCustomer = ({ onSave, formRef, currentStep, enterpriseFormRef, savedData, resetData, formType, onRadioChange}) => {
  const steps = [
    {
      title: <div className="customer-title">Thông tin tài khoản</div>,
      content: <InfoUser ref={formRef} onSave={onSave} initialValues={savedData} />,
    },
    {
      title: <div className="customer-title">Thông tin doanh nghiệp</div>,
      content: <InfoEnterprise ref={enterpriseFormRef} onSave={onSave} initialValues={savedData} resetData={resetData} formType={formType} onRadioChange={onRadioChange} />,
    },
    {
      title: <div className="customer-title">Xác nhận thông tin</div>,
      content: <ConfirmInfo data={savedData} formType={formType} />,
    },
  ];



  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  return (
    <>
      <Steps labelPlacement="vertical" current={currentStep} items={items} />
      <div>{steps[currentStep].content}</div>
    </>
  );
};

export default AddNewCustomer;
