const Email = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.332 26.2H10.332C6.73203 26.2 4.33203 24.4 4.33203 20.2V11.8C4.33203 7.60005 6.73203 5.80005 10.332 5.80005H22.332C25.932 5.80005 28.332 7.60005 28.332 11.8V20.2C28.332 24.4 25.932 26.2 22.332 26.2Z"
        stroke="#292D34"
        stroke-width="1.4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="7.12891"
        y="8.31226"
        width="18.6649"
        height="15.2777"
        rx="3"
        fill="#2DC56A"
      />
      <path
        d="M26.3539 10.2629L18.1417 16.6087C17.0753 17.4326 15.5872 17.4326 14.5208 16.6087L6.30859 10.2629"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Email;
