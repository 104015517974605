import {ACTION_FORM_ADD, ACTION_FORM_EDIT} from "../../constants/form";
import {del, post, put} from "../../redux/api/axios";
import {API_CREATE_SERVICE, API_DEL_SERVICE, API_LIST_SERVICE, API_UPDATE_SERVICE} from "../../constants/apiUrls";
import {AlertType, openNotificationWithIcon} from "../../utils/AlertMessage";
import {notification} from "antd";
import {DEFAULT_PAGE, MAX_RESULTS} from "../../constants/apiConstants";

export function PackageUseCase() {
    const createService = async (params) => {
        try {
            return await post(API_CREATE_SERVICE, {
                "name": params.name || "",
                "createdMembers": params.createdMembers || 1,
                "activeMembers": params.activeMembers || 1,
                "duration": params.duration || 1,
                "canTryFree": params.canTryFree || false,
                "paymentType": params.paymentType || 1,
                "isPopular": params.isPopular || false,
                "unitPrice": params.unitPrice || 0,
                "description": params.description || ""
            })
        } catch (error) {
            throw error;
        }
    }
    const updateService = async (params, item) => {
        try {
            return await put(API_UPDATE_SERVICE, {
                "id": item.id,
                "name": params.name || "",
                "status": item.status,
                "createdMembers": params.createdMembers || 1,
                "activeMembers": params.activeMembers || 1,
                "duration": params.duration || 1,
                "canTryFree": params.canTryFree || false,
                "paymentType": params.paymentType || 1,
                "isPopular": params.isPopular || false,
                "unitPrice": params.unitPrice || 0,
                "description": params.description || ""
            })
        } catch (error) {
            throw error;
        }
    }
    const deleteService = async (id) => {
        try {
            return await del(API_DEL_SERVICE, {"id": id})
        } catch (error) {
            throw error;
        }
    }
    const listServices = async (searchParams, setLoading) => {
        try {
            let response = await post(API_LIST_SERVICE, searchParams);
            if (response.isSuccess) {
                return response.data
            }
        } catch (error) {
            if (error.response?.status !== 406) {
                openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
            }
            return {}
        } finally {
            if (setLoading) {
                setLoading(false)
            }
        }
    }
    return {
        createService,
        updateService,
        deleteService,
        listServices
    }
}

export function PackageObserver(inject) {
    const {
        setMode,
        mode,
        setOpenModal,
        selectedRowKeys,
        setSelectedRowKeys,
        setLoading,
        setParams,
        params,
        setOkText,
        currenEdit,
        setCurrenEdit,
        setPage,
        setSize,
    } = inject
    let useCase = PackageUseCase();
    const createPackage = () => {
        setMode(ACTION_FORM_ADD);
        setOpenModal(true);
        setOkText("Thêm mới");
    }
    const refreshPackage = () => {
        setParams({...params});
    }
    const resetFilter = () => {
        setParams({});
        setPage(DEFAULT_PAGE);
        setSize(MAX_RESULTS);
    }

    const onCancel = () => {
        setOpenModal(false)
        setCurrenEdit(null);
    }
    const handleChangInput = (value, key) => {
        setPage(1);
        setParams({
            ...params,
            [key]: value,
        });
    };
    const getDataSource = async (params) => {
        return await useCase.listServices(params, setLoading);
    }
    const getPropsInputSelect = () => {
        return {
            handleChangInput
        };
    }
    const getDataModal = () => {

        return {
            mode,
            currenEdit,
            submit: (values) => {
                if (window.tinymce != undefined) {
                    let Description = window.tinymce.get("description");
                    values.description = Description?.getContent({format: "html"}) || "";
                } else {
                    values.description = "";
                }
                if (mode == ACTION_FORM_ADD) {
                    useCase.createService(values)
                        .then(function (response) {
                            if (response.isSuccess) {
                                openNotificationWithIcon(notification, AlertType.success, "Tạo gói thành công")
                                refreshPackage();
                                onCancel();
                            }
                        })
                        .catch(function (error) {
                            if (error.response?.status !== 406) {
                                openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                            }
                        });
                } else if (mode == ACTION_FORM_EDIT && currenEdit) {
                    useCase.updateService(values, currenEdit)
                        .then(function (response) {
                            if (response.isSuccess) {
                                openNotificationWithIcon(notification, AlertType.success, "Cập nật gói thành công")
                                refreshPackage();
                                onCancel();
                            }
                        })
                        .catch(function (error) {
                            if (error.response?.status !== 406) {
                                openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                            }
                        });
                }
            }
        }
    }

    const onSelectChangeCheckBox = (e) => {
        setSelectedRowKeys(e);
    };
    const getRowSelection = () => {
        return {
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChangeCheckBox,
        };
    }
    const getPropsColumn = () => {
        return {
            handleEdit: (row) => {
                setMode(ACTION_FORM_EDIT);
                setOpenModal(true);
                setCurrenEdit(row)
                setOkText("Cập nhật");
            },
            handleConfirmDelete: (row) => {
                useCase.deleteService(row.id).then(function (response) {
                    if (response.isSuccess) {
                        openNotificationWithIcon(notification, AlertType.success, "Xóa gói thành công")
                        refreshPackage();
                    }
                })
                    .catch(function (error) {
                        if (error.response?.status !== 406) {
                            openNotificationWithIcon(notification, AlertType.error, error?.response?.data?.message || "Lỗi hệ thống")
                        }
                    });
            },

        }
    }
    return {
        createPackage,
        refreshPackage,
        resetFilter,
        getPropsColumn,
        getPropsInputSelect,
        onCancel,
        getDataModal,
        getRowSelection,
        getDataSource,
    }
}