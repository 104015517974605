import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

import {
  getStatusText,
  STATUS_PROCESS_REQUEST,
  STATUS_REQUEST,
} from "../../../../constants/enum";
import { DATE_FORMAT_CREATE } from "../../../../constants/apiConstants";

import ButtonCustomize from "../../../../components/button";
import { BtnTypeEnum } from "../../../../components/button/const";
import TableComponent from "../../components/table";
import Modal from "../../../../components/modal/modal-card";
import IconSuccess from "../../../../components/svg/icon-success";
import IconWarning from "../../../../components/svg/icon-warning";
import { useRequest } from "../../useRequest";
import { useUser } from "../../../client/useUser";
import {
  AlertType,
  openNotificationWithIcon,
} from "../../../../utils/AlertMessage";
import { notification } from "antd";
import { formatInputMoney } from "../../../../utils/helper";
import { DEFAULT_PAGE, MAX_RESULTS } from "../../../../constants/apiConstants";

const ListAllUser = forwardRef(
  ({ extendRequestRef, extendRequestRejectRef }, ref) => {
    const { getAllUserRequest, changeUserRequest, confirmExtend } =
      useRequest();
    const { getUserPackageUsingHandle } = useUser();

    const [dataSource, setDataSource] = useState([]);
    const [rejectReasonVal, setRejectReason] = useState("");
    const [selected, setSelected] = useState(null);
    const [userPackage, setUserPackage] = useState(null);
    const [size, setSize] = useState(MAX_RESULTS);
    const [page, setPage] = useState(DEFAULT_PAGE);
    const [total, setTotal] = useState({});
    const [modalForm, setModalForm] = useState({
      isOpen: false,
      isClose: true,
      title: "",
      type: 0,
    });

    useImperativeHandle(ref, () => ({
      reloadList() {
        onLoadData();
      },
    }));

    useEffect(() => {
      onLoadData();
    }, [page, size]);

    const onShowModal = (record) => () => {
      if (!record) return;

      getUserPackageUsingHandle({
        userId: record.userId,
        durationExtend: record.duration,
      }).then((result) => {
        const starAtFormat = result.data.startAt
          ? dayjs(result.data.startAt).format(DATE_FORMAT_CREATE)
          : "";
        setUserPackage({ ...result.data, starAtFormat });
      });

      const createdAtFormat = record.createdAt
        ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
        : "";
      setSelected({ ...record, createdAtFormat });
      setModalForm({
        isOpen: true,
        title: "Yêu cầu gia hạn gói",
        type: 1,
      });
    };

    const onLoadData = () => {
      getAllUserRequest({
        status: STATUS_REQUEST.WAIT_EXTEND,
        statusProcess: STATUS_PROCESS_REQUEST.ALL,
        pageNumber: page,
        pageSize: size,
      }).then((result) => {
        let newDataRes = [];
        let pageSize = size;
        result?.data?.forEach((element, index) => {
          newDataRes.push({
            ...element,
            key: (page - 1) * pageSize + index + 1,
          });
        });
        setDataSource(newDataRes);
        setTotal(result.total);
      });
    };

    const onConfirmExtension = (onDoneCb) => {
      confirmExtend({
        requestId: selected?.requestId,
      }).then(function () {
        onDoneCb && onDoneCb();
        onLoadData();
        setModalForm({
          isOpen: true,
          title: "Thông báo",
          type: 2,
        });
      });
    };

    const onGoRejectForm = () => {
      setModalForm({
        isOpen: true,
        title: "Thông báo",
        type: 3,
      });
    };

    const onShowModalReject = (record) => () => {
      if (!record) return;
      setSelected(record);
      setModalForm({
        isOpen: true,
        title: "Lý do từ chối",
        type: 4,
      });
    };

    const onConfirm = () => {
      changeUserRequest({
        requestId: selected.requestId,
        status: STATUS_PROCESS_REQUEST.NO_PROCESS,
      }).then(() => {
        extendRequestRef.current?.reloadList();
        extendRequestRejectRef.current?.reloadList();
        onLoadData();
        handleCancel();
      });
    };

    const onReject = (onDoneCb) => {
      if (!rejectReasonVal || rejectReasonVal.trim().length === 0) {
        onDoneCb && onDoneCb();
        openNotificationWithIcon(
          notification,
          AlertType.error,
          "Vui lòng nhập lý do từ chối"
        );
        return;
      }

      changeUserRequest({
        requestId: selected.requestId,
        status: STATUS_PROCESS_REQUEST.REJECT,
        reason: rejectReasonVal,
      }).then(() => {
        onDoneCb && onDoneCb();
        extendRequestRef.current?.reloadList();
        extendRequestRejectRef.current?.reloadList();
        onLoadData();
        handleCancel();
      });
    };

    const handleCancel = () => {
      setModalForm({
        isClose: false,
      });
      setSelected(null);
      setRejectReason("");
    };

    const renderFooter = () => {
      if (modalForm.type === 1) {
        return (
          <>
            <ButtonCustomize
              title={"Thoát"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Từ chối"}
              btnType={BtnTypeEnum.GREY}
              btnSize={44}
              onPressHandle={onGoRejectForm}
            />
            <ButtonCustomize
              title={"Xác nhận, gia hạn gói"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              showLoading={true}
              onPressHandle={onConfirmExtension}
            />
          </>
        );
      }

      if (modalForm.type === 2) {
        return (
          <>
            <ButtonCustomize
              title={"Hoàn thành"}
              btnType={BtnTypeEnum.GREEN}
              btnSize={44}
              onPressHandle={handleCancel}
            />
          </>
        );
      }

      if (modalForm.type === 3) {
        return (
          <>
            <ButtonCustomize
              title={"Hủy bỏ"}
              btnType={BtnTypeEnum.GREY}
              btnSize={44}
              onPressHandle={handleCancel}
            />
            <ButtonCustomize
              title={"Xác nhận từ chối"}
              btnType={BtnTypeEnum.BLACK}
              btnSize={44}
              showLoading={true}
              onPressHandle={onReject}
            />
          </>
        );
      }

      if (modalForm.type === 4) {
        return (
          <>
            <ButtonCustomize
              title={"Chuyển về trạng thái yêu cầu"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={onConfirm}
            />
            <ButtonCustomize
              title={"Thoát"}
              btnType={BtnTypeEnum.OUTLINE}
              btnSize={44}
              onPressHandle={handleCancel}
            />
          </>
        );
      }
    };

    return (
      <>
        <div className="layout-package">
          {/* <p1>Đã chọn</p1>
        <p2>02</p2>
        <button>Từ chối gia hạn gói</button>
        <button>Gia hạn gói</button> */}
        </div>
        <TableComponent
          scroll={{ x: 1400, y: "calc(100vh - 220px)" }}
          bordered={true}
          // rowSelection={true}
          dataSource={dataSource}
          totalCount={total}
          page={page}
          setPage={setPage}
          pageSize={size}
          setSize={setSize}
          columns={[
            {
              title: "STT",
              dataIndex: "key",
              width: 20,
            },
            {
              title: "Khách hàng",
              width: 105,
              dataIndex: "fullName",
              key: "fullName",
              render: (val, record) => {
                return (
                  <div className="table-user-column">
                    <span>{record.fullName}</span>
                    <div className="table-box">
                      <span>{record.phoneNumber}</span>
                      <span>-</span>
                      <span>{record.email}</span>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "Ngày yêu cầu gia hạn",
              width: 60,
              dataIndex: "createdAt",
              key: "createdAt",
              render: (val, record) => {
                const createdAtFormat = record.createdAt
                  ? dayjs(record.createdAt).format(DATE_FORMAT_CREATE)
                  : "";
                return <span>{createdAtFormat}</span>;
              },
            },
            {
              title: "Trạng thái TK",
              className: "account-status",
              width: 45,
              dataIndex: "status",
              key: "status",
              render: (val) => {
                return <span>{getStatusText(val)}</span>;
              },
            },
            {
              title: "Gói gia hạn",
              width: 45,
              dataIndex: "serviceName",
              key: "serviceName",
            },
            {
              title: "Giá gói",
              width: 60,
              dataIndex: "amount",
              key: "amount",
              render: (_, record) => {
                return <span>{formatInputMoney(record.amount)} đ</span>;
              },
            },
            {
              title: "Trạng thái gói",
              width: 45,
              dataIndex: "fullName",
              key: "fullName",
              render: (_, record) => {
                const getStatusText = (val, row) => {
                  if (row.statusProcess === 0) {
                    return <span className="sub-package">Chờ gia hạn</span>;
                  }

                  if (row.statusProcess === 1) {
                    return <span className="sub-status">Đã gia hạn</span>;
                  }

                  if (row.statusProcess === 2) {
                    return <span className="reject-status">Bị từ chối</span>;
                  }
                };
                return (
                  <>
                    <div>{getStatusText(record.statusProcess, record)}</div>
                  </>
                );
              },
            },
            {
              title: "Hành động",
              width: 45,
              dataIndex: "fullName",
              key: "fullName",
              fixed: "right",
              render: (val, record) => {
                const buttonStatus = (val, row) => {
                  if (row.statusProcess === 0) {
                    return (
                      <ButtonCustomize
                        title={"Kiểm duyệt"}
                        btnType={BtnTypeEnum.GREEN}
                        btnSize={32}
                        onPressHandle={onShowModal(row)}
                      />
                    );
                  }

                  if (row.statusProcess === 1) {
                    return null;
                  }

                  if (row.statusProcess === 2) {
                    return (
                      <ButtonCustomize
                        title={"Xem lý do"}
                        btnType={BtnTypeEnum.OUTLINE}
                        btnSize={32}
                        onPressHandle={onShowModalReject(row)}
                      />
                    );
                  }
                };
                return <>{buttonStatus(record.action, record)}</>;
              },
            },
          ]}
        />
        <Modal
          showModal={modalForm.isOpen}
          item={modalForm.title}
          onClose={handleCancel}
          footerNode={renderFooter}
        >
          {/* Yêu cầu đăng gia hạn gói */}
          {modalForm.type === 1 && (
            <>
              <div className="item-modal">
                <ul>
                  <li>Trạng thái</li>
                  <li>
                    <span>Chờ gia hạn</span>
                  </li>
                </ul>
                <ul>
                  <li>Tên gói gia hạn</li>
                  <li>{selected?.serviceName}</li>
                </ul>
                <ul>
                  <li>Thời gian còn lại</li>
                  <li>{userPackage?.remainDay} ngày</li>
                </ul>
                <ul>
                  <li>Thời gian gia hạn thêm</li>
                  <li>{selected?.duration} ngày</li>
                </ul>
                <ul>
                  <li>Thời gian còn lại sau khi gia hạn</li>
                  <li>{userPackage?.totalDayExtend} ngày</li>
                </ul>
                <ul>
                  <li>Ngày đăng ký gói</li>
                  <li>{userPackage?.starAtFormat}</li>
                </ul>
                <ul>
                  <li>Ngày yêu cầu gia hạn</li>
                  <li>{selected?.createdAtFormat}</li>
                </ul>
                <ul>
                  <li>Giá gói</li>
                  <li>{formatInputMoney(selected?.amount)} đ</li>
                </ul>
              </div>
            </>
          )}

          {/* Gia hạn gói thành công */}
          {modalForm.type === 2 && (
            <>
              <div className="modal-success">
                <IconSuccess />
                <span>Gia hạn gói thành công</span>
              </div>
            </>
          )}

          {/* Từ chối gia hạn gói */}
          {modalForm.type === 3 && (
            <>
              <div className="modal-refuse">
                <div className="modal-warning">
                  <IconWarning />
                  <span>Từ chối gia hạn gói</span>
                </div>
                <div className="modal-warning">
                  <span>Nhập nội dung lý do từ chối</span>
                  <TextArea
                    rows={4}
                    placeholder="Nhập vào"
                    autoSize={{ minRows: "100px", maxRows: 5 }}
                    onChange={(e) => setRejectReason(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          {/*Lý do từ chối*/}
          {modalForm.type === 4 && (
            <div className="reason-refuse">
              <p>{selected?.rejectReason}</p>
            </div>
          )}
        </Modal>
      </>
    );
  }
);

export default ListAllUser;
