export const INPUT_TYPE_TEXT_NAME = 'input';
export const INPUT_TYPE_TEXT_PHONE = 'input_phone';
export const INPUT_TYPE_EMAIL = 'input_email';
export const INPUT_TYPE_NUMBER_PRICE = 'input_price';
export const INPUT_TYPE_NUMBER_QUANTITY = 'input_quantity';
export const INPUT_TYPE_NUMBER_PERCENT = 'input_percent';
export const INPUT_TYPE_CONFORM_PASSWORD = 'input_type_conform_password';
export const INPUT_TYPE_PASSWORD = 'input_type_password';
export const SELECT_INPUT = 'select';
export const DEBOUNCE_SELECT = 'DEBOUNCE_SELECT';
export const SELECT_CUSTOM_OPTION = 'SelectCustomOption';
export const TEXT_AREA = 'text_area';
export const CHECKBOX = 'checkbox';
export const CHECKBOX_GROUP = 'checkboxgroup';
export const RADIO = 'radio';
export const SELECT_MULTIPLE = 'select_multiple';
export const LAYOUT_CARE = 'care';
export const LAYOUT_CARE_HISTORY = 'care_history';
export const LAYOUT_DEFAULT = 'default';
export const TITLE_BOX = "title_box"
export const TITLE_DIVIDER = "title_divider"
export const CUSTOM_DROPDOWN_SELECT = 'CUSTOM_DROPDOWN_SELECT';
export const DRAGGER_UPLOAD = 'DRAGGER_UPLOAD';

export const SELECT_TREE = 'select_tree';
export const INPUT_TYPE_DATE = 'input_date';
export const INPUT_TYPE_DATE_TIME = 'input_date_time';
export const INPUT_TYPE_TIME = 'input_time';
export const TITLE_FIELD_BOX_TEXT = 'title_field_box_text';
export const DATE_RANGE_PICKER = 'input_date_rang_picker';
export const ACTION_FORM_ADD = 'ADD';
export const ACTION_FORM_EDIT = 'EDIT';
export const ACTION_FORM_INFO = 'INFO';
export const SWITCH = "switch"
export const BUTTON_TYPE_CHECK = "button"
export const INPUT_SEARCH = 'input_search'

export const TEXT_AREA_DEFAULT = "text_area_default"
export const TYPE_RENDER = "type_render"
export const TYPE_UPLOAD_FILE = "type_upload_file"
export const DROPDOWN_CUSTOM = "dropdown_custom"
export const UPLOAD_FILE = "upload"


export const PAYMENT_TYPE_SERVICE =  [
    {label: "Thanh toán duy nhất một lần", value: 1},
    {label: "Thanh toán hàng năm", value: 2},
    {label: "Thanh toán hàng tháng", value: 3},
]