export const APP_CONST = {
    NO_LIMIT_TIME: "Không giới hạn"
};

export const optionFilterSortDateType = {
    DAYNOW: "Hôm nay",
    DAYAGO: "Hôm qua",
    WEEKNOW: "Tuần này",
    WEEKAGO: "Tuần trước",
    MONTHNOW: "Tháng này",
    MONTHAGO: "Tháng trước",
    OPTIONLABEL: "Tùy chọn",
    OPTIONVALUE: "0",
};
export const statusEnum = {
    ALL: -1,
    DELETE: 0,
    ACTIVE: 1,
    IS_LOCKED: 2,
};

export const STATUS_USING = {
    NO_ACTIVE: 0,
    ACTIVE: 1
}

export const STATUS_REQUEST = {
    WAIT_REGIS_NEW: 0, // Chờ đăng ký gói mới
    WAIT_EXTEND: 1, // Chờ gia hạn gói
    WAIT_CHANGE: 2, // Chờ thay đổi gói
};

export const STATUS_PROCESS_REQUEST = {
    ALL: -1,
    NO_PROCESS: 0, // Chưa xử lý
    DONE: 1, // Đã xử lý xong
    REJECT: 2, // Từ chối
};

export const EUserStatus = {
    DELETE: 0,
    ACTIVE: 1,
    IS_LOCKED: 2,
};
export const getUserStatusName = (status) => {
    let statusName = "";
    let clsName = "";
    let isStatus = true;
    switch (status) {
        case EUserStatus.DELETE:
            statusName = "Đã xóa";
            clsName = "";
            break;
        case EUserStatus.ACTIVE:
            statusName = "Hoạt động";
            clsName = "user-status-active";
            break;
        case EUserStatus.IS_LOCKED:
            statusName = "Đã khóa";
            clsName = "user-status-locked";
            isStatus = false
            break;
        default:
            statusName = "";
            clsName = "";
    }

    return {statusName, clsName, isStatus};
};

export const EBuyMoreSystem = {
    NORMAL: 0,
    SYSTEM: 1,
};
export const EBuyMoreType = {
    NORMAL: 0,
    ADDON: 1,
};

export const STATUS_PACKAGE = {
  new_register: "new_purchase",
  buy_more: "buy_more_item",
  changed: "changed_pkg",
  extend_pkg: "extend_pkg",
};

export const STATUS_PACKAGE_FILTER = {
    ALL: -1,
    ACTIVATED: 0,
    WAIT_APPROVAL: 1,
    EXPIRED: 2,
    EXPIRE_SOON: 3,
    REJECTED: 4,
}


export const SERVICE_ADD_MORE = "service_add_more";


export const StatusSelect = [
    {
        label: "Tất cả",
        value: statusEnum.ALL,
    },
    {
        label: "Hoạt động",
        value: statusEnum.ACTIVE,
    },
    {
        label: "Đã khóa",
        value: statusEnum.IS_LOCKED,
    },
];
export const ETypeGroup = {
    GROUP_BUY_MORE: 1,
}

export const getStatusText = (status) => {
    const foundItem = StatusSelect.find((x) => x.value === status);
    return foundItem != null ? foundItem.label : "";
};
