const Chat = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.666 27.5C23.0172 27.5 28.166 22.3512 28.166 16C28.166 9.64872 23.0172 4.5 16.666 4.5C10.3147 4.5 5.16602 9.64872 5.16602 16C5.16602 17.8397 5.59798 19.5783 6.36599 21.1204C6.5701 21.5301 6.63803 21.9985 6.5197 22.4407L5.83475 25.0007C5.53741 26.1119 6.55408 27.1286 7.66537 26.8313L10.2253 26.1463C10.6675 26.028 11.1359 26.096 11.5457 26.3C13.0876 27.0681 14.8264 27.5 16.666 27.5Z"
        stroke="#292D34"
        stroke-width="1.4"
      />
      <path
        d="M16.6666 24.4068C21.3095 24.4068 25.0734 20.6429 25.0734 15.9999C25.0734 11.357 21.3095 7.59314 16.6666 7.59314C12.0236 7.59314 8.25977 11.357 8.25977 15.9999C8.25977 17.3448 8.57554 18.6158 9.13698 19.7431C9.28619 20.0426 9.33585 20.385 9.24935 20.7083L8.74863 22.5797C8.53126 23.3921 9.27448 24.1352 10.0869 23.9179L11.9582 23.4172C12.2815 23.3307 12.6239 23.3804 12.9235 23.5295C14.0507 24.091 15.3217 24.4068 16.6666 24.4068Z"
        fill="#2DC56A"
      />
      <path
        d="M12.0664 16H12.0768M16.6561 16H16.6664M21.2561 16H21.2664"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Chat;
