import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {InputNumber, Button, Table, Popconfirm, Tooltip, Form, Input} from "antd";
import cloneDeep from "lodash/cloneDeep";
import IconDelete from "../../../../../components/svg/icon-delete";
import IconAdd from "../../../../../components/svg/icon-add";
import './styles.scss';
import InputState from "./InputState";
import {formatterNumber, parserNumber} from "../../../../../utils/helper";

const itemPackage = {
  id: "",
  serviceId: "",
  duration: 0,
  priceOrg: 0,
  discount: 0,
  price: 0,
  isDelete: false,
  isEditing: false,
};
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
  );
};
const EditableCell = ({
                                                                              title,
                                                                              editable,
                                                                              children,
                                                                              dataIndex,
                                                                              record,
                                                                              handleSave,
                                                                              ...restProps
                                                                            }) => {
  // const [editing, setEditing] = useState(false);
  // const inputRef = useRef(null);
  // const form = useContext(EditableContext);

  // useEffect(() => {
  //   if (editing) {
  //     inputRef.current?.focus();
  //   }
  // }, [editing]);

  // const toggleEdit = () => {
  //   setEditing(!editing);
  //   form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  // };

  // const save = async () => {
  //   try {
  //     const values = await form.validateFields();
  //
  //     toggleEdit();
  //     handleSave({ ...record, ...values });
  //   } catch (errInfo) {
  //     console.log('Save failed:', errInfo);
  //   }
  // };

  let childNode = children;
  // if (editable) {
  //   childNode = editing ? (
  //       <Form.Item
  //           style={{ margin: 0 }}
  //           name={dataIndex}
  //           rules={[
  //             {
  //               required: true,
  //               message: `${title} is required.`,
  //             },
  //           ]}
  //       >
  //         <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
  //       </Form.Item>
  //   ) : (
  //       <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
  //         {children}
  //       </div>
  //   );
  // }

  return <td {...restProps}>{childNode}</td>;
};

const ModalAddPackage = forwardRef(({serviceId, packageName, packageActive, description, priceList}, ref) => {
  const [dataSource, setDataSource] = useState([]);
  const sourceDelRef = useRef([]);
  const formRef = useRef({
      name: "",
      activeMembers: 0,
      description: "",
  });

  useImperativeHandle(ref, () => ({
      getDataForm(){
          const newSource = [];
          const sourceEditing = dataSource.filter(x => x.isEditing && x.id);
          const sourceAddNew = dataSource.filter(x => !x.id);

          if(sourceAddNew.length > 0)
          {
              newSource.push(...sourceAddNew);
          }
          if(sourceEditing.length > 0)
          {
              newSource.push(...sourceEditing);
          }
          if(sourceDelRef.current.length > 0)
          {
              newSource.push(...sourceDelRef.current);
          }

          return {
              packagePriceList: newSource,
              name: formRef.current.name,
              activeMembers: formRef.current.activeMembers,
              description: formRef.current.description
          };
      },
      clearSource(){
          sourceDelRef.current = [];
          setDataSource([]);
      },
      setSourceAgain(newSource = []) {
          setDataSource(newSource.length > 0 ? [...newSource] : []);
      }
  }));

  useEffect(() => {
      if(priceList.length > 0) {
          const newSource = priceList.map(x => ({
              id: x.id,
              serviceId: x.service_id,
              duration: x.duration,
              priceOrg: x.price_org,
              discount: x.discount,
              price: x.price,
              isDelete: false,
              isEditing: false,
          }))
          setDataSource(newSource);
      }
      else {
          setDataSource([]);
      }
  }, [priceList]);

  useEffect(() => {
      formRef.current = {
          name: packageName,
          activeMembers: packageActive,
          description: description,
      };
  }, [packageName, packageActive, description]);

  const columns = [
    {
      title: <div>Số ngày</div>,
      dataIndex: "duration",
      key: "duration",
      render: (val, record, idx) => <InputNumber placeholder="Nhập vào" style={{minWidth: 130}} min={0} value={val || 0} onChange={(newValue) => {
              dataSource[idx].duration = newValue;
              dataSource[idx].isEditing = true;
              setDataSource([...dataSource])
          }}/>,
    },
    {
      title: <div>Giá gốc (đ)</div>,
      dataIndex: "priceOrg",
      key: "priceOrg",
      render: (val, record, idx) => <InputNumber placeholder="Nhập vào" style={{minWidth: 150}}
                                                 // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                 // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                 formatter={formatterNumber}
                                                 parser={parserNumber}
                                                 min={0} value={val || 0} onChange={(newValue) => {
          dataSource[idx].priceOrg = newValue;
          dataSource[idx].isEditing = true;
          setDataSource([...dataSource])
      }}/>,
    },
    {
      title: <div>Giá bán (đ)</div>,
      dataIndex: "price",
      key: "price",
      render: (val, record, idx) => <InputNumber placeholder="Nhập vào" style={{minWidth: 150}}
                                                 formatter={formatterNumber}
                                                 parser={parserNumber}
                                                 // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                 // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                 min={0} value={val || 0} onChange={(newValue) => {
          dataSource[idx].price = newValue;
          dataSource[idx].isEditing = true;
          setDataSource([...dataSource])
      }}/>,
    },
    {
      title: <div>% Giảm</div>,
      dataIndex: "discount",
      key: "discount",
      render: (val, record, idx) => <InputNumber placeholder="Nhập vào" style={{minWidth: 150}} min={0} value={val || 0} onChange={(newValue) => {
          dataSource[idx].discount = newValue;
          dataSource[idx].isEditing = true;
          const priceDiscount = ((dataSource[idx].priceOrg ?? 0) * newValue) / 100;
          dataSource[idx].price = dataSource[idx].priceOrg - priceDiscount;
          setDataSource([...dataSource])
      }}/>,
    },
    {
      title: <div className="text-right">Thao tác</div>,
      dataIndex: "action",
      render: (_,record, idx) => {
        return (
          <div style={{ display: "flex", gap: 10, justifyContent: 'center' }}>
              <Popconfirm
                  title="Xóa bản ghi"
                  description="Bạn có muốn xóa bản ghi này?"
                  onConfirm={onDelItem(record, idx)}
                  okText="Đồng ý"
                  cancelText="Hủy"
              >
                  <Tooltip title="Xóa" placement="top">
                      <div>
                      <IconDelete color="#1D193E" />
                      </div>
                  </Tooltip>
              </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onAddNew = () => {
      const itemClone = cloneDeep(itemPackage);
      // itemClone.id = uuidv4();
      itemClone.serviceId = serviceId;
      setDataSource([...dataSource, itemClone]);
  }

  const onDelItem = (record, idx) => () => {
      if(record.id)
      {
          const recordDel = cloneDeep(record);
          recordDel.isDelete = true;
          sourceDelRef.current.push(recordDel);
      }
      // removeLd(dataSource, x => x.id === record.id);
      // console.log("REMOVE", dataSource);
      dataSource.splice(idx, 1);
      setDataSource([...dataSource]);
  };

  const onFormChanged = (fieldText, inputVal) => {
      formRef.current[fieldText] = inputVal;
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
        <div className="package-body">
            <ul>
              <li>Tên gói</li>
                <InputState fieldText={"name"} fieldValue={packageName || ""} onChanged={onFormChanged}/>
              {/*<Input defaultValue={packageName || ""} style={{minWidth: 300}} onChange={(e) => formRef.current.name = e.target.value} />*/}
            </ul>
            <ul>
              <li>Số user khởi tạo</li>
                <InputState fieldText={"activeMembers"} isNumber={true} fieldValue={packageActive || 0} onChanged={onFormChanged}/>
              {/*<InputNumber defaultValue={packageActive || 0} style={{minWidth: 300}} onChange={(e) => formRef.current.activeMembers = e.target.value} />*/}
            </ul>
        </div>
            <span>Mô tả gói</span>
            <div className={"desc-input"}>
                <InputState fieldText={"description"} isTextArea={true} fieldValue={description || ""} onChanged={onFormChanged}/>
            </div>
        <div className="package-box">
          <Table
              components={components}
              rowClassName={() => 'editable-row'}
              bordered
              dataSource={[...dataSource]}
              columns={columns}
              pagination={false}
          />
          <Button
            style={{ marginTop: 12, fontWeight: 600 }}
            onClick={onAddNew}
            // onClick={() => {
            //   setEditItem({
            //     key:
            //       Date.now().toString() +
            //       Math.random().toString(36).slice(2, 9),
            //   });
            // }}
          >
            <div className="btn-add">
              <IconAdd />
              Thêm giá bán
            </div>
          </Button>
        </div>
    </>
  );
});

export default ModalAddPackage;