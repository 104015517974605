import { createSlice } from '@reduxjs/toolkit';
import {getAllListBuyMoreApi} from "./adminApis";

const initialState = {
  servicesByPkg: [],
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearState: (state) => {
      state.servicesByPkg = [];
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getAllListBuyMoreApi.fulfilled, (state, action) => {
          state.servicesByPkg = action.payload || [];
        });
  },
});

export const {clearState } = adminSlice.actions;

export default adminSlice.reducer;
