import { useEffect, useState } from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { Form, Input } from "antd";

import "./ResetPassword.scss";
import ButtonCustomize from "../button";
import { BtnTypeEnum } from "../button/const";
import { useUser } from "../../pages/client/useUser";
import ElipseLeft from "../svg/elipseLeft";
import ElipseTop from "../svg/elipseTop";
import ElipseRight from "../svg/elipseRight";
import Logo from "../svg/logo";
import LogoTesop from "../../assets/images/logo_tesop.png";
import Star from "../svg/star";
import Line from "../svg/line";
import IconSuccess from "../svg/icon-success";

function RestPassword() {
  const { updateResetPassword } = useUser();
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(true);

  const token = new URLSearchParams(location.search).get("token");

  const handleResetPassword = async () => {
    try {
      const values = await form.validateFields();
      const isToken = await updateResetPassword({
        password: values.password,
        token: token,
      });
      if (!isToken) {
        setShow(false);
      }
    } catch (e) {
      setShow(true);
    }
  };

  return (
    <>
      <div className="header">
        {process.env.REACT_APP_IS_SAAS_PROD === "true" ? (
          <Logo />
        ) : (
          <img src={LogoTesop} alt={""} width={150} />
        )}
        <div className="comment">
          <Star />
          4.9/5
        </div>
      </div>
      <div className="container-reset-password">
        <div className="container-icon">
          <div className={"ellipse-left"}>
            <ElipseLeft />
          </div>
          <div className={"ellipse-top"}>
            <ElipseTop />
          </div>
          <div className={"ellipse-right"}>
            <ElipseRight />
          </div>
        </div>

        {show ? (
          <div className="forgot-password-card">
            <div className="form-register">
              <h2>Đặt lại mật khẩu</h2>
              <Form
                className={"form"}
                name="basic"
                layout="vertical"
                form={form}
                onFinish={handleResetPassword}
                autoComplete="off"
              >
                <div className="col-wrap">
                  <div className={"form-item"}>
                    <Form.Item
                      label={
                        <>
                          <div className="title-form">Mật khẩu mới</div>
                        </>
                      }
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập mật khẩu",
                        },
                      ]}
                    >
                      <Input
                        rootClassName={"form-input"}
                        placeholder={"Nhập vào"}
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <>
                          <div className="title-form">
                            Nhập lại mật khẩu mới
                          </div>
                        </>
                      }
                      name="confirmPassword"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập lại mật khẩu",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Mật khẩu nhập lại không khớp")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        rootClassName={"form-input"}
                        placeholder={"Nhập lại mật khẩu mới"}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="btn_reset_password">
                  <ButtonCustomize
                    title={"Xác nhận"}
                    btnType={BtnTypeEnum.BLACK}
                    btnSize={44}
                    onPressHandle={handleResetPassword}
                  />
                </div>
              </Form>
            </div>
          </div>
        ) : (
          <div className="reset-password-card">
            <IconSuccess />
            <div className="container_reset_password">
              <p>Đặt lại mật khẩu mới thành công!</p>
              <p>Bạn đã tạo mật khẩu mới thành công</p>
            </div>
            <div className="btn_reset_password">
              <ButtonCustomize
                title={"Đăng nhập ngay"}
                btnType={BtnTypeEnum.GREEN}
                btnSize={44}
                onPressHandle={() => history.push("/login")}
              />
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <span className="footer-text">© 2024 Crm360</span> <Line />
        <span className="footer-text">Hỗ trợ</span>
      </div>
    </>
  );
}

export default withRouter(RestPassword);
